import React, { Component } from 'react';

import { 
    WFInput,
} from '../../../components/wireframes/modules';

import Header from '../components/Header';
import Footer from '../components/Footer';

import { getId } from '../../../utils/url';
import { clear_form_element } from '../utils/forms';

import UsersTable from '../components/UsersTable';

class PoolDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            new_pool_name: null,
            new_user_email: null,
            confirming_delete_pool: false,
        }
        this.delete_pool = this.delete_pool.bind(this);
        this.add_user = this.add_user.bind(this);
        this.update_pool = this.update_pool.bind(this);
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        this.setState({ id }, async() => {
            try {
            const result = await this.props.allow_pools_get({ id });
            const { data: pool } = result.payload;
            await this.props.allow_users_get_all({ pool_reference: pool.pool_reference });
            } catch (e) {
                console.log(e);
            }
        });
        
    }

    async delete_pool () {
        const { id, confirming_delete } = this.state;
        if (confirming_delete) {
            await this.props.allow_pools_delete({ 
                id,
            });
            this.props.history.push("/pools");
        } else {
            this.setState({
                confirming_delete: true,
            });
        }
    }

    update_pool () {
        const { id, new_pool_name } = this.state;
        this.props.allow_pools_put({ 
            id, 
            data: {
                pool_name: new_pool_name,
            },
        });
    }

    async add_user () {
        const { selected: pool } = this.props.allow_pools;
        const { new_user_email } = this.state;
        clear_form_element('create_email');
        await this.props.allow_users_post({ data : {
            pool_reference: pool.pool_reference,
            email: new_user_email,
        }}); 
    }

    render() {
        const { id, confirming_delete } = this.state;
        const { isFetching: isFetchingPool, selected: pool = {}, error: errorPools  } = this.props.allow_pools;
        const { isFetching: isFetchingUsers, data: users = [], error: errorUsers } = this.props.allow_users;

        return (
            <div>
                <Header />

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12 wf-bg">
                            <div className="wf">
                                {confirming_delete && <p>This will delete the pool and all users within the pool. Are you sure?</p>}
                                <button onClick={this.delete_pool}>Delete Pool</button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                
                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-6">
                            <div className="wf wf-br">

                                {(errorUsers) &&
                                    <div className="wf wf-alert wf-bg">
                                        <p><b>Error!</b> : {errorUsers.message}</p>
                                    </div>
                                }

                                <WFInput 
                                    name={'create_email'}
                                    label={'User Email'}
                                    validation={(value) => {
                                        return value.length > 5 && value.includes('@');
                                    }}
                                    submit={(value)=> { 
                                        this.setState({ new_user_email: value.create_email }) 
                                    }}
                                />
                                {!(isFetchingPool || isFetchingUsers) &&<button onClick={this.add_user}>Add User</button>}
                                {(isFetchingPool || isFetchingUsers) &&  <div className='wf-loader'></div>}

                            </div>
                            
                            <hr/>

                            <div className="wf wf-br">
                                <WFInput 
                                    name={'search_email'}
                                    label={'Search Email'}
                                    validation={(value) => value.length > 5}
                                    submit={(value)=> { this.props.allow_users_get_all({
                                        pool_reference: this.state.id,
                                        email: value.search_email,
                                    }) }}
                                />
                                {!(isFetchingPool || isFetchingUsers) && <button>Search pool for user</button>}
                                {(isFetchingPool || isFetchingUsers) &&  <div className='wf-loader'></div>}
                            </div>
                            
                        </div>
                        <div className="wf-col-6">
                            {!isFetchingPool &&
                                <div className="wf wf-br">
                                    <WFInput 
                                        name={'pool_reference'}
                                        label={'Pool Reference'}
                                        value={pool.pool_reference}
                                        validation={() => {}}
                                        submit={()=> {}}
                                        disabled={true}
                                    />
                                    <button onClick={() => {
                                        this.props.history.push(`/login/${id}`)
                                    }}>Try Login</button>
                                </div>
                            }

                            <hr/>

                            {!isFetchingPool &&
                                <div className="wf wf-br">

                                    {(errorPools) &&
                                        <div className="wf wf-alert wf-bg">
                                            <p><b>Error!</b> : {errorPools.message}</p>
                                        </div>
                                    }

                                    <WFInput 
                                        name={'pool_name'}
                                        label={'Pool Name'}
                                        value={pool.pool_name}
                                        validation={(value) => value.length > 5}
                                        submit={(value)=> { 
                                            this.setState({ new_pool_name: value.pool_name }) 
                                        }}
                                    />
                                    <button onClick={this.update_pool}>Save Pool Name</button>
                                </div>
                            }
                            {isFetchingPool && <div className='wf-loader'></div>}
                        </div>
                    </div>
                </div>

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        {!isFetchingUsers &&
                            <div className="wf-col-12">
                                <UsersTable data={users} />
                            </div>
                        }
                        {isFetchingUsers && <div className='wf-loader'></div>}
                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}

export default PoolDetail;