const strings = {
    languages: {
        en: 'english',
        fr: 'french',
    },
    login: {
        email_address: 'Email address',
    },
}

export default strings;