import React, { Component } from 'react';

import TestsAggregate from './TestsAggregate';

import { DownloadButton } from '../../utils/download';
import { UploadArea } from '../../utils/upload';
import { VirtualTable } from '../../utils/virtualTable';

class TestsOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upload: false,
        }
        this.uploadTests = this.uploadTests.bind(this);
    }

    async uploadTests(results) {
        this.props.completeUpload(results);
        this.setState({ upload: false });
    }

    render() {
        let { tests = [], tests_count, is_running, read_only } = this.props;
        const { upload } = this.state;

        if(tests.length > tests_count) { tests = tests.slice(0, tests_count) };

        return [
            <TestsAggregate tests={tests} />,
            <div key={'tests-table'} className="review-block">
                <div key={`full-buttons`} className="upload-buttons">
                    <button className="button small grey no-hover mr">Rows : {tests.length}</button>
                    
                    {tests.length > 0 && 
                        <DownloadButton 
                            data={{
                                tests,
                            }}
                            label={'Tests'}
                            file_name={`tests.csv`}
                            type={'tests'}
                            className={'mr'}
                        />
                    }
                    
                    {!read_only && <button className="button small secondary mr" onClick={() => this.setState({ upload: !this.state.upload })}><i className="fa fa-angles-up"></i>Upload Upload (CSV)</button>}
                </div>
                
                {(upload || tests.length === 0) &&
                    <UploadArea
                        type={'tests'}
                        complete={this.uploadTests}
                    />
                }

                {!is_running && tests.length > 0 && 
                    <div className="exclusions-edit">
                        <VirtualTable 
                            data={tests}
                            type='tests'
                            xScroll={'hidden'}
                            clickRow={(index) => {
                                if (read_only) return;
                                this.props.viewTest(tests[index]);
                            }}
                        />
                    </div>
                }
            </div>
        ]
    }
}

export default TestsOverview;