import React, { Component } from 'react';
import moment from 'moment';

class Input extends Component {
    render() {

        let { 
            label = 'Step Name', 
            value = '',
            errors = null,
            disabled = false,
            update = (() => {}),
            type = 'text',
            placeholder,
        } = this.props;

        const { message } = errors || {};

        return (
            <fieldset className={`${message ? 'error' : ''} ${disabled ? 'disabled' : ''}`}>
                <label>{label}</label>
                <input 
                    onChange={(e) => {
                        let { value } = e.target;
                        if (type === 'date') {
                            const newDate = moment(new Date(value)).format('YYYY-MM-DD');
                            update(newDate);
                        } else {
                            update(value);
                        }
                    }}
                    placeholder={placeholder}
                    disabled={disabled} 
                    defaultValue={value} 
                    type={type} 
                />
                {message && <p className='error-message'><i className="fa fa-circle-exclamation"></i>{message}</p>}
            </fieldset>
        );
    }
}

export default Input;