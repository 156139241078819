import React, { Component } from 'react';
import SelectSearch from 'react-select-search';
import { isUndefined } from 'lodash';

class Select extends Component {
    render() {
        let { 
            label = 'Step Name', 
            value = '',
            errors = null,
            disabled = false,
            options = [],
            update = (() => {}),
            type = 'normal'
        } = this.props;

        const { message } = errors || {};

        const normal_select = () => {
            return (
                <select 
                    value={value} 
                    onChange={(e) => {
                        update(e.target.value)
                    }}
                    disabled={disabled}
                >
                    <option value={''}></option>
                    {options.map(op => {
                        return ( <option value={op.value}>{op.text}</option>)
                    })}
                </select>
            )
        }

        const boolean_select = () => {
            value = (value === null || isUndefined(value)) ? '' : (value === '' ? '' : (value ? 'true' : 'false'));
            return (
                <SelectSearch 
                    options={[{
                        name: '',
                        value: ''
                    },
                    {
                        name: 'TRUE',
                        value: 'true',
                    },{
                        name: 'FALSE',
                        value: 'false',
                    }]} 
                    search={false}
                    value={value} 
                    placeholder={''}
                    onChange={(val) => {
                        update(val === 'true' ? true : false);
                    }}
                />
            )
        }

        const search_select = () => {
            return (
                <SelectSearch 
                    options={[{
                        name: '',
                        value: ''
                    }, ...options]} 
                    value={value} 
                    search={true}
                    name={label} 
                    placeholder={''}
                    onChange={(val) => {
                        update(val);
                    }}  
                />
            )
        }

        return (
            <fieldset className={`${message ? 'error' : ''} ${disabled ? 'disabled' : ''}`}>
                <label>{label}</label>
                {type === 'normal' && normal_select()}
                {type === 'boolean' && boolean_select()}
                {type === 'search' && search_select()}
                {message && <p className='error-message'><i className="fa fa-circle-exclamation"></i>{message}</p>}
            </fieldset>
        );
    }
}

export default Select;