import React, { Component } from 'react';
import hoc from '../../../../hoc/main';

import { sortBy} from 'lodash';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';
import { VirtualTable } from '../../utils/virtualTable';

import GlobalLoading from '../../../../components/GlobalLoading';

import config from '../../../../config/index';
import moment from 'moment';
import axios from 'axios';  

export async function post_zeus(data, auth) {
    const { client_id_key, client_secret_key } = auth
    const result = await axios({
        url: config.root + '/a/zues',
        method: 'post',
        data: JSON.stringify({
            ...data,
        }),
        headers: {
            'Content-Type': 'application/json',
            'client_id_key': client_id_key,
            'client_secret_key': client_secret_key,
        }, 
    }).then(({data}) => {
        return data;
    }).catch((error) => console.log(error))
    return result;
}

class Super extends Component {
    constructor(props){
        super(props);
        this.state = {
            section: 'users',
        }
        this.getUsers = this.getUsers.bind(this);
        this.getVersions = this.getVersions.bind(this);
        this.getQuotes = this.getQuotes.bind(this);
    }

    async UNSAFE_componentWillMount() {
        await this.getUsers();
        await this.props.swallow_companys_get_all({});
    }

    async getUsers() {
        this.setState({ section: 'users' });
        await this.props.swallow_users_get_all({});
    }

    async getVersions() {
        this.setState({ section: 'versions' });
        await this.props.swallow_versions_get_all({});
    }

    async getQuotes() {
        this.setState({ section: 'quotes' });
        await this.props.swallow_quotes_get_all({});
    }

    async getCompanys() {
        this.setState({ section: 'companys' });
        await this.props.swallow_companys_get_all({});
    }


    render() {
        let { data: users = [], isFetching: isFetchingUsers } = this.props.swallow_users;
        let { data: versions = [], isFetching: isFetchingVersions } = this.props.swallow_versions;
        let { data: quotes = [], isFetching: isFetchingQuotes } = this.props.swallow_quotes;
        let { data: companys = [], isFetching: isFetchingCompanys } = this.props.swallow_companys;
        
        const { selected: user = {} } = this.props.auth;
        const { email = '', company_reference, pool_reference, user_reference, client_id_key, client_secret_key } = user;
        const [ company = {} ] = user.companys || [];

        const { section } = this.state;

        versions = sortBy(versions, v => -moment(v.updated_at));
        quotes = sortBy(quotes, q => -moment(q.created_at));
        users = sortBy(users, u => -moment(u.last_token_check_at));

        const isFetching = (isFetchingUsers || isFetchingVersions || isFetchingQuotes || isFetchingCompanys);

        return (
            <div className="swallow_app slide-left">
                <TopNav history={this.props.history} auth={this.props.auth} account_type={this.props.account_type} />

                {isFetching && <GlobalLoading /> }

                {!isFetching &&
                    <section className="main-content">

                        <div className="main-content-nav">
                            <div className="main-content-nav-left">
                                <h2 style={{ margin: '0 12px 0' }}>{ company.company_name } <span className="button small main" onClick={async() => {
                                    await post_zeus({
                                        user_reference,
                                        company_reference: '098ae4f2-3af8-4740-8760-5d312e46742c',
                                        pool_reference: '098ae4f2',
                                    }, {
                                        client_id_key,
                                        client_secret_key,
                                    });
                                    window.location.reload();
                                }}>Reset Zues</span></h2>
                                <p style={{ margin: '6px 12px 0', fontSize: 12, opacity: 0.7 }}>{email} / {company_reference} / {pool_reference}</p>
                            </div>
                            <div className="main-content-nav-right" >
                                <button onClick={() => this.getUsers()} className={`button big ${section === 'users' ? 'white' : 'main'} mr`}>
                                    Users
                                </button>
                                <button onClick={() => this.getVersions()} className={`button big ${section === 'versions' ? 'white' : 'main'} mr`}>
                                    Versions
                                </button>
                                <button onClick={() => this.getQuotes()} className={`button big ${section === 'quotes' ? 'white' : 'main'} mr`}>
                                    Quotes
                                </button>
                                <button onClick={() => this.getCompanys()} className={`button big ${section === 'companys' ? 'white' : 'main'} mr`}>
                                    Companys
                                </button>
                            </div>
                        </div>

                        {section === 'users' && 
                            <div>
                                <VirtualTable 
                                    data={users}
                                    type='users'
                                    xScroll={'scroll'}
                                    clickRow={async(index) => {
                                        const { company_reference, pool_reference } = users[index];
                                        window.navigator.clipboard.writeText(JSON.stringify(users[index], null, 4));
                                        await post_zeus({
                                            user_reference,
                                            company_reference,
                                            pool_reference,
                                        }, {
                                            client_id_key,
                                            client_secret_key,
                                        });
                                        window.location.reload();
                                    }}
                                />
                            </div>
                        }

                        {section === 'versions' && 
                            <div>
                                <VirtualTable 
                                    data={versions}
                                    type='versions'
                                    xScroll={'scroll'}
                                />
                            </div>
                        }

                        {section === 'quotes' && 
                            <div>
                                <VirtualTable 
                                    data={quotes}
                                    type='quotes'
                                    xScroll={'scroll'}
                                />
                            </div>
                        }

                        {section === 'companys' && 
                            <div>
                                <VirtualTable 
                                    data={companys}
                                    type='companys'
                                    xScroll={'scroll'}
                                    minWidth={300}
                                />
                            </div>
                        }

                    </section>
                }       

                <Footer history={this.props.history} />
            </div>
        );
    }
}

export default hoc(Super);