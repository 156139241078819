import { isNumber, isBoolean, isObject, isArray } from 'lodash';
import moment from 'moment';

function isDecimal(num) {
    return num % 1 !== 0
}

export function dataTypeDeclared(item, type) {
    if (!item) return null;
    if (type === 'boolean') {
        if (item.toUpperCase() === 'TRUE') return true;
        if (item.toUpperCase() === 'FALSE') return false;
    } else if (type === 'integer' || type === 'decimal') {
            return Number(item);
    } else if (type === 'date') {
        if(moment(item, 'YYYY-MM-DD', true).isValid()) {
            return moment(item, 'YYYY-MM-DD').format('YYYY-MM-DD');
        } else {
            if(moment(item, 'DD/MM/YYYY', true).isValid()) {
                return moment(item, 'DD/MM/YYYY').format('YYYY-MM-DD');
            } else {
                if(moment(item, 'MM/DD/YYYY', true).isValid()) {
                    return moment(item, 'MM/DD/YYYY').format('YYYY-MM-DD');
                } else {
                    return item;
                }  
            }
        }
    } else {
        return item
    }
}

export function dataTypeConvertor(item) {
    if (!item) return null;
    if (isBoolean(item)) {
        return Boolean(item);
    } else {
        if (isNumber(item)) {
            if (isDecimal(item)) {
                return Number(item);
            } else {
                return Number(item);
            }
        } else {
            if (item.toUpperCase() === 'TRUE') return true;
            if (item.toUpperCase() === 'FALSE') return false;
            if(!isNaN(Number(item))) {
                if (isDecimal(Number(item))) {
                    return Number(item);
                } else {
                    return Number(item);
                }
            } else {
                if(moment(item, 'YYYY-MM-DD', true).isValid()) {
                    return moment(item, 'YYYY-MM-DD').format('YYYY-MM-DD');
                } else {
                    if(moment(item, 'DD/MM/YYYY', true).isValid()) {
                        return moment(item, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    } else {
                        if(moment(item, 'MM/DD/YYYY', true).isValid()) {
                            return moment(item, 'MM/DD/YYYY').format('YYYY-MM-DD');
                        } else {
                            return item;
                        }  
                    }
                }
            }
        }
    }  
}

export default function dataTypeSelector(item) {
    if (isObject(item)) return 'object';
    if (isArray(item)) return 'array';
    if (isBoolean(item)) {
        return 'boolean';
    } else {
        if (isNumber(item)) {
            if (isDecimal(item)) {
                return 'decimal';
            } else {
                return 'integer';
            }
        } else {
            if (item.toUpperCase() === 'TRUE') return 'boolean';
            if (item.toUpperCase() === 'FALSE') return 'boolean';
            if(!isNaN(Number(item))) {
                if (isDecimal(Number(item))) {
                    return 'decimal';
                } else {
                    return 'integer';
                }
            } else {
                if(moment(item, 'YYYY-MM-DD', true).isValid()) {
                    return 'date';
                } else {
                    if(moment(item, 'DD/MM/YYYY', true).isValid()) {
                        return 'date';
                    } else {
                        if(moment(item, 'MM/DD/YYYY', true).isValid()) {
                            return 'date';
                        } else {
                            return 'string';
                        }  
                    }
                }
            }
        }
    }  
}