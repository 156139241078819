import React, { Component } from 'react';

import TopNav from '../../components/Layout/TopNav';

import Table from '../../components/Data/Table';

class ProjectTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    async componentDidMount() {
    }

    render() {
        const { data } = this.state;

        return (
            <div class="swallow_app">
                <TopNav history={this.props.history} />

                <section className="main-content slide-left">

                    <div className="data-area">
                        <div className="data-area-left">
                            <div className="data-area-chart">
                                <p>Chart</p>
                            </div>
                            <Table 
                                items={data}
                                placeholder={`Search Data`}
                                hide_checkboxes={true}
                                headers={[
                                    'Name',
                                    'Version',
                                    'Date',
                                    'Rows',
                                ]}
                            />
                        </div>
                        <div className="data-area-right">
                        </div>
                    </div>

                </section>

            </div>
        );
    }
}

export default ProjectTeam;