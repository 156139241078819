import React, { Component } from 'react';

import TopNav from '../../components/Layout/TopNav';

class ProjectLogs extends Component {
    render() {
        return (
            <div class="swallow_app">
                <TopNav history={this.props.history} />
                <section className="main-content slide-left">
                    <div className="logs-area">
                        <div className="logs-area-left"></div>
                        <div className="logs-area-right"></div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ProjectLogs;