import Dexie from 'dexie';
import moment from 'moment';

export const db = new Dexie('swallow_local_db');
db.version(1).stores({ versions: 'version_reference' });
db.version(1).stores({ tests: 'version_reference' });

export function saveCheck({ created_at, cache_time = 60 }) {
    const now = moment();
    const created = moment(created_at);
    const diff = now.diff(created, 'seconds');
    return diff > cache_time;
}

export async function getAllLocalVersion() {
    try {
        const result = await db.versions.toArray();
        return result;
    } catch(e) {
        console.warn(e);
    }
}

export async function getLocalVersion({ version_reference }) {
    try {
        const result = await db.versions.get({
            version_reference,
        });
        return result || {};
    } catch(e) {
        console.warn(e);
        return {};
    }
}

export async function putLocalVersion({ version_reference, version, created_at  }) {
    try {
        const result = await db.versions.put({
            ...version,
            version_reference,
            created_at: created_at || moment().toISOString(), 
        });
        return result || {};
    } catch(e) {
        console.warn(e);
        return version;
    }
}

export async function getAllLocalTests() {
    try {
        const result = await db.tests.toArray();
        return result;
    } catch(e) {
        console.warn(e);
    }
}

export async function getLocalTests({ version_reference }) {
    try {
        const result = await db.tests.get({
            version_reference,
        });
        return result;
    } catch(e) {
        console.warn(e);
    }
}

export async function putLocalTests({ version_reference, tests, created_at  }) {
    try {
        const result = await db.tests.put({
            tests,
            version_reference,
            created_at: created_at || moment().toISOString(), 
        });
        return result;
    } catch(e) {
        console.warn(e);
    }
}

export async function getFreeSpace() {
    navigator.webkitPersistentStorage.queryUsageAndQuota (
        function(usedBytes, grantedBytes) {
            console.warn('we are using ', usedBytes, ' of ', grantedBytes, 'bytes');
        },
        function(e) { console.log('Error', e);  }
    );
}