import React, { Component } from 'react';
import versions_hoc from '../../hoc/versions';
import { get } from 'lodash';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import DesignArea from '../../components/Design/DesignArea';
import EditArea from '../../components/Design/EditArea';
import ApprovedBlock from '../../components/Layout/ApprovedBlock';

import { unique_id } from '../../utils/unique_id';

class VersionDesign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: {},
            output: {},
            steps: [],
            version: this.props.version,
            process: this.props.process,
            tests: this.props.tests.tests,
            version_reference: this.props.version_reference,
            selected_step_id: this.props.selected_step_id || null,
            selected_item_id: this.props.selected_item_id || null,
            isFetching: false,
        }
        this.addComponent = this.addComponent.bind(this);
        this.editComponent = this.editComponent.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.deleteComponent = this.deleteComponent.bind(this);
        this.unselectComponent = this.unselectComponent.bind(this);
        this.moveComponent = this.moveComponent.bind(this);
        this.saveVersion = this.saveVersion.bind(this);
    }

    async UNSAFE_componentWillMount() {
        const { version = {}, selected_item_id, selected_step_id } = this.props;
        const { steps, input, output } = version;
        this.setState({
            steps, 
            input,
            output,
            selected_item_id,
            selected_step_id,
        });
    }

    shouldComponentUpdate(nextProps) {
        // Adds - Removes selected item and step
        if (
            nextProps.selected_item_id !== this.state.selected_item_id ||
            nextProps.selected_step_id !== this.state.selected_step_id
        ) {
            this.setState({
                selected_item_id: nextProps.selected_item_id,
                selected_step_id: nextProps.selected_step_id,
            });
        }
        return true;
    }

    async saveVersion({ uploaded_project } = {}) {
        const { 
            steps,
            input,
            output,
            version,
        } = this.state;

        this.setState({ isFetchingVersion: true });

        const { status } = version.meta

        let project = {
            ...version,
            steps,
            input,
            output,
        };

        if (uploaded_project) project = uploaded_project;

        // Can't update
        if (status === 'published') return this.setState({ isFetchingVersion: false });

        await this.props.saveVersionProject({
            project,
        });

        this.setState({ 
            isFetchingVersion: false,
            steps: project.steps,
            input: project.input,
            output: project.output,
        });
    }

    unselectComponent() {
        this.setState({
            selected_step_id: null,
            selected_item_id: null,
        })
    }

    addComponent(index = 0) {
        const { steps } = this.state;
        const new_steps = [
            ...steps.slice(0, index),
            {
                step: null,
                name: null,
                key: null,
                id: unique_id(),
            },
            ...steps.slice(index)
        ];
        this.setState({ steps: new_steps });
    }

    moveComponent(dnd_result = {}) {
        const { steps } = this.state;
        const startIndex = dnd_result.source.index;
        const endIndex = dnd_result.destination.index;

        const reorder = () => {
            const result = Array.from(steps);
            const [ removed ] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };

        this.setState({
            steps: reorder(),
        }, () => this.saveVersion())
    }

    editComponent(id) {
        const { steps = [] } = this.state;
        if (steps.map(s => s.id).includes(id) || id === 'input' || id === 'output') {
            this.setState({
                selected_step_id: id,
            });
        } else {
            return this.setState({
                selected_step_id: null,
                selected_item_id: null,
            });
        }
    }

    updateComponent({ id, data, input, output, empty = false }) {
        const { steps } = this.state;

        if (input) {
            return this.setState({
                input,
                selected_step_id: null,
                selected_item_id: null,
            }, () => {
                this.saveVersion();
            });
        }

        if (output) {
            return this.setState({
                output,
                selected_step_id: null,
                selected_item_id: null,
            }, () => {
                this.saveVersion();
            });
        }

        const new_steps = steps.map(step => {
            if (step.id === id) {
                return data;
            }
            return step;
        });

        this.setState({
            steps: new_steps,
            selected_step_id: null,
            selected_item_id: null,
        }, () => {
            // Don't save if an empty step to stop validation fails
            if (!empty) this.saveVersion();
        });
    }

    deleteComponent (step_id, no_cloud = false) {
        const { steps } = this.state;
        this.setState({
            selected_step_id: null ,
            selected_item_id: null,
            steps: steps.filter(s => s.id !== step_id),
        }, async() => {
            if(!no_cloud) this.saveVersion();
        });
    }

    render() {
        const { 
            selected_step_id,
            selected_item_id,
            version_reference,
            steps = [],
            input,
            output,
            isFetchingVersion,
            tests,
            process = {},
        } = this.state;

        const {
            version_size,
            version_saved_at,
            read_only,
            version,
        } = this.props;

        const { account_type, is_approved, account_config } = this.props;
        const project_reference = get(process, 'version.project_reference');

        const isFetching = isFetchingVersion;

        return (
            <div className="swallow_app">
                <TopNav 
                    history={this.props.history} 
                    version={process.version} 
                    auth={this.props.auth} 
                    account_type={account_type} 
                    read_only={read_only}
                />

                {is_approved && <ApprovedBlock project_reference={project_reference} />}

                { !isFetching && !selected_step_id &&
                    <DesignArea
                        account_type={account_type}
                        account_config={account_config}
                        version_reference={version_reference}
                        history={this.props.history}
                        version={version}
                        process={process}
                        steps={steps}
                        input={input}
                        output={output}
                        tests={tests}
                        selected_step_id={selected_step_id}
                        selected_item_id={selected_item_id}
                        addComponent={this.addComponent}
                        updateComponent={this.updateComponent}
                        editComponent={this.editComponent}
                        deleteComponent={this.deleteComponent}
                        moveComponent={this.moveComponent}
                        saveVersion={this.saveVersion}
                        set_error={this.props.set_error}
                        version_size={version_size}
                        version_saved_at={version_saved_at}
                    />
                }

                { !isFetching && selected_step_id &&
                    <EditArea 
                        account_type={account_type}
                        account_config={account_config}
                        version={version}
                        process={process}
                        steps={steps}
                        selected_step_id={selected_step_id}
                        selected_item_id={selected_item_id}
                        updateComponent={this.updateComponent}
                        unselectComponent={this.unselectComponent}
                        editComponent={this.editComponent}
                        deleteComponent={this.deleteComponent}
                        saveVersion={this.saveVersion}
                        set_error={this.props.set_error}
                    /> 
                }

                <Footer history={this.props.history}/>

            </div>
        );
    }
}

export default versions_hoc(VersionDesign);