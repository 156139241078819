const { each } = require('lodash');

function queryBuilder(queries) {
    let count = 0;
    let url = '';
    each(queries, (q, k) => {
        if (k === 'id') return;
        if (k === 'data') return;
        if (q === null || q === '') return;
        url += `${count === 0 ? '?' : '&'}${k}=${encodeURIComponent(q)}`;
        count++;
    });
    return url;
}

class BaseActions {
    constructor({
        path, type, version, notify, headers = {},
    }) {
        this.path = path;
        this.type = type;
        this.version = version;
        this.notify = notify;
        this.headers = headers;
        this.types = {
            getAll: `GET_ALL_${type}`,
            getSome: `GET_SOME_${type}`,
            get: `GET_${type}`,
            delete: `DELETE_${type}`,
            post: `POST_${type}`,
            put: `PUT_${type}`,
            reset: `RESET_${type}`,
            postSome: `POST_${type}`
        };
        this.getSome = this.getSome.bind(this);
        this.getAll = this.getAll.bind(this);
        this.get = this.get.bind(this);
        this.delete = this.delete.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.reset = this.reset.bind(this);
        this.postSome = this.postSome.bind(this);
    }

    getAll(query) {
        const url = this.path + queryBuilder(query);
        const result = {
            type: this.types.getAll,
            payload: {
                request: {
                    url,
                    method: 'GET',
                    headers: this.headers,
                },
            },
        };
        return result;
    }

    getSome(query) {
        const url = this.path + queryBuilder(query);
        const result = {
            type: this.types.getSome,
            payload: {
                request: {
                    url,
                    method: 'GET',
                    headers: this.headers,
                },
            },
        };
        return result;
    }

    get(data) {
        const { id } = data;
        const url = `${this.path}/${id}` + queryBuilder(data);
        const result = {
            type: this.types.get,
            payload: {
                request: {
                    url,
                    method: 'GET',
                    headers: this.headers,
                },
            },
        };
        return result;
    }

    reset() {
        const result = {
            type: this.types.reset,
            payload: {
                data: null,
            },
        };
        return result;
    }

    delete({ id, query }) {
        const url = `${this.path}/${id}${queryBuilder(query)}`;
        const result = {
            type: this.types.delete,
            payload: {
                request: {
                    url,
                    method: 'DELETE',
                    headers: this.headers,
                },
            },
        };
        return result;
    }

    post({ data }) {
        let result = {
            type: this.types.post,
            payload: {
                request: {
                    url: `${this.path}`,
                    method: 'POST',
                    headers: this.headers,
                    data,
                },
            },
        };
        return result;
    }

    postSome(obj) {
        const { id, data } = obj;
        let result = {
            type: this.types.post,
            payload: {
                request: {
                    url: `${this.path}/${id}${queryBuilder(obj)}`,
                    method: 'POST',
                    headers: this.headers,
                    data,
                },
            },
        };
        return result;
    }

    put({ id, data }) {
        const result = {
            type: this.types.put,
            payload: {
                request: {
                    url: `${this.path}/${id}`,
                    method: 'PUT',
                    headers: this.headers,
                    data,
                },
            },
        };
        return result;
    }

    exportMethods() {
        const type = this.type.toLowerCase();
        let response = {
            [`${type}_get_all`]: this.getAll,
            [`${type}_get_some`]: this.getSome,
            [`${type}_get`]: this.get,
            [`${type}_reset`]: this.reset,
            [`${type}_delete`]: this.delete,
            [`${type}_post`]: this.post,
            [`${type}_post_some`]: this.postSome,
            [`${type}_put`]: this.put,
        };
        return response;
    }
}

export default BaseActions;
