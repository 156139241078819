import React from 'react';
import Table from '../Layout/Table';

class VersionsTable extends Table {

    renderRow({ item, updateUser, updateAdmin }) {
        const {
            user_reference,
            email,
            project_reference,
            is_read = false,
            is_write = false,
            is_approve = false,
            is_publish = false,
            is_admin = false,
        } = item;

        function renderTick({ value, key }) {

            const payload = {
                project_reference,
                user_reference,
                is_read,
                is_write,
                is_approve,
                is_publish,
                is_admin,
            };

            if (is_admin) {
                return (
                    <i className="fa fa-circle-check admin"></i>
                )
            }

            function update({ payload, key, value }) {
                updateUser({
                    ...payload,
                    [key]: value,
                })
            }

            if(value) {
                return (
                    <i onClick={() => {
                        update({
                            payload,
                            key,
                            value: false,
                        });
                    }} className="fa fa-circle-check"></i>
                )
            } else {
                return (
                    <i onClick={() => {
                        update({
                            payload,
                            key,
                            value: true,
                        });
                    }} className="fa fa-regular fa-circle"></i>
                )
            }
        }

        return (
            <tr key={ project_reference + '_' + user_reference }>
                <td><span className="button small black no-hover">{email}</span></td>
                <td style={{ width: '70px'}}>{renderTick({ value: is_read, key: 'is_read'})}</td>
                <td style={{ width: '70px'}}>{renderTick({ value: is_write, key: 'is_write'})}</td>
                <td style={{ width: '70px'}}>{renderTick({ value: is_approve, key: 'is_approve'})}</td>
                <td style={{ width: '70px'}}>{renderTick({ value: is_publish, key: 'is_publish'})}</td>
                <td style={{ width: '70px'}}>{renderTick({ value: is_admin, key: 'is_admin'})}</td>
            </tr>
        )
    }

    render() {
        const { items = [], updateUser, updateAdmin } = this.props;
        return(
            <div className="table-wrapper role-table">
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '200px' }}>User</th>
                            <th>Read</th>
                            <th>Write</th>
                            <th>Approved</th>
                            <th>Publish</th>
                            <th>Admin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => {
                            return (this.renderRow({ item, updateUser, updateAdmin }));
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default VersionsTable;