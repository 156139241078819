import React, { Component } from 'react';

import Inputs from '../Edit/Shared/Sidebar/Inputs';
import StepButtons from './StepButtons';

class StepComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_logs_open: false,
            is_error_logs_open: false,
        }
        this.openLog = this.openLog.bind(this);
        this.openErrorLog = this.openErrorLog.bind(this);
    }

    openLog() {
        this.setState({
            is_logs_open: !this.state.is_logs_open,
            is_error_logs_open: false,
        });
    }

    openErrorLog() {
        this.setState({
            is_error_logs_open: !this.state.is_error_logs_open,
            is_logs_open: false,
        });
    }

    render() {
        const {
            is_logs_open,
            is_error_logs_open,
        } = this.state;

        const {
            editComponent,
            deleteComponent,
            step,
            contents,
            hide_outputs,
            hideOutputs,
            is_approved,
            errors: model_errors,
        } = this.props;

        const {
            is_completed,
            outputsFormatted,
            outputs,
            errors:step_errors,
            timer,
            logs,
        } = contents;

        const {
            step: step_type,
            name: step_name,
            id,
        } = step;

        const errors = [...model_errors, ...step_errors];

        return (
            <div key={id} className={`component-wrapper ${logs && errors.length === 0 ? '' : 'success'} ${errors.length > 0 ? 'errors' : ''}`}>
                <div className={is_completed ? "component completed" : "component"}>
                    <div className="component-tab"></div>
                    <div className="component-icon">
                        <span className="plus"></span>
                    </div>
                    <div className="component-label">
                        <h6>{step_type}</h6>
                        <h4>{step_name}</h4>

                        {!hide_outputs &&
                            <Inputs 
                                inputs={outputsFormatted}
                                is_output={true}
                            />
                        }

                        <StepButtons 
                            outputs={outputs}
                            hide_outputs={hide_outputs}
                            logs={logs}
                            errors={errors}
                            timer={timer}
                            openErrorLog={this.openErrorLog}
                            openLog={this.openLog}
                            hideOutputs={hideOutputs}
                        />

                    </div>

                    <div className="component-edit" onClick={() => editComponent(id)}>
                        <span className="icon edit"></span>
                    </div>

                    {!is_approved &&
                        <div className="component-delete" onClick={() => deleteComponent(id)}>
                            <i className="fa fa fa-circle-xmark"></i>
                        </div>
                    }
                </div>

                {logs && is_logs_open && 
                    <div className="show-logs-area">
                        <pre>
                            <code>
                                {JSON.stringify(logs, null, 4)}
                            </code>
                        </pre>
                    </div>
                }

                {is_error_logs_open && errors.length && 
                    <div className="show-logs-area">
                        <pre>
                            <code>
                                {JSON.stringify(errors, null, 4)}
                            </code>
                        </pre>
                    </div>
                }

            </div>
        )
    }
}

export default StepComplete;