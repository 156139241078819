import { get } from 'lodash';
import en from './en';
import fr from './fr';

export function getLangSource(selected = 'en') {
    if (!en) throw new Error('English language file not defined in i8n');
    const map = {
        en,
        fr,
    };
    const lang = map[selected] || map.en;
    return {
        lang,
        translate: translate(lang),
        t: translate(lang),
        tc: translateAndCapitalise(lang),
    };
}

export function capitalise(str = '') {
    return str.substr(0, 1).toUpperCase() + str.substring(1).toLowerCase();
}

export function translate(lang) {
    return function find(path) {
        return get(lang, path, '-');
    }
}

export function translateAndCapitalise(lang) {
    return function find(path) {
        return capitalise(get(lang, path, '-'));
    }
}

export default {
    getLangSource,
    capitalise,
    translate,
    t: translate,
    tc: translateAndCapitalise,
}
