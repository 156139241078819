import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import {
    Router,
    Route,
    Switch,
} from 'react-router-dom';

import routes from './routes';
import store from './store';

import { createBrowserHistory } from 'history';
import { unique_id } from './services/swallow/utils/unique_id';

import NotFound from './services/swallow/pages/NotFound';

const history = createBrowserHistory();

export const DefaultRender = (props) => (
    <div>
        <h3>This is a default page element</h3>
        <p>This probably means you haven't added a component in the routes</p>
    </div>
);

function flattenRoutes(routes = []) {
    const flatLinks = [];
    routes.forEach(link => {
        const { sublinks = [], path, component } = link
        if (sublinks.length === 0) {
            flatLinks.push({
                path,
                component,
            });
        } else {
            sublinks.forEach(s => {
                flatLinks.push({
                    path: s.path,
                    component: s.component,
                });
            })
        }
    });
    return flatLinks;
}

ReactDOM.render(
    <Provider store={store()}>
        <Router history={history}>
            <Switch>
                {flattenRoutes(routes).map(r => {
                    let { component } = r;
                    if (!component) component = DefaultRender;
                    return (
                        <Route key={unique_id()} exact={true} path={r.path} component={component} />
                    );
                })}
                <Route key={unique_id()} path='*' component={NotFound} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root'),
);