import React, { Component } from 'react';
import config from '../../../../../config';
import { CopyBlock } from "react-code-blocks";

const theme = {
    lineNumberColor: `#8D7DC9`,
    lineNumberBgColor: `#37247B`,
    backgroundColor: `#37247B`,
    textColor: `#f8f8f2`,
    substringColor: `#59BDE8`,
    keywordColor: `#59BDE8`,
    attributeColor: `#FFFFFF`,
    selectorTagColor: `#8be9fd`,
    docTagColor: `#59BDE8`,
    nameColor: `#66d9ef`,
    builtInColor: `#FFFFFF`,
    literalColor: `#FF79C6`,
    bulletColor: `#8BE9FD`,
    codeColor: `#50FA7B`,
    additionColor: `#59BDE8`,
    regexpColor: `#F1FA8C`,
    symbolColor: `#F1FA8C`,
    variableColor: `#F8F8F2`,
    templateVariableColor: `#FF79C6`,
    linkColor: `#00bcd4`,
    selectorAttributeColor: `#FF79C6`,
    selectorPseudoColor: `#FF79C6`,
    typeColor: `#8BE9FD`,
    stringColor: `#F1FA8C`,
    selectorIdColor: `#50FA7B`,
    selectorClassColor: `#50FA7B`,
    quoteColor: `#E9F284`,
    templateTagColor: `#FF79C6`,
    deletionColor: `#FF79C6`,
    titleColor: `#ff555580`,
    sectionColor: `#F8F8F2`,
    commentColor: `#6272A4`,
    metaKeywordColor: `#50FA7B`,
    metaColor: `#50FA7B`,
    functionColor: `#50FA7B`,
    numberColor: `#ffffff`,
};

// Formatting here looks weird but it renders correctly. Don't mess.

const codeBuild = ({ data = [], section = 'curl', project_reference }) => {
    let language = 'javascript';
    let code = '';

    // Curl
    if (section === 'curl') {
        language = 'javascript';
        code =`curl ${config.root}/sw/public/quotes?project_reference=${project_reference} \\
   -H 'client_id_key=example-4f99-4ce6-922b-e46448fe32a4'
`;
}

    //CLI
    if (section === 'cli') {
        language = 'javascript';
        code =`swallow get_quotes  \\
`;

        code +=`    --project_reference='${project_reference}' \\ \n`;
    }

    // Node
    if (section === 'node') {
        language = 'javascript';

        let obj = {};
        data.forEach(d => {
            obj[d.key] = d.def;
        });

        code = `const swallow = require('swallow-platform')('example-4f99-4ce6-922b-e46448fe32a4');
const quote = await swallow.get_quotes({ project_reference: '${project_reference}' });`
    }

    if (section === 'python') {
        language = 'python';
        code =`
import swallow_platform
import swallow_platform.api_key = "example-4f99-4ce6-922b-e46448fe32a4"
swallow_platform.get_quotes(
`;
        code +=`    project_reference='${project_reference}'`;
        code +=`\n`;
        code +=`)`;
    }

    if (section === 'net') {
        language = 'javascript';
        code =`SwallowPlatform.ApiKey = 'example-4f99-4ce6-922b-e46448fe32a4';
var options = new QuoteCreateOptions
{
`;
        code +=`    project_reference = '${project_reference}'\n}\n}`;
        code +=`var service = new QuotesGet();\n`;
        code +=`service.Create(options);`;
    }

    return {
        language,
        code,
    }

}

class DataAPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            section: 'curl',
        }
    }

    render() {
        const { data, project_reference } = this.props;
        const { section } = this.state;

        const { code } = codeBuild({ data, project_reference, section });
        const { language:curl_language, code:curl_code } = codeBuild({ data, project_reference, section: 'curl' });
        const { language:cli_language, code:cli_code } = codeBuild({ data, project_reference, section: 'cli' });
        const { language:node_language, code:node_code } = codeBuild({ data, project_reference, section: 'node' });
        const { language:python_language, code:python_code } = codeBuild({ data, project_reference, section: 'python' });
        const { language:net_language, code:net_code } = codeBuild({ data, project_reference, section: 'net' });

        return (
            <div className="code-container">
                <div className="code-header">
                    <ul>
                        <li onClick={() => this.setState({ section: 'curl'})} className={`${section === 'curl' ? 'active' : ''}`}>Curl</li>
                        <li onClick={() => this.setState({ section: 'cli'})} className={`${section === 'cli' ? 'active' : ''}`}>Swallow CLI</li>
                        <li onClick={() => this.setState({ section: 'node'})} className={`${section === 'node' ? 'active' : ''}`}>Node</li>
                        <li onClick={() => this.setState({ section: 'python'})} className={`${section === 'python' ? 'active' : ''}`}>Python</li>
                        <li onClick={() => this.setState({ section: 'net'})} className={`${section === 'net' ? 'active' : ''}`}>.NET</li>
                    </ul>
                </div>
                <div className="code-subtitle">
                    <div className="code-subtitle-left">
                        {section === 'curl' && <span><i className="fa fa-terminal"></i> Command Line</span>}
                        {section === 'cli' && <span><i className="fa fa-terminal"></i> Command Line</span>}
                        {section === 'node' && <span><i className="fa fa-file"></i> server.js</span>}
                        {section === 'python' && <span><i className="fa fa-file"></i> server.py</span>}
                        {section === 'net' && <span><i className="fa fa-file"></i> Program.cs</span>}
                    </div>
                    <div className="code-subtitle-right">
                        <span onClick={() => navigator.clipboard.writeText(code)}><i className="fa fa-copy"></i> COPY</span>
                    </div>
                </div>

                <div className={`code-block ${section === 'curl' ? 'on' : ''}`}>
                    <CopyBlock
                        text={curl_code}
                        language={curl_language}
                        showLineNumbers={true}
                        wrapLines
                        theme={theme}
                    />
                </div>

                <div className={`code-block ${section === 'cli' ? 'on' : ''}`}>
                    <CopyBlock
                        text={cli_code}
                        language={cli_language}
                        showLineNumbers={true}
                        wrapLines
                        theme={theme}
                    />
                </div>

                <div className={`code-block ${section === 'node' ? 'on' : ''}`}>
                    <CopyBlock
                        text={node_code}
                        language={node_language}
                        showLineNumbers={true}
                        wrapLines
                        theme={theme}
                    />
                </div>

                <div className={`code-block ${section === 'python' ? 'on' : ''}`}>
                    <CopyBlock
                        text={python_code}
                        language={python_language}
                        showLineNumbers={true}
                        wrapLines
                        theme={theme}
                    />
                </div>

                <div className={`code-block ${section === 'net' ? 'on' : ''}`}>
                    <CopyBlock
                        text={net_code}
                        language={net_language}
                        showLineNumbers={true}
                        wrapLines
                        theme={theme}
                    />
                </div>
            </div>
        );
    }
};

export default DataAPI;