import React, { Component } from 'react';

import { getId, getQuery } from '../../../../utils/url';

import FormElements from '../../components/Public/FormElements';
import Result from '../../components/Public/Result/Result';
import Api from '../../components/Public/Api/Api';

import { each, isEmpty, omit } from 'lodash';

function map_inputs(inputs = {}) {
    let arr = [];
    each(inputs, (v, k) => {
        arr = [...arr, {
            key: k,
            ...v,
        }]
    });
    return arr;
}

class PublicTry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            section: 'data',
        }
        this.runQuote = this.runQuote.bind(this);
        this.clearQuote = this.clearQuote.bind(this);
        this.defaultPayload = this.defaultPayload.bind(this);
        this.validateQuote = this.validateQuote.bind(this);
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        const section = getQuery(this.props.history, 'section') || 'data';
        await this.props.swallow_public_projects_get({ id });
        this.setState({ section, project_reference: id })
    }

    async runQuote() {
        await this.defaultPayload();
        const { payload, project_reference } = this.state;
        this.setState({ quote: {} });
        
        await this.props.swallow_public_quotes_post_some({
            id: project_reference,
            data: payload,
        });

        const { selected:quote = {} } = this.props.swallow_public_quotes;
        this.setState({ quote });
    }

    defaultPayload() {
        let { payload = {} } = this.state;
        const { selected:project = {} } = this.props.swallow_public_projects;
        const { inputs } = project;
        const fields = map_inputs(inputs);
        fields.forEach(f => {
            if (!payload[f.key]) {
                payload[f.key] = f.def;
            }
        });
        this.setState({ payload });
    }

    validateQuote() {
        const { payload = {} } = this.state;
        const { selected:project = {} } = this.props.swallow_public_projects;
        const { inputs } = project;
        const fields = map_inputs(inputs);
        let errors = {}
        let valid = true;
        fields.forEach(f => {
            if (!payload[f.key]) {
                errors[f.key] = {
                    type: 'required',
                    message: 'This is required',
                }
                valid = false;
            }
        });
        this.setState({ errors });
        return valid;
    }

    clearQuote(){
        this.setState({
            payload: {},
            quote: {},
            errors: {},
        })
    }

    render() {

        const { isFetching:isFetchingProject, selected:project = {} } = this.props.swallow_public_projects;
        const { isFetching:isFetchingQuote } = this.props.swallow_public_quotes;

        const isFetching = (isFetchingProject || isFetchingQuote);

        const { lists = {}, inputs = {} } = project;
        const fields = map_inputs(inputs);
        const { payload = {}, errors = {}, quote = {}, section, project_reference } = this.state;

        return (
            <div className="swallow_public">
                <div className="try_form">
                    <div className="try-container">

                        <div className={`try-container-left ${(isEmpty(quote))? '' : 'with-quote'}`}>
                            <div className="try-button-header">
                                <div className="try-button-header-left">
                                    <div className="try-toggle-buttons">
                                        <button onClick={() => this.setState({ section: 'data'})} className={`button small ${section === 'api' ? 'off' : 'secondary'}`}>DATA</button>
                                        <button onClick={() => this.setState({ section: 'api'})} className={`button small ${section === 'data' ? 'off' : 'secondary'}`}>API</button>
                                    </div>
                                </div>

                                { section === 'data' &&
                                <div className="try-button-header-right desktop">
                                    {!isFetchingQuote && <button className="button tertiary" onClick={this.clearQuote}>Clear quote</button>}
                                    {!isFetchingQuote && <button className="button primary" onClick={this.runQuote}>Run quote</button>}
                                    {isFetchingQuote && <button className="button primary" onClick={this.runQuote}><i className="fa-solid fa-spinner fa-spin"></i> Running</button>}
                                </div>
                                }

                                { section === 'data' &&
                                <div className="try-button-header-right mobile">
                                    {!isFetchingQuote && <button className="button tertiary" onClick={this.clearQuote}>Clear</button>}
                                    {!isFetchingQuote && <button className="button primary" onClick={this.runQuote}>Run</button>}
                                    {isFetchingQuote && <button className="button primary" onClick={this.runQuote}><i className="fa-solid fa-spinner fa-spin"></i> Run</button>}
                                </div>
                                }

                            </div>

                            {!isFetchingProject && <div className={`try-content ${section === 'data' ? 'on' : ''}`}>
                                {fields.map(f => {
                                    const { key, type, label } = f;
                                    return (
                                        <FormElements
                                            key={key}
                                            form_key={key}
                                            type={type}
                                            label={label}
                                            update={(value) => {
                                                const { payload } = this.state;
                                                this.setState({
                                                    errors: omit(errors, key),
                                                    payload: {
                                                        ...payload,
                                                        [key]: value,
                                                    }
                                                });
                                            }}
                                            value={payload[key]}
                                            errors={errors[key]}
                                            lists={lists} 
                                        />
                                    );
                                })}
                            </div>}

                            {!isFetchingProject && <div className={`try-content ${section === 'api' ? 'on' : ''}`}>
                                <Api 
                                    data={fields}
                                    project_reference={project_reference}
                                />
                            </div>}

                        </div>

                        <div className={`try-container-right ${(isEmpty(quote))? '' : 'with-quote'}`}>
                            <Result 
                                is_running={isFetching}
                                quote={quote}
                                clearQuote={this.clearQuote}
                            />
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default PublicTry;