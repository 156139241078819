export function parseSource(source = {}) {
    const { type, example } = source;
    if (type === 'json') {
        try {
            const result = JSON.parse(example);
            return result;
        } catch(e) {
            return null;
        }
    }
    // TODO CSV, XML
    return null;
}