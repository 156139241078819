import BaseActions from './base';
import config from '../config';

const base = config;

const sessions = new BaseActions({
    path: `${base.root}/a/sessions`,
    type: 'ALLOW_SESSIONS',
});

export default sessions;