/*
* All routing
*/

import main from '../hoc/main';

import Wireframes from '../components/wireframes/Index';

import SuperMain from '../services/swallow/pages/super/Main';

import AllowPools from '../services/allow/pages/Pools';
import AllowPoolDetail from '../services/allow/pages/PoolDetail';
import AllowUserDetail from '../services/allow/pages/UserDetail';
import AllowUsers from '../services/allow/pages/Users';
import AllowSessions from '../services/allow/pages/Sessions';
import AllowSessionDetail from '../services/allow/pages/SessionDetail';
import AllowSettings from '../services/allow/pages/Settings';
import AllowLogin from '../services/allow/pages/Login';

import SwallowLogin from '../services/swallow/pages/Login';
import SwallowSettingsTeam from '../services/swallow/pages/app/SettingsTeam';
import SwallowProjects from '../services/swallow/pages/app/Projects';
import SwallowProject from '../services/swallow/pages/app/Project';
import SwallowProjectData from '../services/swallow/pages/app/ProjectData';
import SwallowVersionData from '../services/swallow/pages/app/VersionData';
import SwallowVersionDataDetail from '../services/swallow/pages/app/VersionDataDetail';
import SwallowProjectLogs from '../services/swallow/pages/app/ProjectLogs';
import SwallowProjectTeam from '../services/swallow/pages/app/ProjectTeam';
import SwallowProjectTry from '../services/swallow/pages/app/ProjectTry';
import SwallowVersionHome from '../services/swallow/pages/app/VersionHome';
import SwallowVersionDesign from '../services/swallow/pages/app/VersionDesign';
import SwallowVersionTests from '../services/swallow/pages/app/VersionTests';
import SwallowVersionTry from '../services/swallow/pages/app/VersionTry';
import SwallowVersionReview from '../services/swallow/pages/app/VersionReview';
import SwallowVersionTeam from '../services/swallow/pages/app/VersionTeam';

import SwallowPublicTry from '../services/swallow/pages/app/PublicTry';
import SwallowPublicData from '../services/swallow/pages/app/PublicData';

let routes = [        {
    name: 'Wireframes',
    path: '/wf',
    component: Wireframes,
    hide: true,
}, {
    name: 'Super',
    path: '/super',
    component: SuperMain,
    hide: true,
}];

/*
properties:
name - Link display name
icon - feather icon for display
path - route path. This automatically defined in Router 
product - when to show link with in Policy or Rating view
component - The React Page to render
sublinks - The dropdown children links
hide - if true, this sets up the route but doesn't display it. Use for details pages mainly
*/

// env switch to pick right actions
const main_component = process.env.REACT_APP_MAIN;

if (main_component === 'allow') {
    routes = [
        ...routes,
        {
            name: 'Home',
            path: '/',
            component: main(AllowPools, {
                private: true,
            }),
        },
        {
            name: 'Login',
            path: '/login/:id',
            hide: true,
            component: main(AllowLogin, {
                private: false,
            }),
        },
        {
            name: 'Users',
            path: '/users',
            component: main(AllowUsers, {
                private: true,
            }),
        },
        {
            name: 'Sessions',
            path: '/sessions',
            component: main(AllowSessions, {
                private: true,
            }),
        },
        {
            name: 'Pools',
            path: '/pools',
            component: main(AllowPools, {
                private: true,
            }),
        },
        {
            name: 'PoolDetail',
            path: '/pools/:id',
            component: main(AllowPoolDetail, {
                private: true,
            }),
            hide: true,
        },
        {
            name: 'SessionDetail',
            path: '/sessions/:id',
            component: main(AllowSessionDetail, {
                private: true,
            }),
            hide: true,
        },
        {
            name: 'UserDetail',
            path: '/users/:id',
            component: main(AllowUserDetail, {
                private: true,
            }),
            hide: true,
        },
        {
            name: 'Settings',
            path: '/settings',
            component: main(AllowSettings, {
                private: true,
            }),
        },
    ]
};

if (main_component === 'swallow') {
    routes = [
        ...routes,
        {
            name: 'Landing',
            path: '/',
            component: main(SwallowProjects, {
                private: true,
            }),
        },
        {
            name: 'Login',
            path: '/login',
            component: main(SwallowLogin, {
                private: false,
            }),
        },
        {
            name: 'Settings Team',
            path: '/settings/team',
            component: main(SwallowSettingsTeam, {
                private: true,
            }),
        },
        {
            name: 'Projects',
            path: '/projects',
            component: main(SwallowProjects, {
                private: true,
            }),
        },
        {
            name: 'Project Data',
            path: '/project/data/:id',
            component: main(SwallowProjectData, {
                private: true,
            }),
        },
        {
            name: 'Project Logs',
            path: '/project/logs/:id',
            component: main(SwallowProjectLogs, {
                private: true,
            }),
        },
        {
            name: 'Project Try',
            path: '/project/try/:id',
            component: main(SwallowProjectTry, {
                private: true,
            }),
        },
        {
            name: 'Project Team',
            path: '/project/team/:id',
            component: main(SwallowProjectTeam, {
                private: true,
            }),
        },
        {
            name: 'Version Home',
            path: '/version/home/:id',
            component: main(SwallowVersionHome, {
                private: true,
            }),
        },
        {
            name: 'Version Publish',
            path: '/version/publish/:id',
            component: main(SwallowVersionDesign, {
                private: true,
            }),
        },
        {
            name: 'Version Tests',
            path: '/version/tests/:id',
            component: main(SwallowVersionTests, {
                private: true,
            }),
        },
        {
            name: 'Version Try',
            path: '/version/try/:id',
            component: main(SwallowVersionTry, {
                private: true,
            }),
        },
        {
            name: 'Version Data',
            path: '/version/data/:id',
            component: main(SwallowVersionData, {
                private: true,
            }),
        },
        {
            name: 'Version Data Detail',
            path: '/data/detail/:id',
            component: main(SwallowVersionDataDetail, {
                private: true,
            }),
        },
        {
            name: 'Home',
            path: '/home',
            component: main(SwallowProjects, {
                private: true,
            }),
        },
        {
            name: 'Project',
            path: '/project/:id',
            component: main(SwallowProject, {
                private: true,
            }),
        },
        {
            name: 'Version',
            path: '/version/process/:id',
            component: main(SwallowVersionReview, {
                private: true,
            }),
        },
        {
            name: 'Team',
            path: '/version/team/:id',
            component: main(SwallowVersionTeam, {
                private: true,
            }),
        },
        {
            name: 'Public Try Form',
            path: '/public/try/:id',
            component: main(SwallowPublicTry, {
                private: false,
            }),
        },
        {
            name: 'Public Data',
            path: '/public/data/:id',
            component: main(SwallowPublicData, {
                private: false,
            }),
        },
    ]
};

export default routes;
