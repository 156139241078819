import moment from 'moment';

export default function getCurrencySymbol(currency) {
    const symbols = {
        GBP: '£',
        EUR: '€',
        USD: '$',
        AUD: '$',
    }
    return symbols[currency];
}

export function toUpper(str = '') {
    return str?.toUpperCase();
}

export function defaultDateFormat(date) {
    return moment(date).utc().format('DD/MM/YYYY');
}

export function extendedDateFormat(date) {
    return moment(date).utc().format('HH:mm - DD/MM/YY')
}

export function chartDateFormat(range) {
    const map = {
        'hour': 'HH:mm',
        'day': 'HH:mm',
        'week': 'dd/MM',
        'month': 'dd/MM',
    }
    return map[range] || 'HH:mm';
}

export function addEndDateTime(date) {
    const newDate = moment(date)
        .utc()
        .add(23, 'h')
        .add(59, 'm')
        .add(59, 's')
        .toDate();

    return newDate;
}