
import React, { Component } from 'react';

class TeamUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: null,
        }
    }

    render() {
        const { 
            users = [],
            selected_user,
            selectUser,
        } = this.props;

        return (
            <ul>
                {users.map((u, i) => {
                    const { 
                        is_admin,
                        email,
                        user_reference,
                    } = u;

                    const active = user_reference === selected_user.user_reference;

                    return (<li key={user_reference} className={`${active ? 'active' : ''}`}>
                        <button 
                            onClick={() => {
                                selectUser(user_reference)
                            }}
                            key={user_reference} 
                            className={`${active ? 'black' : 'grey'} button big`}
                        >
                            {is_admin ?
                                <i className="fa fa-solid fa-user-gear"></i> :
                                <i className="fa fa-regular fa-user"></i>
                            }{ email }
                        </button>
                    </li>)
                })}
            </ul>
        )
    }

}

export default TeamUserList;