import React, { Component } from 'react';
import BooleanSelect from '../../../../Design/Edit/Shared/Forms/BooleanSelect';
import SelectSearch from 'react-select-search';

class ExpressionCommandForm extends Component {
    render() {
        let{
            command_key: key,
            index,
            type,
            value,
            updateCommand,
            lists = [],
        } = this.props;

        if (lists.length === 1 && lists[0] === '') lists = [];

        return (
            <div key={key + index} className={'spacing'}>

                {type === 'string' && lists.length === 0 &&
                    <input 
                        onChange={(e) => {
                            let { value } = e.target;
                            updateCommand({
                                key,
                                value,
                                index,
                            })
                        }} 
                        value={value}
                    />
                }

                {type === 'string' && lists.length > 0 &&
                    <SelectSearch 
                        options={lists.map(l => {
                            return { name: l, value: l };
                        })} 
                        autoComplete={false}
                        autoFocus={false}
                        value={value} 
                        name={key}
                        placeholder=""
                        onChange={(value) => {
                            updateCommand({
                                key,
                                value,
                                index,
                            })
                        }}  
                    />
                }

                {(type === 'integer' || type === 'decimal')&&
                    <input 
                        type="number"
                        onChange={(e) => {
                            let { value } = e.target;
                            value = (value !== '' ? Number(value) : '');
                            updateCommand({
                                key,
                                value,
                                index,
                            })
                        }} 
                        value={value}
                    />
                }

                {(type === 'date')&&
                    <input 
                        type="date"
                        className="date"
                        onChange={(e) => {
                            let { value } = e.target;
                            updateCommand({
                                key,
                                value,
                                index,
                            })
                        }} 
                        value={value}
                    />
                }

                {(type === 'boolean') &&
                    <BooleanSelect 
                        update={({ value }) => {
                            updateCommand({
                                key,
                                value,
                                index,
                            })
                        }}
                        value={value}
                    />
                }

            </div>
        );
    }
}

export default ExpressionCommandForm;