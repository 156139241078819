import React, { Component } from 'react';

import TestsOverview from './TestsOverview';

import { flatten, uniq } from 'lodash';

class Tests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_tests: [],
            search_term: '',
            tag_term: '',
        }
        this.filterTests = this.filterTests.bind(this);
    }

    filterTests(tests = []) {
        const { search_term, tag_term } = this.state;
        let filtered_tests = tests;

        if (search_term !== '' && search_term.length > 3) {
            const lc_search_term = search_term.toLowerCase();
            filtered_tests = filtered_tests.filter(test => {
                return test.key.includes(lc_search_term);
            });
        }

        if (tag_term !== '') {
            filtered_tests = filtered_tests.filter(test => (test.tags || []).includes(tag_term));
        }

        return filtered_tests;
    }

    render() {
        const { tests = [], project = {}, is_running, inputs } = this.props;
        const { search_term, tag_term } = this.state;

        const {
            tests_searchable,
            tests_count,
            read_only,
        } = this.props;

        const filtered_tests = this.filterTests(tests);
        const tags = uniq(flatten(tests.map(t => t.tags)));

        const {
            status = '',
        } = project.meta || {};

        const is_approved = (status === 'published' || status === 'approved');

        return (
            <section className="main-content slide-left">
                
                {!is_approved && 
                <div className="design-button-area">
                    <div className="design-button-area-left"></div>
                    <div className="design-button-area-right">

                        {!read_only && <button onClick={() => {
                            this.props.createTest();
                        }} className="button main big mr">
                            Create Test
                        </button>}

                        {tests.length > 0 && !read_only &&
                            <button onClick={() => {
                                this.props.runTests(tests);
                            }} className="button main big">
                                <i className="fa fa-play"></i>
                                Run All Tests
                            </button>
                        }

                    </div>
                </div>
                }

                <div className={`test-area ${is_approved ? 'approved' : ''}`}>

                    <div className="test-area-left">

                        {tests.length > 0 && tests_searchable && <div className="project">
                            <div className="search-block">

                                <div className="search-block-item">
                                    <fieldset className={'spacing'}>
                                        <label>Search Test Name</label>
                                        <input onChange={(e) => {
                                            this.setState({
                                                search_term: e.target.value,
                                            })
                                        }} value={search_term} />
                                    </fieldset>
                                </div>

                                <div className="search-block-item">
                                    <fieldset className={'spacing'}>
                                        <label>Search Tags</label>
                                        <select 
                                            value={tag_term} 
                                            onChange={(e) => {
                                                this.setState({
                                                    tag_term: e.target.value,
                                                });
                                            }
                                        }>
                                            <option value={''}></option>
                                            {tags.map(t => {
                                                return (<option key={t} value={t}>{t}</option>);
                                            })}
                                        </select>
                                    </fieldset>
                                </div>

                            </div>
                           
                        </div>
                        }

                        <div className="project"> 
                            <TestsOverview 
                                deleteTest={this.props.deleteTest}
                                viewTest={this.props.viewTest}
                                tests={filtered_tests} 
                                height={600}
                                tests_count={tests_count}
                                is_running={is_running}
                                completeUpload={this.props.uploadTests}
                                read_only={read_only}
                                inputs={inputs}
                            />
                        </div>

                        {/*tests.length > 0 && tests_external &&
                            <div className="project">                          
                                <TestsExternal 
                                    tests={filtered_tests} 
                                    tests_config={tests_config}
                                    tests_saved={tests_saved}
                                />
                            </div>
                        */}

                    </div>

                    <div className="test-area-right">
                    </div>

                </div>

            </section>
        );
    }
}

export default Tests;