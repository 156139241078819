import React, { Component } from 'react';

import { keys, omit, sample, times, round, isString } from 'lodash';

import slugify from '../../../../utils/slugify';
import { DownloadButton } from '../../../../utils/download';
import { UploadArea } from '../../../../utils/upload';
import { VirtualTable } from '../../../../utils/virtualTable';

function populateFactor(inputs = {}, dimensions = 1, key) {
    let results = [];

    let base = {
        weight: 1,
        exclude: false,
    };

    function randomValue ({ type, value, i }) {
        if (type === 'decimal' || type === 'integer') {
            value = value + (i * 1);
        } else if ( type === 'boolean') {
            if (i % 2 === 0) {
                value = false;
            } else {
                value = true
            } 
        } else {
            value = value = '_' + (i + 1);
        }
        return isString(value) ? value : value.toString();
    }

    // base schema
    times(dimensions, (i) => {
        const dim = i + 1;
        let key_inputs = keys(inputs).filter(k => k !== key);
        if (key_inputs.length > 1) key_inputs = key_inputs.filter(k => !(k === 'base' || k === '{{base}}'));
        const selected_key = sample(key_inputs);
        const input = inputs[selected_key] || {};
        let { value, type } = input;
        base[`input_${dim}`] = selected_key;
        base[`value_${dim}`] = value;
        base[`value_to_${dim}`] = '';
        base[`value_${dim}_type`] = type;
    });

    // rows
    times(10, (i) => {
        const obj = { ...base };
        obj.weight = round(base.weight + (i * 0.1), 1);
        obj.value_1 = randomValue({ type: obj.value_1_type, value: obj.value_1, i });
        delete obj.value_1_type;
        if (dimensions === 2)  {
            obj.value_2 = randomValue({ type: obj.value_2_type, value: obj.value_2, i });
            delete obj.value_2_type;
        }
        if (dimensions === 3)  {
            obj.value_3 = randomValue({ type: obj.value_3_type, value: obj.value_3, i });
            delete obj.value_3_type;
        }
        results = [ ...results, obj];
    });

    let last_obj = { 
        ...base,
        value_1: 'def',
    };

    if (dimensions === 2) last_obj.value_2 = 'def';
    if (dimensions === 3) last_obj.value_3 = 'def';
    results = [...results, last_obj];

    return results;
}

class FactorsWithData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: {},
            key: (this.props.step || {}).key,
            upload: this.props.upload_open || false,
        }
        this.uploadedFactor = this.uploadedFactor.bind(this);
        this.starterFactor = this.starterFactor.bind(this);
        this.updateStep = this.updateStep.bind(this);
        this.changeKey = this.changeKey.bind(this);
        this.updateKey = this.updateKey.bind(this);
    }

    componentDidMount() {
        const { step } = this.props;
        this.setState({
            step,
        });
    }

    starterFactor(dimensions = 1) {
        const { inputs } = this.props;
        const { key } = this.state;
        const results = populateFactor(inputs, dimensions, key);
        this.uploadedFactor(results);
    }

    uploadedFactor(model) {
        const { step }= this.state;
        this.updateStep({
            ...step,
            ...model,
        });
    }

    changeKey(e) {
        const key = e.target.value;
        this.setState({
            key: slugify(key),
        });
    }

    updateKey(e) {
        e.preventDefault();
        const { key } = this.state;
        const { step }= this.props;
        this.props.updateKey({
            old_key: step.key,
            new_key: key,
        })
    }

    updateStep(step) {
        this.setState({
            step,
        }, () => {
            this.setState({ upload: false });
             this.props.updateStep({
                factor: omit(step, 'key'),
             });
        }) 
    }

    render() {
        const { is_approved, inputs } = this.props;

        const { 
            step: factor = {},
            upload = false,
            key,
        } = this.state;

        const {
            hashes = {},
        } = factor;

        const rows = keys(hashes);

        return (
            <div>
                <div className="shim-buttons-left" style={{ left: is_approved ? 0 : -340}}>
                    <button className="button white big mr" onClick={async () => {
                        if(!is_approved) { 
                            if(rows.length) {
                                this.props.updateStep({
                                    factor: omit(factor, 'key'),
                                });
                            }
                        }
                        this.props.clearSelected();
                    }}>&lt; Go Back</button>
                </div>

                {!is_approved &&
                    <div style={{ margin: '0px 0px 10px 0px', textAlign: 'right' }}>
                        <button className="button grey small mr"
                            onClick={() => this.props.clearSelected()}
                        > Clear Factor</button>
                        <button className="button secondary small" 
                            onClick={(e) => {
                                this.updateKey(e);
                                this.props.clearSelected();
                            }}
                        > Save Factor</button>
                    </div>
                }

                {!is_approved && <div className="shim-buttons-right">
                    <button className="button secondary big" onClick={async () => {
                        if(rows.length) { this.updateStep(); }
                        this.props.clearSelected();
                    }}>Save Step</button>
                </div>}

                {!is_approved && 
                    <div className="edit-inputs">
                        <fieldset>
                            <label>Factor Name</label>
                            <input type="text" value={key} onChange={(e) => this.changeKey(e)}/>
                        </fieldset>
                    </div>
                }

                <div key={`full-buttons`} className="upload-buttons">
                    <button className="button small grey no-hover mr">Rows : {rows.length}</button>
                    {rows.length === 0 && <button className="button small white mr" onClick={() => this.starterFactor(1)}><i className="fa fa-circle-plus"></i>Template New Factor (1D)</button>}
                    {rows.length > 0 && <DownloadButton 
                        data={{
                            factor,
                        }}
                        label={'Factor'}
                        file_name={`${factor.key}.csv`}
                        type={'factor'}
                        className={'mr'}
                    />}
                    {!is_approved && <button className="button small secondary mr" onClick={() => this.setState({ upload: !this.state.upload })}><i className="fa fa-angles-up"></i>Upload Factor (CSV)</button>}
                </div>

                {(rows.length  === 0 || upload) &&
                    <UploadArea 
                        type='factor'
                        inputs={inputs}
                        complete={this.uploadedFactor}
                    />
                }

                {!upload && rows.length > 0 &&
                    <div className="exclusions-edit">
                        <VirtualTable 
                            data={factor} 
                            type='factor'
                        />
                    </div>
                }

            </div>
        );
    }
}

export default FactorsWithData;