import React, { Component } from 'react';
import { evaluateExpressions } from '../Expression/ExpressionTextarea';
import { unique_id } from '../../../../../utils/unique_id';

import { validate } from '../../../../../utils/validate_project';

import { isUndefined, omit } from 'lodash';

class ItemsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
        }
        this.enrichWithDefault = this.enrichWithDefault.bind();
        this.updateItem = this.updateItem.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.validate = this.validate.bind(this);
        this.clearExpression = this.clearExpression.bind(this);
        this.updateItemFromTable = this.updateItemFromTable.bind(this);
        this.topForm = React.createRef();
    }

    // This validates just the exclusion item
    validate(data) {
        let { step, inputs } = this.props;

        this.setState({ errors: [] })

        const result = validate({
            step: {
                ...step,
                ...data,
            }
        });

        if (!result.valid) {
            this.setState({ errors: result.errors });
            return false;
        }

        if(!inputs) return true;

        if (data.items && data.items.length > 0) {
            let exp_errors = evaluateExpressions({
                inputs,
                items: data.items,
            }).filter(exp => exp.invalid);

            this.setState({
                errors: exp_errors.map(e => {
                    return {
                        type: 'step',
                        key: step.key,
                        path: `format.${step.key}.exp`,
                        message: e.message,
                        keyword: 'expression'
                    }
                })
            });
            if (exp_errors.length > 0) return false;
        }

        return true;
    }

    updateItem (data) {
        const { selected } = this.state;

        const new_data = {
            ...selected,
            ...data,
        }

        this.setState({
            selected: new_data
        });
    }

    clearExpression () {
        this.setState({
            selected: null,
            test: null,
        });
    }

    selectItem (selected) {
        this.setState({
            selected,
            test: null,
        }, () => {
            window.scrollTo({
                top: 140,
                behavior: 'smooth',
            });
        })
    }

    enrichWithDefault({
        items,
        step,
    }) {
        let new_items = items || [];
        
        if (['excesses', 'endorsements'].includes(step.step)) {
            let { def = [] } = step;
            new_items.forEach(ni => {
                if (!isUndefined(ni.new_def)) {
                    if (ni.new_def) {
                        def = [ ni.value, ...def ];
                    }
                } else {
                    if (def.includes(ni.value)) {
                        def = [ ni.value, ...def ];
                    }
                }
                return omit(ni, 'new_def');
            });
            return { 
                def,
                items:new_items,  
            }
        }

        if (['label'].includes(step.step)) {
            let def = step.def;
            new_items = new_items.map(ni => {
                if (ni.new_def) def = ni.value;
                return omit(ni, 'new_def');
            });
            return { 
                def,
                items:new_items, 
            }
        }

        // refer or exclusion
        return { items: new_items };
    }

    async updateItems() {
        
        const { step, updateStep } = this.props;
        const { items = [] } = step;
        const { id = '' } = this.state.selected || {};

        let new_items = items;

        // New Item
        if (id === '') {
            new_items = [...items, {
                ...this.state.selected,
                id: unique_id(),
            }];
        // Update Item
        } else {
            new_items = items.map(item => {
                if (item.id === id) {
                    return this.state.selected;
                }
                return item;
            });
        }

        const payload = this.enrichWithDefault({
            step,
            items: new_items,
        });

        if(!this.validate(payload)) return;
        await updateStep(payload);
        this.clearExpression();
    }

    updateItemFromTable(new_items) {
        this.props.updateStep(new_items);
    }

    render() {
        return(<div></div>)
    }
};
export default ItemsEdit;