/* eslint react/jsx-no-target-blank:0 */

import React, { Component } from 'react';
import Footer from '../components/Layout/Footer';

class Home extends Component {
    render() {
        const { history } = this.props;
        console.log(history)
        return [
            <div className="login-canvas swallow_app" style={{ marginTop: 40 }}>
                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <h1><i className="fa-solid fa-triangle-exclamation"></i> 404 : The page doesn't exist</h1>
                            <p>I am afraid we can't find the page you have requested. Please click here to return <span onClick={() => history.goBack()} className="button darkblue">GO BACK</span></p>
                        </div>
                    </div>
                </div>
            </div>,
            <div className="global swallow_app"><Footer /></div>  
        ];
    }
}

export default Home;