import axios from 'axios';
import config from '../../../config';
import { get_auth } from '../../../auth';
import moment from 'moment';

import { getLocalVersion, putLocalVersion } from './localDB';

export async function putLargeVersion ({ version_reference, version }) {
    const { access_token: token } = await get_auth();

    const { signed_url } = await axios({
        url: `${config.root}/sw/projects/versions/large/${version_reference}`,
        method: 'put',
        headers: {
            authorization: `Bearer ${token}`,
        },
    }).then(({data}) => data);

    const result = await axios({
        url: signed_url,
        method: 'put',
        data: version,
    }).then(({data}) => data);

    return result;
}

export async function getLargeVersion ({ version_reference }) {
    const { access_token: token } = await get_auth();

    const { signed_url } = await axios({
        url: `${config.root}/sw/projects/versions/large/${version_reference}`,
        headers: {
            authorization: `Bearer ${token}`,
        }
    }).then(({data}) => data);

    const result = await axios({
        url: signed_url,
    }).then(({data}) => data);

    return result;
}

export async function getVersion({ version_reference, no_cache = false }) {
    const version_local = await getLocalVersion({ version_reference });
    let { created_at } = version_local;

    //Force fetch if no version or no_cache
    if (no_cache || !version_local) {
        const version_large = await getLargeVersion({ version_reference });
        await putLocalVersion({ version_reference, version: version_large });
        return version_large;
    } 
    
    //Force fetch if cache is older than 1 minutes
    if (created_at) {
        const diff = moment().diff(moment(created_at), 'seconds');
        const cache_time = 60;
        if (diff > cache_time) {
            const version_large  = await getLargeVersion({ version_reference });
            await putLocalVersion({ version_reference, version: version_large });
            return version_large;
        }
    }

    return version_local;
}

export async function putVersion({ version_reference, version, no_cache = false }) {
    let version_local = await getLocalVersion({ version_reference });
    let { created_at } = version_local;

    // Update meta
    version = {
        ...version,
        meta: { 
            ...version.meta,
            updated_at: moment().toISOString(),
        }
    }
    
    //Force fetch if no version or no_cache
    if (no_cache || !version_local) {
        await putLargeVersion({ version_reference, version });
        await putLocalVersion({ version_reference, version });
        return version;
    }
    
    //Force fetch if cache is older than 5 minutes
    if (created_at) {
        const diff = moment().diff(moment(created_at), 'seconds');
        const cache_time = 60;
        if (diff > cache_time) {
            await putLargeVersion({ version_reference, version });
            await putLocalVersion({ version_reference, version });
            return version;
        } else {
            await putLocalVersion({ version_reference, version });
            return version;
        }
    }

    await putLocalVersion({ version_reference, version });
    return version;
}