import React, { Component } from 'react';

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_valid: null,
            value: this.props.value || '',
        }
        this.update = this.update.bind(this);
    }

    update (value) {
        const {
            submit = () => {
                console.warn('No submit function')
                return null
            },
        } = this.props;

        this.setState({
            is_valid: value !=='',
        }, () => {
            submit(value);
        });
    }

    render () {
        const {
            name,
            label,
            items,
            value,
            disabled = false,
        } = this.props;
    
        const { is_valid } = this.state;
    
        return (
            <fieldset>
                <label>{label}</label>

                <select 
                    disabled={disabled}
                    ref={name}
                    onChange={(e) => this.update({
                        [name]: e.target.value,
                    })} 
                    name={name}
                    defaultValue={value}
                >
                    {items.map((item, i) => <option key={name + i} value={item.value}>{item.name}</option>)}
                </select>

                {is_valid === true && <div className="wf-valid"></div>}
                {is_valid === false && <div className="wf-invalid"></div>}
            </fieldset>
        )
    }
}

export default Select;
