import React, { Component } from 'react';
import { get, isEmpty, round } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../../actions';

import { getId, getQuery } from '../../../utils/url';
import viewerRoles from '../utils/viewer_roles';

import GlobalLoading from '../../../components/GlobalLoading';
import { Spotlight } from '../utils/spotlight';

import { error_response } from '../utils/errors';
import { evaluateSchema, projectSchema } from '@swa_llow/pricing_engine';
import { putVersion, getVersion } from '../utils/versions';

function getSizeMB(data) {
    const str = JSON.stringify(data);
    const size = round(str.length / 1024, 1); //KB
    return size;
}

export default (Page) => {
    class Versions extends Component {
        constructor(props) {
            super(props);
            this.state = {
                isFetching: false, 
                version_reference: null,
                version_size: 0,
                version_saved_at: null,
                cache: false,
                user: {},
                viewer_roles: [],
                version: {},
                process: {},
                tests: [],
                users: [],
                errors: [],
                spotlight: false,
                selected_step_id: null,
                selected_item_id: null,
            }
            this.getVersionProcess = this.getVersionProcess.bind(this);
            this.getVersionTests = this.getVersionTests.bind(this);
            this.getVersionProject = this.getVersionProject.bind(this);
            this.getUsers = this.getUsers.bind(this);
            this.refreshVersion = this.refreshVersion.bind(this);
            this.saveVersionProject = this.saveVersionProject.bind(this);
            this.spotlight = this.spotlight.bind(this);
        }

        // Setting up all data for the version on any version page load
        async UNSAFE_componentWillMount() {
            // To use URL routing to step
            const selected_step_id = getQuery(this.props.history, 'step_id', null);
            const selected_item_id = getQuery(this.props.history, 'item_id', null);
            this.setState({ selected_step_id, selected_item_id });
            
            const cache = getQuery(this.props.history, 'cache') !== '';
            const id = getId(this.props.history);
            await this.refreshVersion({ id, cache });

            this.interval = setInterval(async () => {
                const { version } = this.state;
                const result = evaluateSchema({
                    project: version,
                    schema: projectSchema,
                });
                if (result.valid) await putVersion({ version_reference:id, version });
            }, 60000);

            // This is so we can detect url changes to clear step_id and item_id
            this.unlisten = this.props.history.listen((location, action) => {
                let selected_step_id = getQuery(this.props.history, 'step_id', '');
                let selected_item_id = getQuery(this.props.history, 'item_id', '');
                if (selected_step_id === '') selected_step_id = null;
                if (selected_item_id === '') selected_item_id = null;
                this.setState({ selected_step_id, selected_item_id });
            });

            // Open Spotlight Search
            document.addEventListener('keydown', this.spotlight)
        }

        componentWillUnmount() {
            // Close Spotlight Search
            document.removeEventListener('keydown', this.spotlight);
            clearInterval(this.interval);
            this.unlisten();
        }

        spotlight(e = {}) {
            const { spotlight } = this.state;
            if ((e.metaKey || e.ctrlKey) && e.code === 'KeyK') {
                if (spotlight) {
                    this.setState({ spotlight: false });
                    document.body.style.height = 'auto';
                    document.body.style.overflow = 'auto';
                } else {
                    this.setState({ spotlight: true });
                    document.body.style.height = '100%';
                    document.body.style.overflow = 'hidden';
                }
            }
        }

        async refreshVersion({ id, cache }) {
            // We need to setup process first before subsequent calls
            const process = await this.getVersionProcess({ id });
            await this.getUsers({ project_reference: get(process, 'version.project_reference') });
            const user = this.props.auth.selected;
            const viewer_roles = viewerRoles({ team: process.team, user });
            this.setState({ version_reference: id, cache, viewer_roles, user });
            // We can do the rest of the calls now
            await this.getVersionTests({ id });
            await this.getVersionProject({ id });
        }

        async getVersionProject({ id }) {
            this.setState({ isFetching: true });

            //const { selected = {} } = this.props.swallow_versions;
            //if (isEmpty(selected)) await this.props.swallow_versions_get({ id });
            //const version = get(this.props, 'swallow_versions.selected', {});
            // const errors = get(this.props, 'swallow_versions.errors', []);

            const version =  await getVersion({ version_reference: id });
            const { updated_at } = version.meta;
            const version_size = getSizeMB(version);
            this.setState({ 
                version, 
                version_size, 
                version_saved_at: updated_at, 
                isFetching: false,
                errors: this.state.errors
            });
            return version;
        }
    
        async getVersionProcess({ id }) {
            this.setState({ isFetching: true });
            const { selected = {} } = this.props.swallow_process;
            if (isEmpty(selected)) await this.props.swallow_process_get({ id });
            const process = get(this.props, 'swallow_process.selected', {});
            const errors = get(this.props, 'swallow_process.errors', []);
            this.setState({ process, isFetching: false, errors: [ this.state.errors, ...errors ] });
            return process;
        }
    
        async getVersionTests({ id }) {
            this.setState({ isFetching: true });
            let { selected = {} } = this.props.swallow_tests;
            // TODO we will need to instate fetchTests for tests > 9.9MB
            if (isEmpty(selected)) await this.props.swallow_tests_get({ id });
            const tests = get(this.props, 'swallow_tests.selected', {});
            const errors = get(this.props, 'swallow_tests.errors', []);
            this.setState({ tests, isFetching: false, errors: [ this.state.errors, ...errors ] });
            return tests;
        }

        async getUsers({ project_reference }) {
            this.setState({ isFetching: true });
            let { data = [] } = this.props.swallow_users;
            if(data.length === 0) await this.props.swallow_users_get_all({ project_reference });
            const users = get(this.props, 'swallow_users.data', []);
            this.setState({ users, isFetching: false });
            return users;
        }

        async saveVersionProject({ project = {} }) {
            const { version_reference } = this.state;
            this.setState({ isFetching: true });

            const result = evaluateSchema({
                project,
                schema: projectSchema,
            });

            const version_size = getSizeMB(project);

            if (version_size > 99000000) {
                this.props.set_error('Model is greater than 10MB. You need to speak with the Swallow Team to store larger models');
                return this.setState({ isFetching: false });
            }
            
            if (result.valid) {
                await putVersion({ version_reference, version: project });
                /*
                await this.props.swallow_versions_put({
                    id: version_reference,
                    data: {
                        project,
                    }
                });
                */
                this.setState({ 
                    version: project,
                    version_saved_at: new Date(),
                    version_size, 
                });
            } else {
                const errors = (result.errors || []).map(e => error_response({
                    error: e,
                    label: `Project: Issue in ${e.step};`,
                }));
                console.warn(errors);
                this.props.set_error(errors[0]);
            }

            this.setState({ isFetching: false });
        }

        render() {
            const {
                version_reference,
                version_size,
                version_saved_at,
                cache,
                viewer_roles,
                version,
                process,
                tests,
                users,
                user,
                spotlight,
                selected_step_id,
                selected_item_id,
            } = this.state;

            const is_approved = get(process, 'version.status', 'draft') === 'published' || get(process, 'version.status', 'draft') === 'approved';
            const read_only = viewer_roles.length === 1 && viewer_roles[0] === 'read';

            // Keep loader up until full version loaded
            if (isEmpty(process)) return (<GlobalLoading />);
            if (isEmpty(version)) return (<GlobalLoading />); 
            if (isEmpty(tests)) return (<GlobalLoading />);

            /*
            TODO need to make team member able to see older versions
            if (viewer_roles.length === 0) {
                return (
                    <ErrorPage history={this.props.history} errors={[{ 
                        status: 'Access', 
                        message: 'Ask the Project Admin for team access to this project',
                    }]} />
                )
            }
            */

            return (
                <div>
                    <Page { ...
                        {
                            ...this.props,
                            is_approved,
                            saveVersionProject: this.saveVersionProject,
                            version_reference,
                            version_size,
                            version_saved_at,
                            cache,
                            viewer_roles,
                            read_only,
                            version,
                            process,
                            tests,
                            users,
                            user,
                            selected_step_id,
                            selected_item_id,
                        }
                    } />
                    {spotlight &&
                        <Spotlight 
                            version={version}
                            history={(url) => {
                                this.setState({ spotlight: !spotlight });
                                this.props.history.push(url);
                            }}
                            close={() => {
                                this.setState({ spotlight: !spotlight });
                            }}
                            version_reference={version_reference}
                        />
                    }
                </div>
            );
        }
    }

    function mapStateToProps(state) {
        return state;
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators({
            ...actions,
        }, dispatch);
    }

    return connect(mapStateToProps, mapDispatchToProps)(Versions);
};