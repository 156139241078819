import React, { Component } from 'react';
import { find } from 'lodash';

import CommonEdit from './Edit/Common';

import Details from './Edit/Shared/Sidebar/Details';
import Inputs from './Edit/Shared/Sidebar/Inputs';
// import APISource from './Edit/Shared/Sidebar/APISource';

import { getProgressiveInput } from '../../utils/steps';
import { validate, mapSchema } from '../../utils/validate_project';

// --- Define Each Step Editor
// ---
import InputEdit from './Edit/Input/Edit';
import OutputEdit from './Edit/Output/Edit';
import TransformEdit from './Edit/Transform/Edit';
import CalculationEdit from './Edit/Calculation/Edit';
import ExcessesEdit from './Edit/Excesses/Edit';
import ExclusionsEdit from './Edit/Exclusions/Edit';
import RefersEdit from './Edit/Refers/Edit';
import LabelEdit from './Edit/Label/Edit';
import EndorsementsEdit from './Edit/Endorsements/Edit';
import LinksEdit from './Edit/Links/Edit';
import FactorsEdit from './Edit/Factors/Edit';
import ExternalEdit from './Edit/External/Edit';
import CollectionEdit from './Edit/Collection/Edit';

import { parseSource } from '../../utils/api_source';

const mapComponent = (step) => {
    const map = {
        'output': OutputEdit,
        'input': InputEdit,
        'transform': TransformEdit,
        'calculation': CalculationEdit,
        'excesses': ExcessesEdit,
        'endorsements' : EndorsementsEdit,
        'exclusion' : ExclusionsEdit,
        'refer' : RefersEdit,
        'label' : LabelEdit,
        'links' : LinksEdit,
        'collection' : CollectionEdit,
        'external': ExternalEdit,
        'factors': FactorsEdit,
        'common': CommonEdit,
    }
    return map[step] || map['common'];
}
//---
//---

class EditArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_config: false,
            show_inputs: false,
            step: {},
            inputs: {},
            errors: [],
        }
        this.updateInputValue = this.updateInputValue.bind(this);
        this.updateStep = this.updateStep.bind(this);
        this.overwriteStep = this.overwriteStep.bind(this);
        this.validate = this.validate.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.showConfig = this.showConfig.bind(this);
        this.showInputs = this.showInputs.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { version, selected_step_id } = this.props;
        let step = find(this.props.steps, s => s.id === selected_step_id);

        // Input and Output are outside steps
        if (selected_step_id === 'input') {
            step = version.input;
            return this.setState({
                step: {
                    ...step,
                    key: 'input',
                    name: 'Input',
                    step: 'input',
                    id: 'input',
                },
                inputs: parseSource(step.source),
            });
        }

        if (selected_step_id === 'output') {
            step = version.output;
            step = {
                ...step,
                key: 'output',
                name: 'Output',
                step: 'output',
                id: 'output',
            };
        }

        const inputs = getProgressiveInput({ step_key: step.key, project: version });
        this.setState({
            step,
            inputs,
        });
    }

    validate(step) {
        this.setState({ errors: [] });

        const result = validate({ 
            step,
        });

        if (!result.valid) {
            console.warn({ step, errors: result.errors });
            this.setState({ errors: result.errors });
            return false;
        }

        return true;
    }

    showInputs() {
        this.setState({
            show_inputs: !this.state.show_inputs,
        })
    }

    showConfig() {
        this.setState({
            show_config: !this.state.show_config,
        })
    }

    updateInputValue(input) {
        this.setState({
            inputs: {
                ...this.state.inputs,
                ...input,
            }
        });
    }

    updateStep(step = {}) {
        if (step.id === 'input') {
            this.setState({
                input: step,
            });
        }

        if (step.id === 'output') {
            this.setState({
                output: step,
            });
        }

        let new_step = {
            ...this.state.step,
            ...step
        };

        // Keeps factors result key in state with the step key
        if (new_step.step === 'factors') {
            if (step.key) {
                new_step = {
                    ...new_step,
                    key: step.key,
                    result: {
                        ...new_step.result,
                        key: step.key,
                        name: step.key,
                    }
                }
            }
        }

        this.validate(new_step);

        this.setState({
            step: new_step,
        });
    }

    overwriteStep(step) {
        this.setState({
            step,
        });
    }

    async updateComponent() {
        const { step } = this.state;
        const result = this.validate(step);
        if(!result) return;
        await this.props.updateComponent({
            id: step.id,
            data: step,
            input: step.id === 'input' ? step : null,
            output: step.id === 'output' ? step : null,
        });
    }

    render() {

        const { inputs, step, errors, show_config, show_inputs } = this.state;
        const { version:version_process = {} } = this.props.process;

        const {
            status = '',
        } = version_process;

        const EditComponent = mapComponent(step.step);
        const editSchema = mapSchema(step.step);

        const is_approved = (status === 'published' || status === 'approved');

        return (
            <section className="main-content slide-left">

                <div className="design-button-area">
                    <div className="design-button-area-left">
                        
                        <button className="button white big mr" onClick={async () => {
                            if(this.validate(step)) {
                                this.updateComponent();
                            } else {
                                this.props.unselectComponent();
                            }

                        }}>&lt; Go Back</button>

                        <button className="button secondary big mr" onClick={this.showConfig}>{show_config ? 'Hide' : 'Show'} JSON</button>
                        {!is_approved && step.id !== 'input' && <button className="button secondary big" onClick={this.showInputs}>{show_inputs ? 'Hide' : 'Show'} Inputs</button>}

                    </div>


                    {!is_approved &&
                        <div className="design-button-area-right">

                            {!(step.id === 'input' || step.id === 'output') && <button onClick={() => {
                                this.props.deleteComponent(step.id);
                            }} className="button invalid big mr">
                                Delete Step
                            </button>}

                            <button onClick={() => {
                                this.updateComponent();
                            }} className="button secondary big">
                                Save Step
                            </button>

                        </div>
                    }
                    
                </div>

                <div className={`edit-area`}>
                    {!is_approved && step.id !== 'input' &&
                        <div className="edit-area-left">

                            {!(step.id === 'input' || step.id === 'output') && 
                                <Details 
                                    step={step}
                                    errors={errors}
                                    updateStep={this.updateStep}
                                />
                            }

                            {step.id !== 'input' && show_inputs &&
                                <div className="edit-inputs">
                                    <Inputs 
                                        id={step.id}
                                        inputs={inputs}
                                        updateInputValue={this.updateInputValue}
                                        project={this.props.version}
                                    />
                                </div>
                            }

                        </div>
                    }

                    <div className="edit-area-center" style={{paddingRight: '0', flex: 2}}>

                        {show_config &&
                            <CommonEdit 
                                schema={editSchema}
                                key={step.id}
                                errors={errors}
                                step={step}
                                inputs={inputs}
                                updateStep={this.updateStep}
                                overwriteStep={this.overwriteStep}
                                set_error={this.props.set_error}
                                is_approved={is_approved}
                                item_id={this.props.item_id}
                            />
                        }

                        <div className="edit-area-canvas">
                            <div className="edit-form">
                                <EditComponent
                                    schema={editSchema}
                                    key={step.id}
                                    errors={errors}
                                    step={step}
                                    inputs={inputs}
                                    updateStep={this.updateStep}
                                    overwriteStep={this.overwriteStep}
                                    set_error={this.props.set_error}
                                    is_approved={is_approved}
                                    version={this.props.version}
                                    selected_item_id={this.props.selected_item_id}
                                    selected_step_id={this.props.selected_step_id}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="edit-area-right" style={{display: 'none'}}></div>
                </div>
            </section>
        );
    }
}

export default EditArea;