import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const users = new BaseActions({
    path: `${base.root}/a/users`,
    type: 'SWALLOW_USERS',
});

export default users;