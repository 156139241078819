import React, { Component } from 'react';
import projects_hoc from '../../hoc/projects';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import ProjectMiniContainer from '../../components/Project/ProjectMiniContainer';
import ProjectCreate from '../../components/Project/ProjectCreate';

import GlobalLoading from '../../../../components/GlobalLoading';

import { get, first, omit, find } from 'lodash';

const templates = [{
    id: 'bikeInsuranceTemplate',
    name: 'Bike Insurance Template',
},{
    id: 'petInsuranceTemplate',
    name: 'Pet Insurance Template',
}];

class Projects extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_create_open: false,
        }
        this.openProject = this.openProject.bind(this);
        this.createProject = this.createProject.bind(this);
        this.createVersion = this.createVersion.bind(this);
    }

    async UNSAFE_componentWillMount() {
        await this.props.swallow_projects_get_all();
    }

    openProject() {
        const { is_create_open } = this.state;
        this.setState({
            is_create_open: !is_create_open,
        })
    }

    async createVersion({ project_reference }) {
        const result = await this.props.swallow_versions_post({
            data: {
                project_reference
            }
        });
        const version_reference = get(result, 'payload.data.version_reference');
        this.props.history.push(`/version/design/${version_reference}`);
    }

    async createProject(payload) {
        const { data: projects } = this.props.swallow_projects;
        let { name = '', description, template } = payload;
        if (!description) description = name;
        if (!template) template = 'blankTemplate'
        
        let project = {};
        let tests = [];

        // TODO : this should exist in API
        // From Cloned Project
        if ((template || '').includes('project::')) {
            const project_reference = template.split('project::')[1];
            const version_reference = (first(projects.filter(p => p.project_reference === project_reference)) || {}).version_reference;
            await this.props.swallow_versions_get({ id: version_reference });
            await this.props.swallow_tests_get({ id: version_reference });
            const { selected: new_version = {} } = this.props.swallow_versions;
            const { selected: new_tests = {} } = this.props.swallow_tests;
            project = new_version;
            tests = new_tests;

            // Sending clone project
            await this.props.swallow_projects_post({ 
                data: {
                    ...omit(project, 'tests'),
                    meta: {
                        ...project.meta,
                        name,
                        description: description || name,
                    }
                }
            });

            // Sending clone tests
            if(tests.length > 0) {
                const { selected: project_new } = this.props.swallow_projects;
                await this.props.swallow_tests_post_some({
                    id: project_new.version_reference,
                    data: {
                        tests,
                    }
                });
            }
        }

        // From Set Template
        let found_template;
        if (template === 'blankTemplate') {
            found_template = template;
        } else {
            found_template = (find(templates, t => t.id === template) || {}).id
        }

        if (found_template) {

            await this.props.swallow_projects_post({ 
                data: {
                    name,
                    description,
                    template: found_template,
                    project: {},
                }
            });
        }

        await this.props.swallow_projects_get_all();

        this.setState({ is_create_open: false })
    }

    render() {
        let { data = [], isFetching } = this.props.swallow_projects;
        const { projects_count, account_type } = this.props;
        
        const reached_limit = projects_count <= data.length
        if (reached_limit) data = data.slice(0, projects_count);
        const is_create_open = (this.state.is_create_open || data.length === 0) && !isFetching;

        return (
            <div className="swallow_app">
                <TopNav history={this.props.history} account_type={account_type} auth={this.props.auth} />

                {isFetching && <GlobalLoading /> }

                <section className="main-content">

                    {/*account_type === 'free' &&
                        <div style={{ marginBottom: 20 }}>
                            <HelpBlock 
                                text={'Free account is limited to 3 projects in total.'}
                            />
                        </div>
                    */}

                    { !is_create_open && !reached_limit  &&
                        <div className="main-content-nav">
                            <div className="main-content-nav-left"></div>
                            <div className="main-content-nav-right">
                                {!isFetching && !reached_limit &&
                                    <button onClick={this.openProject} className="button big main">
                                        <i className="fa fa-passport"></i> Create New Project
                                    </button>
                                }           
                            </div>
                        </div>
                    }

                    {/* TODO - If no projects then show the project create box */}

                    { is_create_open && <ProjectCreate 
                        projects={data}
                        templates={templates}
                        openProject={this.openProject} 
                        createProject={this.createProject}
                    /> }
                    
                    <div style={{ opacity: is_create_open ? 0.5 : 1 }}>
                        {!isFetching && data.length > 0 && 
                            data.map(d => {
                                return(<ProjectMiniContainer 
                                    key={d.project_reference} 
                                    history={this.props.history} 
                                    project={d} 
                                    createVersion={this.createVersion}
                                />)
                            })
                        }

                    </div>

                </section>

                {!isFetching && <Footer history={this.props.history} />}
            </div>
        );
    }
}

export default projects_hoc(Projects);