import React, { Component } from 'react';
import { getId } from '../../../../utils/url';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import DataDetail from '../../components/Data/DataDetail';
import GlobalLoading from '../../../../components/GlobalLoading';

import { get } from 'lodash';

class VersionDataDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        await this.props.swallow_quotes_get({ id, debug : true });
        const version_reference = get(this.props, 'swallow_quotes.selected.meta.version_reference', null)
        await this.props.swallow_process_get({ id : version_reference });
    }

    render() {

        const {
            selected: process = {},
            isFetching: isFetchingProcess,
        } = this.props.swallow_process;

        const {
            selected: quote = {},
            isFetching : isFetchingQuotes,
        } = this.props.swallow_quotes;

        const {
            version = {},
        } = process;

        const { account_type, read_only } = this.props;

        return (
            <div class="swallow_app data-area">

                <TopNav history={this.props.history} version={version} auth={this.props.auth} account_type={account_type} read_only={read_only} />
                
                {(isFetchingQuotes || isFetchingProcess) && <GlobalLoading /> }

                {!(isFetchingQuotes || isFetchingProcess) &&
                    <DataDetail 
                        data={quote}
                        version={version}
                        history={this.props.history}
                        auth={this.props.auth}
                    />
                }
                <Footer history={this.props.history}/>
            </div>
            
        );
    }
}

export default VersionDataDetail;