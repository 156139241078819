import React, { Component } from 'react';

class GlobalLoading extends Component {
    constructor (props) {
        super(props);
        this.state = {
            close: false,
        }
        this.close = this.close.bind(this);
    }

    close() {
        this.setState({
            close: true,
        });
    }

    render() {
        const { close } = this.state;
        return (
            <div className="global-success" style={{ display: close ? 'none' : 'block'}}>
                <i className='fa fa-circle-check'></i>
            </div>
        )
    }
}

export default GlobalLoading;