import BaseActions from './base';
import config from '../config';

const base = config;

const auth = new BaseActions({
    path: `${base.root}/a/users`,
    type: 'AUTH',
});

export default auth;