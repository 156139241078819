import React, { Component } from 'react';
import { each, isString } from 'lodash';
import BooleanSelect from '../Forms/BooleanSelect';
import { evaluateLists } from '@swa_llow/pricing_engine';

class Inputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: this.props.inputs,
        }
        this.updateInputValue = this.updateInputValue.bind(this);
        this.filter = this.filter.bind(this);
    }

    updateInputValue ({
        key, 
        value,
        type,
    }) {
        this.props.updateInputValue({
            [key] : {
                value,
                type,
            } 
        });
    }
    
    filter() {
        const { filter_value = '', filter_key = '' } = this.state;
        let items = [];

        each(this.props.inputs, (v, k) => {
            items.push({
                key: k,
                value: v.value,
                type: v.type,
            });
        });

        if (filter_key !== '') {
            items = items.filter(item => item.key.includes(filter_key));
        }

        if (filter_value !== '') {
            items = items.filter(item => {
                let { value: new_value } = item;
                if (!isString(new_value)) new_value = new_value.toString();
                return new_value.includes(filter_value)
            });
        }

        return items;
    }

    render() {
        let { id, lists = {}, is_output = false, is_disabled = false, project } = this.props;
        if (project) lists = evaluateLists(project) || {};

        const inputs = this.filter();
        if(is_output) is_disabled = true;

        return (
            <div className={`input-wrapper ${is_disabled ? '' : 'editable'}`}>
               
                <div className="input_row header">
                    <div className="input_key">
                        {is_output ? 'Output' : 'Input'}
                        <input 
                            type="text" 
                            placeholder="Filter Input"
                            onChange={(e) => {
                                const { value = '' } = e.target;
                                this.setState({
                                    filter_key: value,
                                });
                            }}
                        />
                    </div>
                    <div className="input_value">
                        Value
                        <input 
                            type="text" 
                            placeholder="Filter Value"
                            onChange={(e) => {
                                const { value = '' } = e.target;
                                this.setState({
                                    filter_value: value,
                                });
                            }}
                        />
                    </div>
                </div>

                {inputs.map(input => {
                    if (input.type === 'boolean') {
                        return (
                            <div key={`${id}-${input.key}`} className="input_row">
                                <div className="input_key">{input.key}</div>
                                <div className="input_value">
                                    <BooleanSelect
                                        value={input.value}
                                        disabled={is_disabled}
                                        update={({ value }) => {
                                            this.updateInputValue({
                                                type: input.type,
                                                key: input.key,
                                                value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }

                    if (input.type === 'date') {
                        return (
                            <div key={`${id}-${input.key}`} className="input_row">
                                <div className="input_key">{input.key}</div>
                                <div className="input_value">
                                    <input 
                                        type={'date'} 
                                        disabled={is_disabled}
                                        value={input.value} 
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.updateInputValue({
                                                type: input.type,
                                                key: input.key,
                                                value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }

                    if (input.type === 'string' && lists[input.key]) {
                        const { data } = lists[input.key] || {};
                        return (
                            <div key={`${id}-${input.key}`} className="input_row">
                                <div className="input_key">{input.key}</div>
                                <div className="input_value">
                                    <select
                                        disabled={is_disabled}
                                        value={input.value} 
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.updateInputValue({
                                                type: input.type,
                                                key: input.key,
                                                value,
                                            })
                                        }}
                                    >
                                        {data.map(d => (<option value={d}>{d}</option>))}
                                    </select>
                                </div>
                            </div>
                        );
                    }

                    if (input.type === 'string') {
                        return (
                            <div key={`${id}-${input.key}`} className="input_row">
                                <div className="input_key">{input.key}</div>
                                <div className="input_value">
                                    <input 
                                        type={'text'} 
                                        disabled={is_disabled}
                                        value={input.value} 
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.updateInputValue({
                                                type: input.type,
                                                key: input.key,
                                                value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }

                    return (
                        <div key={`${id}-${input.key}`} className="input_row">
                            <div className="input_key">{input.key}</div>
                            <div className="input_value">
                                <input 
                                    type={'number'} 
                                    disabled={is_disabled}
                                    value={input.value} 
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (value !== '')value = Number(value);
                                        this.updateInputValue({
                                            type: input.type,
                                            key: input.key,
                                            value,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    )
                })
            }
            </div>
        );
    }
}

export default Inputs;