import React, { Component } from 'react';
import { omit } from 'lodash';

class GlobalErrors extends Component {
    constructor (props) {
        super(props);
        this.state = {
            close: true,
        }
        this.close = this.close.bind(this);
        this.copy = this.copy.bind(this);
    }

    componentDidMount() {}

    close() {
        const { close } = this.state;
        this.setState({
            close: !close,
        });
    }

    copy() {
        const { global_errors, auth = {} } =this.props;
        const { selected } = auth;
        const page = window.location.href;
        const created_at = new Date().toISOString();
        const report = [{
            page,
            created_at,
            user: omit(selected, ['projects']),
        }, ...global_errors];

        navigator.clipboard.writeText(JSON.stringify(report, null, 4))
    }

    render() {
        const { close } = this.state;
        const { global_errors = [] } = this.props;
        return [
            <div>
                <div className="global-success global-error" >
                    <span className="global-error-count" onClick={this.close}>{global_errors.length}</span>
                </div>
                <div className="global-error-window" style={{ display: close ? 'none' : 'block'}}>
                    <button className="button small invalid" onClick={this.copy}>Copy</button>
                    <ul>
                        {global_errors.map(e => {
                            return (<li>{ e.message }</li>)
                        })}
                    </ul>
                </div>
            </div>
        ];
    }
}

export default GlobalErrors;