import React, { Component } from 'react';

class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
        }
        this.redirect = this.redirect.bind(this);
    }

    redirect() {
        this.props.history.push('/');
    }

    componentDidMount() {}

    render() {        
        return (
            <div id="success" className="wf">
                <h1>Success!</h1>
                <h2>&nbsp;</h2>
                <p>You can click on the button below to login.</p>
                <button style={{ marginTop: 40 }} className="primary-button alt" onClick={(e) => {
                    e.preventDefault();
                    this.redirect();
                }}>
                    Take Me There!
                </button>
            </div> 
        );
    }
}
export default Success;