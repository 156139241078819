import React from 'react';
import { first, last } from 'lodash';

function colour(name = '') {
    const size = name.length % 12;
    const map = {
        1: '#ffb3ba',
        2: '#ffdfba',
        3: '#ffffba',
        4: '#baffc9',
        5: '#bae1ff',
        6: '#CCF1FF',
        7: '#E0D7FF',
        8: '#FFCCE1',
        9: '#FAFFC7',
        10: '#D7EEFF',
        11: '#ffdfba',
        12: '#baffc9',
    }
    return map[size] || '#ffb3ba';
}

function initials(name = '') {
    const fn = first(name.split(' ')) || '';
    const ln = last(name.split(' ')) || '';
    return fn.charAt(0).toUpperCase() + ln.charAt(0).toUpperCase();
}

function Avatar(props) {
    const { name, lite = false, onClick = (() => {}), inverse = false } = props;
    if (lite) {
        return (
            <span className="avatar-icon" style={{
                background: colour(name), 
                color: 'black',
                marginTop: '4px',
                marginLeft: '0px'
            }}>{initials(name)}</span>
        );
    }

    return (
        <span className={`avatar-icon-container ${inverse ? 'inverse' : ''}`} onClick={onClick}>
            <span className="avatar-icon" style={{background: '#fff', color: 'black' }}>{initials(name)}</span>
            <span className="avatar-text">{name}</span>
        </span>
    );
}

export default Avatar;