import React, { Component } from 'react';

import Select from './Elements/Select';
import Input from './Elements/Input';

class PublicFormElements extends Component {
    render() {
        const{
            form_key: key,
            type,
            label,
            placeholder,
            value,
            errors,
            lists = {},
        } = this.props;

        const list = lists[key];

        if(type === 'string' && !list) {
            return (
                <Input 
                    type={'text'}
                    label={label}
                    errors={errors}
                    update={this.props.update} 
                    value={value}
                    placeholder={placeholder}
                />
            )
        }

        if (type === 'string' && list) {
            return (
                <div className="select-container">
                    <Select
                        type={'search'}
                        options={(list.data || []).map(l => {
                            return { name: l, value: l };
                        })} 
                        label={label}
                        errors={errors}
                        update={this.props.update}
                        value={value}
                    />
                    <i className="fa fa-caret-down"></i>
                </div>
            )
        }

        if (type === 'integer' || type === 'decimal') {
            return (
                <Input 
                    type="number"
                    label={label}
                    errors={errors}
                    update={this.props.update} 
                    value={value}
                    placeholder={placeholder}
                />
            )
        }

        if (type === 'date') {
            return (
                <Input 
                    type="date"
                    label={label}
                    errors={errors}
                    update={this.props.update} 
                    value={value}
                />
            )
        }

        if (type === 'boolean') {
            return (
                <div className="select-container">
                    <Select
                        type={'boolean'}
                        label={label}
                        errors={errors}
                        update={this.props.update}
                        value={value}
                    />
                    <i className="fa fa-caret-down"></i>
                </div>
            )
        }

        return <div></div>
    }
}

export default PublicFormElements;