import React, { Component } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import { getId } from '../../../utils/url';

import Login from '../../../auth/Login';

class LoginContainer extends Component {
    render() {
        const id = getId(this.props.history);
        return (
            <div>
                <Header show_nav={false} />
                <Login history={this.props.history} pool_reference={id} />
                <Footer />
            </div>
        );
    }
}

export default LoginContainer;