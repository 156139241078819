/* eslint no-self-assign:0 */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import React from 'react';
import Table from '../../../../Layout/Table';

import moment from 'moment';
import { isBoolean, isString } from 'lodash';

class DragList extends Table {
    constructor(props) {
        super(props);
        this.deleteItems = this.deleteItems.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.moveItem = this.moveItem.bind(this);
    }

    deleteItems ([ key ]) {
        let { updateComponent, items } = this.props;
        const new_items = items.filter(t => key !== t.key);
        updateComponent({
            items: new_items,
        });
    }

    moveItem (dnd_result = {}) {
        const { updateComponent, items } = this.props;
        const startIndex = dnd_result.source.index;
        const endIndex = dnd_result.destination.index;

        const reorder = () => {
            const result = Array.from(items);
            const [ removed ] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };

        updateComponent({
            items: reorder(),
        });
    }

    renderRow(data) {
        let {
            def,
            key,
            type,
            label,
            static: format_static,
            index: format_index,
        } = data;

        const { is_approved = false } = this.props;

        if (isBoolean(def)) {
            def = def.toString().toUpperCase();
        } else if (def === null) {
            def = '';
        } else if (!isString(def)) {
            def = def.toString();
        } else if (!isNaN(Number(def))) {
            def = def.toString();
        } else if (moment(def, 'YYYY-MM-DD').isValid()) {
            def = moment(def).format('YYYY-MM-YY');
        } else {}

        return (
            <div className="drag-row">
                <div className="drag-row-cell">{ label }<br/><span>{ key }</span></div>
                <div className="drag-row-cell">{ type }</div>
                <div className="drag-row-cell">{format_static && <i className='fa fa-circle-check'></i>}</div>
                <div className="drag-row-cell">{format_index && <i className='fa fa-circle-check'></i>}</div>
                <div className="drag-row-cell">{ def }</div>
                <div className="drag-row-cell">
                    {!is_approved && <button className="button small invalid mr" onClick={() => {
                        this.deleteItems([ key ]);
                    }}>
                        <i className="fa fa-trash"></i> Delete
                    </button>}

                    <button className="button small secondary" onClick={() => {
                        this.props.selectItem(data);
                    }}>
                        { is_approved ? 'View' : 'Edit' }
                    </button>
                </div>
            </div>
        )
    }

    render() {
        const { headers = [], items = [] } = this.props;
        return(
            <div className="drag-list" style={{ minHeight: (54 * items.length) + 45 }}>
                <div className="drag-row">
                    {headers.map((h = '') => {
                        return (<div className="drag-row-header">{ h.toUpperCase() }</div>)
                    })}
                </div>
                <DragDropContext onDragEnd={this.moveItem}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {items.map((item, i) => {
                                    return (
                                        <Draggable key={item.key || item.id} draggableId={item.key || item.id} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {this.renderRow(item)}
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }
}

export default DragList;