import React, { Component } from 'react';

import ExpressionCommands from '../Expression/ExpressionCommands';

import Expression from '../Forms/Expression';

class ExpressionBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            syntax: false,
        }
    }
    render() {
        const { 
            inputs,
            is_approved,
            version,
            exp,
            tag = 'Exclusions',
            errors,
            disabled = false
        } = this.props;

        const { syntax = false } = this.state;

        return (
            <div style={{ position: 'relative'}}>

                {syntax === false &&
                    <fieldset className={`${errors && errors.message ? 'error' : ''} ${disabled ? 'disabled' : ''} spacing`}>
                        <label style={{ margin: 10 }}>Expression</label>
                        <ExpressionCommands
                            version={version}
                            inputs={inputs}
                            exp={exp}
                            is_approved={is_approved}
                            updateExpression={(value) => {
                                this.props.updateItem({
                                    exp: value,
                                    def: false,
                                });
                            }}
                        />
                        {errors && errors.message && <p className='error-message'>{errors.message}</p>}
                    </fieldset>
                }

                {syntax === true &&
                    <Expression 
                        label={`${tag} Expression`}
                        disabled={disabled}
                        inputs={inputs} 
                        value={exp}
                        errors={errors}
                        updateStep={(value) => {
                            this.props.updateItem({
                                exp: value,
                                def: false,
                            });
                        }}
                    />
                }

                <button className="button main small" style={{ position: 'absolute', right: 14, top: 16,  zIndex: 1000 }}
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({ syntax: !syntax });
                    }}
                >{syntax ? 'Form' : 'Advanced'}</button>

            </div>
        );
    }
}

export default ExpressionBuilder;