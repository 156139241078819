import React, { Component } from 'react';
import ActualResult from '../Test/ActualResult';

import Inputs from '../Design/Edit/Shared/Sidebar/Inputs';

import dataTypeSelector from '../../utils/data_type_selector'; 

import { each, omit } from 'lodash';
import moment from 'moment';

import config from '../../../../config/index';

function formatInputs (inputs) {
    let new_inputs = {};
    each(inputs|| {}, (input, k) => {
        const type = dataTypeSelector(input);
        new_inputs = {
            ...new_inputs,
            [k]: {
                type,
                value: input,
            }
        }
    });

    new_inputs = omit(new_inputs, 'quote_reference');
    return new_inputs;
}

class DataDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_api: false,
        }
    }
    
    render() {

        const {
            data = {},
            history,
            auth = {},
        } = this.props;

        const { open_api } = this.state;

        const {
            input:inputs,
            created_at,
            quote_reference,
            meta,
        } = data;

        const {
            version_reference = '',
            project_reference,
        } = meta || {};

        let new_inputs = formatInputs(inputs);

        const url = `${config.root}/sw/quotes/${quote_reference}?debug=true`;
        const { client_id_key, client_secret_key } = auth.selected || {};

        return (
            <section className="main-content slide-left">
                <div class="design-button-area">
                    <div class="design-button-area-left">
                        <button class="button white small" onClick={() => history.goBack()} >&lt; Go Back</button>
                    </div>
                    <div class="design-button-area-center"></div>
                    <div class="design-button-area-right">
                        <button class="button main small" onClick={() => this.setState({ open_api: !open_api})}><i className="fa fa-database"></i> Data API</button>
                    </div>
                </div>

                {open_api && 
                    <div>
                        <div className="code-block" style={{ height: 120 }}>
                            <label>Data API</label>
                            <textarea style={{ height: 50, minHeight: 'auto' }} value={`curl --request GET --url '${url}' --header 'content-type: application/json' --header 'client_secret_key: ${client_secret_key}' --header 'client_id_key: ${client_id_key}'`} />
                        </div>
                    </div>
                }

                <div className="data-area">
                    <div className="data-detail-area-left">

                        <div className="review-side-section">
                            <h4>Quote Reference</h4>
                            <button className="button small white">
                                <i class="fa-solid fa-copy"></i>
                                {quote_reference}
                            </button>
                        </div>

                        <div className="review-side-section">
                            <h4>Created</h4>
                            <button className="button white small no-hover mr">
                                {moment(created_at).format('lll')}
                            </button>
                            <button className="button date small no-hover">
                                <i className='fa fa-clock'></i>
                                {moment(created_at).fromNow()}
                            </button>
                        </div>

                        <div className="review-side-section">

                            <h4>Version Reference</h4>

                            <button className="button small white no-hover">
                                <i class="fa-solid fa-copy"></i>
                                {version_reference}
                            </button>

                        </div>

                        <div className="review-side-section">

                            <h4>Project Reference</h4>

                            <button className="button small white">
                                <i class="fa-solid fa-copy"></i>
                                {project_reference}
                            </button>

                        </div>

                    </div>
                    <div className="data-detail-area-center" style={{ margin: '0 20px 0 0'}}>

                        <div className="edit-area">
                            <div className="edit-inputs">
                                <Inputs
                                    inputs={new_inputs}
                                    is_disabled={true}
                                />
                            </div>
                            <div className="standalone-log">
                                <div className="show-logs-area">
                                    <pre>
                                        <code>
                                            {JSON.stringify(data, null, 4)}
                                        </code>
                                    </pre>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="data-detail-area-right">

                        <div className="edit-inputs"> 
                            <ActualResult
                                page={'data'}
                                quote={{
                                    ...data,
                                    result: data.output
                                }}
                                hide_logs={true}
                            /> 
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default DataDetail;