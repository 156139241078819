import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const actions = new BaseActions({
    path: `${base.root}/sw/process`,
    type: 'SWALLOW_PROCESS',
});

export default actions;