import React, { Component } from 'react';
import RoleTable from './RoleTable';
import { find } from 'lodash';

class TeamRoleList extends Component {
    render() {
        const {
            project = {},
            selected_user = {},
            updateUser,
            updateAdmin,
        } = this.props;

        const { 
            projects : user_projects,
            is_admin,
            user_reference,
            email,
        } = selected_user;

        const matched_project = find(user_projects, up => up.project_reference === project.project_reference);
        const active_project = is_admin || (matched_project && (matched_project.is_read || matched_project.is_write || matched_project.is_approve || matched_project.is_publish))

        return (
            <div key={project.project_reference} className={`project-role ${active_project ? '' : 'not-active'}`}>
                <div className="project-role-header">
                    <div className="project-role-header-left">
                        <h4>{project.name}</h4>
                        <p>{project.description}</p>
                    </div>
                    <div className="project-role-header-right"></div>
                </div>
                <RoleTable 
                    updateUser={updateUser}
                    updateAdmin={updateAdmin}
                    items={[{
                        ...matched_project,
                        project_reference: project.project_reference,
                        is_admin,
                        user_reference,
                        email,
                    }]}
                />
            </div>
        )
    }

}

export default TeamRoleList;