import React, { Component } from 'react';

import {
    Link,
} from 'react-router-dom';

import { extendedDateFormat } from '../../../utils/format';

class PoolCard extends Component {
    render() {
        const { data = {} } = this.props;
        const {
            created_at = '',
            is_admin = false,
            pool_name = '',
            pool_reference = '',
        } = data;
        return (
            <div className={`wf ${is_admin ? `wf-bg`: `wf-br`}`}>
                <h4>{is_admin ? 'Admin Pool' : pool_name}</h4>
                <p>Pool Reference : {pool_reference}</p>
                <p>Created At : {extendedDateFormat(created_at)}</p>
                <Link to={`/pools/${pool_reference}`}><button>View</button></Link>
            </div>
        );
    }
}

export default PoolCard;
