import React, { Component } from 'react';
import versions_hoc from '../../hoc/versions';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import { getId } from '../../../../utils/url';

import moment from 'moment';
import { omit, isUndefined, find } from 'lodash';

import Tasks from '../../components/Review/Tasks';
import TeamTable from '../../components/Review/TeamTable';
import Comments from '../../components/Review/Comments';
import Block from '../../components/Review/Block';
import Assets from '../../components/Review/Assets';
import ControlsBox from '../../components/Layout/ControlsBox';
import ApprovedBlock from '../../components/Layout/ApprovedBlock';

import { Link } from 'react-router-dom';

import axios from 'axios';
import config from '../../../../config';
import GlobalLoading from '../../../../components/GlobalLoading';

class Version extends Component {
    constructor(props){
        super(props);

        this.state = {
            edit_mode: false,
            viewer_roles: this.props.viewer_roles,
            process: this.props.process,
            users: this.props.users,
        };

        this.save_process = this.save_process.bind(this);
        this.edit_mode = this.edit_mode.bind(this);
        this.put_signed_url_s3 = this.put_signed_url_s3.bind(this);
        this.get_signed_url_s3 = this.get_signed_url_s3.bind(this);
        this.post_action = this.post_action.bind(this);
        this.put_action = this.put_action.bind(this);
        this.delete_action = this.delete_action.bind(this);
        this.publish_version = this.publish_version.bind(this);
        this.approve_version = this.approve_version.bind(this);
        this.delete_version = this.delete_version.bind(this);
        this.refreshProcess = this.refreshProcess.bind(this);
        this.mappedTeam = this.mappedTeam.bind(this);
    }

    async UNSAFE_componentWillMount() {
        const id = getId(this.props.history);
        await this.props.swallow_actions_get_all({ version_reference: id });

        //Set to edit more if in URL
        const edit_mode_url = new URLSearchParams(window.location.search).get('edit_mode');
        const edit_mode_write = this.props.viewer_roles.includes('write');
        if (edit_mode_write || edit_mode_url) this.setState({ edit_mode: true });
    }

    // For Assets upload
    async put_signed_url_s3 ({ data, name, type = 'application/octet-stream; charset=binary', size }) {
        const { token } = this.props.token;
        const { selected } = this.props.swallow_process;
        const { version, assets } = selected;
        const { version_reference } = version;
        const { signed_url } = await axios({
            url: `${config.root}/sw/process/assets/${version_reference}?file=${name}&type=${type}`,
            method: 'put',
            headers: {
                authorization: `Bearer ${token}`,
            },
        }).then(({data}) => data);
    
        await axios({
            url: signed_url,
            method: 'put',
            header: {
                'Content-Type': type,
            },
            data,
        }).then(({data}) => data);

        this.save_process({
            assets: [{
                file: name,
                type,
                size,
                date: new Date().toISOString(),
            }, ...assets]
        })
    }

    // For assets download
    async get_signed_url_s3 ({ name, type }) {
        const { token } = this.props.token;
        const { selected } = this.props.swallow_process;
        const { version } = selected;
        const { version_reference } = version;

        const { signed_url } = await axios({
            url: `${config.root}/sw/process/assets/${version_reference}?file=${name}&type=${type}`,
            method: 'get',
            headers: {
                authorization: `Bearer ${token}`,
            },
        }).then(({data}) => data);
    
       return signed_url;
    }

    async refreshProcess({ version_reference}) {
        
        await this.props.swallow_versions_get({ id: version_reference });
        await this.props.swallow_process_get({ id: version_reference });
        const { process = {}} = this.state;
        const { selected: updated_version  } = this.props.swallow_versions;
        this.setState({
            process: {
                ...process,
                version: updated_version.meta,
            }
        });
    }

    async approve_version () {
        const { process = {}} = this.state;
        const { version = {}} = process;
        const { version_reference, project_reference } = version;

        await this.props.swallow_actions_post({
            data: {
                content: '',
                version_reference,
                project_reference,
                type: 'completed',
            }
        });

        await this.props.swallow_versions_put({
            id: version_reference,
            data: {
                status: 'approved',
            }
        });

        await this.refreshProcess({ version_reference });
    }

    async publish_version () {
        const { process = {}} = this.state;
        const { version = {}} = process;
        const { version_reference, project_reference } = version;

        await this.props.swallow_actions_post({
            data: {
                content: '',
                version_reference,
                project_reference,
                type: 'published',
            }
        });

        await this.props.swallow_versions_put({
            id: version_reference,
            data: {
                status: 'published',
            }
        });

        await this.refreshProcess({ version_reference });
    }

    async delete_version () {
        const { selected } = this.props.swallow_process;
        const { version } = selected;
        const { version_reference, project_reference } = version;

        await this.props.swallow_versions_delete({
            id: version_reference,
        });

        this.props.history.push(`/project/${project_reference}`)
    }

    async post_action({ type, content, reply_to = null }) {
        const { selected } = this.props.swallow_process;
        const { version } = selected;
        const { version_reference, project_reference } = version;
        if (content === '' && type === 'comment') return;
        if (type === 'approved') return;
        await this.props.swallow_actions_post({
            data: {
                content,
                version_reference,
                project_reference,
                type,
                mentions: [],
                reply_to,
            }
        })
    }

    async put_action({ action_reference, content, type}) {
        await this.props.swallow_actions_put({
            id: action_reference,
            data: {
                content,
                type,
            }
        })
    }

    async delete_action({ action_reference }) {
        await this.props.swallow_actions_delete({
            id: action_reference,
        })
    }

    async save_process (payload, action) {
        const { selected = {}} = this.props.swallow_process;
        const { version = {}} = selected
        const { version_reference } = version;

        if (action) {
            this.post_action(action)
        }

        const data = {
            blocks: [],
            summary: {},
            team: [],
            assets: [],
            tasks: [],
            rules: {},
            ...omit(selected, 'version'),
            ...payload,
        };

        await this.props.swallow_process_post_some({
            id: version_reference,
            data,
        });

        this.setState({
            process: {
                ...data,
                version,
            },
        })
    }

    edit_mode() {
        this.setState({
            edit_mode: !this.state.edit_mode,
        })
    }

    mappedTeam() {
        const { selected:process = {} } = this.props.swallow_process;
        const { team = [] } = process;
        const { data:users = [] } = this.props.swallow_users;
        
        const mapped_team = team.map(t => {
            const user = find(users, u => u.user_reference === t.user_reference) || {};
            return {
                ...t,
                is_admin: isUndefined(user.is_admin) ? false : user.is_admin,
            }
        });

        return mapped_team;
    }

    render() {
        const { isFetching: isFetchingProcess = true } = this.props.swallow_process;
        const { data:actions = [], isFetching: isFetchingActions = true } = this.props.swallow_actions;
        const { selected: user = {}} = this.props.auth;

        const { 
            process = {},
            viewer_roles = [],
            edit_mode,
        } = this.state;

        const {
            account_config = {},
            is_approved,
            read_only,
            account_type,
        } = this.props;

        const {
            // process_logs,
            process_assets,
            process_tasks,
            process_blocks,
            process_comments,
        } = account_config

        const { 
            version = {},
            assets = [],
            tasks = [],
            blocks = [],
            summary = {},
        } = process;

        const team = this.mappedTeam();

        const { 
            version_reference,
            project_reference,
            status,
            created_at,
            approved_at,
            published_at,
        } = version;

        const {
            content:summary_content = '',
        } = summary;

        const isFetching = (isFetchingProcess || isFetchingActions);

        return (
            <div className="swallow_app slide-left">
                <TopNav history={this.props.history} version={version} auth={this.props.auth} account_type={account_type} read_only={read_only} />

                {(isFetching) && <GlobalLoading /> }

                {is_approved && <ApprovedBlock project_reference={project_reference} /> }

                {!(isFetching) &&
                    <section className={`main-content ${edit_mode ? 'edit-mode' : ''}`}>

                        <div className="design-area" style={{marginTop : 80 }}>
                            <div className="design-area-left">
                                {status !== 'published' && !read_only &&
                                    <div className="review-side-section">
                                        <div style={{ marginRight: 20 }}>
                                            <ControlsBox 
                                                process={process}
                                                account_type={account_type}
                                                viewer_roles={viewer_roles}
                                                approve_version={this.approve_version}
                                                delete_version={this.delete_version}
                                                publish_version={this.publish_version}
                                                page={'process'}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="review-side-section">
                                    <h4>Created</h4>
                                    <button className="button white small no-hover mr">
                                        {moment(created_at).format('lll')}
                                    </button>
                                    <button className="button white small no-hover">
                                        <i className='fa fa-clock'></i>
                                        {moment(created_at).fromNow()}
                                    </button>
                                </div>

                                {approved_at &&
                                    <div className="review-side-section">
                                        <h4>Approved</h4>
                                        <button className="button approved small no-hover mr">
                                            {moment(approved_at).format('lll')}
                                        </button>
                                        <button className="button approved small no-hover">
                                            <i className='fa fa-clock'></i>
                                            {moment(approved_at).fromNow()}
                                        </button>
                                    </div>
                                }

                                {published_at &&
                                    <div className="review-side-section">
                                        <h4>Published</h4>
                                        <button className="button published small no-hover mr">
                                            {moment(published_at).format('lll')}
                                        </button>
                                        <button className="button published small no-hover">
                                            <i className='fa fa-clock'></i>
                                            {moment(published_at).fromNow()}
                                        </button>
                                    </div>
                                }

                            </div>
                            <div className="design-area-center">

                                <div className="review-area-line"></div>

                                <div className="project with-label" style={{ marginTop: 0, marginBottom: 0 }}>
                                    <div className="project-label">
                                        Change Log
                                    </div>

                                    {/*<HelpBlock 
                                        text={'This is the change log summary. This should document the changes made to this version. Keep this short and to the point.'}
                                    />*/}

                                    <Block 
                                        html={summary_content}
                                        save_process={this.save_process}
                                        edit_mode={edit_mode}
                                        is_summary={true}
                                        status={status}
                                    />

                                    {/*process_blocks && edit_mode && status === 'draft' &&
                                        <div className="add-review-block button big main" onClick={() => {
                                            this.save_process({
                                                blocks: [{
                                                    title: 'New Title',
                                                    content: '<p>New Block</p>',
                                                }, ...blocks]
                                            })
                                        }}>
                                            <i className="fa fa-circle-plus"></i> Add Block
                                        </div>
                                    */}
                                </div>

                                {process_blocks && blocks.map((b, i) => {
                                    const { title, content } = b;
                                    return (
                                        <div key={`block-${i}`} className="project with-label">
                                            <div className="project-label">
                                                {title}
                                            </div>

                                            {/*<HelpBlock 
                                                text={'This is an optional block of content. It is not in the change log. It can either be used to provide better context or to give extra instruction.'}
                                            />*/}

                                            <Block 
                                                title={title}
                                                html={content}
                                                save_process={this.save_process}
                                                edit_mode={edit_mode}
                                                is_summary={false}
                                                index={i}
                                                blocks={blocks}
                                                status={status}
                                            />
                                        </div>
                                    )
                                })}

                                <div className="project tasks with-label">
                                    <div className="project-label">
                                        Team
                                    </div>

                                    {edit_mode && status === 'draft' &&
                                        <div className="project-buttons">
                                            <Link to={`/version/team/${version_reference}`} className="button small main">Edit Team</Link>
                                        </div>
                                    }

                                    {/*<HelpBlock 
                                        text={'Assign team members for this version change. Assign the roles which grants access to read, write, download, approve or publish.'}
                                    />*/}

                                    <div className="review-block">
                                        <TeamTable 
                                            items={team}
                                            user={user}
                                            save_process={this.save_process}
                                            status={status}
                                            history={this.props.history}
                                            version_reference={version_reference}
                                        />
                                    </div>
                                </div>

                                {process_assets &&
                                    <div className="project assets with-label">
                                        <div className="project-label">
                                            Assets
                                        </div>

                                        {/*<HelpBlock 
                                            text={'Upload assets associated with the version change. Data, models, documents, presentations etc. There is no size limit.'}
                                        />*/}

                                        <Assets 
                                            items={assets}
                                            put_signed_url_s3={this.put_signed_url_s3}
                                            get_signed_url_s3={this.get_signed_url_s3}
                                            save_process={this.save_process}
                                            edit_mode={edit_mode}
                                            status={status}
                                        />
                                    </div>
                                }

                                {process_tasks &&
                                    <div className="project tasks with-label">
                                        <div className="project-label">
                                            Tasks
                                        </div>

                                        {/*<HelpBlock 
                                            text={'A checklist of tasks that need to be completed for this version change. They can be optional or mandatory.'}
                                        />*/}

                                        <Tasks
                                            items={tasks}
                                            user={user}
                                            edit_mode={edit_mode}
                                            save_process={this.save_process}
                                            status={status}
                                            team={team}
                                        />
                                    </div>
                                }

                                {!isFetching && process_comments &&
                                    <div className="project with-label comments">
                                        <div className="project-label">
                                            Comments
                                        </div>
                                        <Comments 
                                            comments={actions}
                                            team={team}
                                            user={user}
                                            post_action={this.post_action}
                                            put_action={this.put_action}
                                            delete_action={this.delete_action}
                                            status={status}
                                        />

                                    </div>
                                }

                            </div>
                        </div>
                    </section>
                }

                <Footer history={this.props.history} />

            </div>
        );
    }
}

export default versions_hoc(Version);