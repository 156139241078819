import React, { Component } from 'react';
import { find } from 'lodash';

import slugify from '../../../../../utils/slugify';
import Input from '../Forms/Input';

class EditDetails extends Component {
    render() {
        const {
            step,
            errors = [],
            updateStep,
        } = this.props;

        const {
            name,
            key,
        } = step;

        const is_disabled = (key === 'input' || key === 'output');

        return (
            <div>
                <div className="edit-inputs">
                    <Input 
                        label={'Step Name'}
                        disabled={is_disabled}
                        value={name}
                        errors={find(errors, e => e.path === 'name')}
                        updateStep={(value) => {
                            updateStep({
                                name: value,
                            })
                        }}
                    />
                </div>

                {step.step !== 'links' &&
                    <div className="edit-inputs">
                        <Input 
                            label={'Step Output Key'}
                            disabled={is_disabled}
                            value={key}
                            errors={find(errors, e => e.path === 'key')}
                            updateStep={(value) => {
                                updateStep({
                                    key: slugify(value),
                                })
                            }}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default EditDetails;