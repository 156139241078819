import React, { Component } from 'react';

import { 
    WFInput,
} from '../../../components/wireframes/modules';

import Header from '../components/Header';
import Footer from '../components/Footer';
import UsersTable from '../components/UsersTable';

class Users extends Component {
    async componentDidMount() {
        await this.props.allow_users_get_all();
    }

    render() {
        const {
            data = [],
        } = this.props.allow_users;

        return (
            <div>
                <Header />

                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-6">
                            <WFInput 
                                name={'email'}
                                label={'User Email'}
                                validation={(value) => value.length > 5}
                                submit={(value)=> { this.props.allow_users_get_all({
                                    email: value.email,
                                }) }}
                            />
                            <button>Search all pools For user</button>
                        </div>
                    </div>
                </div>
                
                <hr />

                <div className="wf-container">
                    <div className="wf-row">
                        <div className="wf-col-12">
                            <UsersTable 
                                data={data}
                            />
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}

export default Users;