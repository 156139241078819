import React from 'react';
import { Link } from 'react-router-dom';

function ApprovedBlock(props) {
    const { project_reference } = props;
    return (
        <div className="help-block" style={{ marginTop: 30 }}>
            <p><i className="fa fa-circle-question"></i> You can not change the model after it has been approved or published. <Link to={`/project/${project_reference}`} className="button small main" style={{marginRight: 10}}>< i className="fa fa-circle-plus"></i>Create a New Version or Edit Draft</Link></p>
        </div>
    );
}

export default ApprovedBlock;