import React, { Component } from 'react';

class Code extends Component {
    render() {
        const {
            code,
        } = this.props;
        return (
            <div className="highlight">
                <pre className="highlight">
                    <code>
                        {JSON.stringify(code, null, 4)}
                    </code>
                </pre>
            </div>
        );
    }
}

export default Code;