import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const versions = new BaseActions({
    path: `${base.root}/sw/projects/versions/large`,
    type: 'SWALLOW_SIGNED',
});

export default versions;