import React from 'react';
import Table from '../Layout/Table';

import moment from 'moment';

class DataTable extends Table {
    renderRow(data) {
        const {
            date, 
            indicators,
            ref,
            result,
            valid,
        } = data;

        const { history } = this.props;

        return (
            <tr key={ref}>
                <td>
                    {indicators.map(i => {
                        return (<span className="button white mr">{i.toUpperCase()}</span>)
                    })}
                </td>
                <td style={{width: 100}}>
                    {result}
                </td>
                <td style={{width: 100}}>
                    {valid && <button className="button small no-hover valid"><i className="fa fa-circle-check"></i> TRUE</button>}
                    {!valid && <button className="button small no-hover invalid"><i className="fa fa-circle-xmark"></i> FALSE</button>}
                </td>
                <td style={{width: 120}}>
                    <button className="button small no-hover date">{moment(date).fromNow()}</button>
                </td>
                <td >
                    <button className="button small main" onClick={() => history.push(`/data/detail/${ref}`) }>
                        <i class="fa-solid fa-magnifying-glass"></i>View
                    </button>
                </td>
            </tr>
        )
    }
}

export default DataTable;