import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const actions = new BaseActions({
    path: `${base.root}/sw/tests`,
    type: 'SWALLOW_TESTS',
});

export default actions;