import React from 'react';

function Badge(props) {
    const { label = '', type = 'primary', icon, size = 'small', style = {} } = props;

    return (
        <span style={{...style}} className={`${size} ${type} button no-hover`}>
            {icon && <i className={`fa ${icon}`}></i>}
            {label.toUpperCase()}
        </span>
    );
}

export default Badge;