import React, { Component } from 'react';
import base from '../../config';

class Timeout extends Component {
    render() {
        const { email } = this.props;
        const timeout = (base.allow.poll_timeout / 1000);
        return (
            <div id="timeout" className="wf">
                <h1>Oh Dear!</h1>
                <h2>Your email link timed out</h2>
                <div className="wf-alert" style={{margin: '20px -10px 20px'}}><p>Sorry, you failed to be authorised because you didn't click on the email link within {timeout} seconds</p></div>
                <p><b><i className="fa-regular fa-envelope" style={{ marginRight: 10 }}></i> {email || 'email@email.com'}</b></p>
                <p>
                    <button style={{ marginTop: 40 }} onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/login?status=START';
                    }}>
                        Try Again
                    </button>
                </p>
            </div>
        );
    }
}
export default Timeout;