import React, { Component } from 'react';
import { round, max } from 'lodash';

import base from '../../config';

class Waiting extends Component {
    render() {
        const { email, auth_count, name } = this.props;
        const timeout = (base.allow.poll_timeout / 1000);

        return (
            <div id="waiting" className="wf">
                <h1>{name ? `Hi ${name},` : 'Hi,'}</h1>
                <h2>Click on link in the sent email</h2>
                <p>Please check your email account and click on the access link:</p>
                <p><b><i className="fa-regular fa-envelope" style={{ marginRight: 10 }}></i> {email || 'email@email.com'}</b></p>
                <p><mark>Expires in {max([round(timeout - auth_count, 0), 0])} seconds</mark></p>
            </div> 
        );
    }
}
export default Waiting;