import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const public_projects = new BaseActions({
    path: `${base.root}/sw/public/projects`,
    type: 'SWALLOW_PUBLIC_PROJECTS',
});

export default public_projects;