const account_types = {
    free: {
        users_count: 100,
        users_admins_count: 10,
        users_approver_count: 10,
        users_viewers_count: 10,
        users_writer_count: 10,
        projects_count: 3,
        tests_count: 1000,
        tests_external: false,
        tests_monitoring: false,
        tests_searchable: false,
        tests_tags: false,
        process_comments: false,
        process_assets: false,
        process_tasks: false,
        process_blocks: false,
        process_notification: false,
        data_rows: 1000,
        data_channels: 1,
        publish_model_size: 100000,
        publish_factors: true,
        publish_calculation: true,
        publish_transform: true,
        publish_external: false,
        publish_links: true,
        publish_exclusion: true,
        publish_excesses: true,
        publish_refer: true,
        publish_endorsements: true,
        publish_label: false,
        publish_collection: true,
    },
    design: {
        users_count: 100,
        users_admins_count: 100,
        users_writer_count: 100,
        users_approver_count: 100,
        users_viewers_count: 100,
        projects_count: 100,
        tests_count: 100000,
        tests_external: false,
        tests_monitoring: false,
        tests_searchable: false,
        tests_tags: false,
        process_comments: true,
        process_logs: 1000,
        process_assets: true,
        process_tasks: true,
        process_blocks: true,
        process_notification: false,
        data_rows: 1000000,
        data_channels: 10,
        publish_model_size: 100000000,
        publish_factors: true,
        publish_calculation: true,
        publish_transform: true,
        publish_external: true,
        publish_links: true,
        publish_exclusion: true,
        publish_excesses: true,
        publish_refer: true,
        publish_endorsements: true,
        publish_label: false,
        publish_collection: true,
    }
}

export default function account_config (type) {
    return account_types[type] || account_types('free');
}