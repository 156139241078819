import React, { Component } from 'react';
import logo from '../services/swallow/pages/assets/app/new-loader.png';

class GlobalLoading extends Component {
    render() {
        return (
            <div className="new-loading">
                <div className="logo">
                    <img alt="swallow logo" src={logo} />
                </div>
            </div>
        )
    }
}

export default GlobalLoading;