import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const public_quotes = new BaseActions({
    path: `${base.root}/sw/public/quotes`,
    type: 'SWALLOW_PUBLIC_QUOTES',
});

export default public_quotes;