import React, { Component } from 'react';
import versions_hoc from '../../hoc/versions';

import { omit, get } from 'lodash';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import TestDetail from '../../components/Test/TestDetail';
import Tests from '../../components/Test/Tests';
import ApprovedBlock from '../../components/Layout/ApprovedBlock';

import GlobalLoading from '../../../../components/GlobalLoading';

import { unique_id } from '../../utils/unique_id';
import {
    getStep,
    parseFormattedOutput,
} from '../../utils/steps';

import {
    test_runner,
} from '../../utils/runner';

class VersionTests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            version_reference: this.props.version_reference,
            version: this.props.version,
            tests: this.props.tests.tests.map(t => omit(t, ['debug', 'result'])),
            tests_config: this.props.tests.config,
            tests_saved: this.props.tests.saved,
            process: this.props.process,
            selected_test_id: null,
            is_running: false,
        }
        this.updateTestDetail = this.updateTestDetail.bind(this);
        this.updateTestList = this.updateTestList.bind(this);
        this.runner = this.runner.bind(this);
        this.runTests = this.runTests.bind(this);
        this.runTest = this.runTest.bind(this);
        this.viewTest = this.viewTest.bind(this);
        this.fixTests = this.fixTests.bind(this);
        this.createTest = this.createTest.bind(this);
        this.uploadTests = this.uploadTests.bind(this);
        this.deleteTest = this.deleteTest.bind(this);
        this.saveTests = this.saveTests.bind(this);
    }

    componentDidMount() {
        const { tests } = this.state;
        this.runTests(tests);
    }

    async saveTests() {
        const { tests, tests_config, tests_saved, version_reference } = this.state;
        const new_tests = tests.map(t => omit(t, ['debug']));
        await this.props.swallow_tests_post_some({
            id: version_reference,
            data: {
                tests: new_tests,
                config: tests_config,
                saved: tests_saved,
            },
        });
        this.runTests(new_tests);
    }

    createTest() {
        const { tests, version } = this.state;

        // Sets inputs to defaults
        const inputs = getStep('input').output({ step: version.input });
        const id = unique_id();
        const new_test = {
            id,
            name: '',
            key: 'new_test',
            input: parseFormattedOutput(inputs),
            output: {
                result: 0,
                valid: true,
            },
        };
        this.setState({
            tests: [ new_test, ...tests ],
            selected_test_id: id,
        })
        
    }

    async uploadTests(new_tests) {
        this.setState({
            tests: new_tests,
            selected_test_id: null,
        }, async () => {
            await this.saveTests();
        })
        
    }

    async viewTest (test) {
        if (!test) {
            this.setState({
                selected_test_id: null,
            });
        } else {
            this.setState({
                selected_test_id: test.id,
            });
        }
    }

    async deleteTest (test_id) {
        const { tests } = this.state;
        this.setState({ selected_test_id: null });
        this.setState({
            tests: tests.filter(t => t.id !== test_id),
        }, async() => {
            await this.saveTests();
        });
    }

    async fixTests () {
        const { tests } = this.state;
        this.setState({
            tests: tests.map(t => {
                return {
                    ...t,
                    output: {
                        result: t.result.result,
                        valid: t.result.valid,
                    }
                }
            })
        }, () => {
            this.saveTests();
        });
    }

    async updateTestDetail (data) {
        const { tests } = this.state;
        
        const new_tests = tests.map(t => {
            if (t.id === data.id) {
                return data;
            }
            return t;
        });

        await this.setState({ 
            tests: new_tests,
            selected_test_id: null,
        });
        
        await this.runTest(data);

        const result = await this.saveTests();
        return result;
    }

    updateTestList ({ data }) {
        this.setState({ 
            tests: data.items
        }, () => {
            this.saveTests();
        });
    }

    async runner(test, debug) {
        const { tests: all_tests, version } = this.state;

        const response = await test_runner({
            project: version,
            test,
            debug,
        });

        const new_tests = all_tests.map(t => {
            if (t.id === test.id) {
                return response;
            }
            return t;
        });

        this.setState({
            tests: new_tests,
        });

        return response;
    }

    async runTest(run_test = {}, debug = true) {
        this.setState({ is_running: true })
        const { tests } = this.state;

        const result = await this.runner(run_test, debug);

        const new_tests = tests.map(t => {
            if (t.id === result.id) {
                return result;
            }
            return t;
        });

        this.setState({
            tests: new_tests,
            is_running: false,
        });
        
        return result;
    }

    async runTests(run_tests = [], debug = true) {
        this.setState({ is_running: true });
        let new_tests = [];
        for (const test of run_tests) {
            const response = await this.runner(test, debug);
            new_tests = [ ...new_tests, response ];
        }
        this.setState({
            tests: new_tests,
            is_running: false,
        })
    }

    render() {

        const { 
            tests,
            tests_saved , 
            tests_config,
            selected_test_id,
            version,
            process,
            isFetching,
            is_running,
        } = this.state;

        const project_reference = get(process, 'version.project_reference');

        const {
            tests_count,
            tests_external,
            tests_monitoring,
            tests_tags,
            account_type,
            is_approved,
            read_only,
        } = this.props;

        const inputs = getStep('input').output({ step: version.input });
        
        return (
            <div className="swallow_app">

                <TopNav 
                    {...{...this.props, version: process.version, read_only }}
                />

                {isFetching && <GlobalLoading /> }

                {is_approved && <ApprovedBlock project_reference={project_reference} />}

                {!isFetching && selected_test_id &&
                    <TestDetail
                        selected_test_id={selected_test_id}
                        tests={tests}
                        viewTest={this.viewTest}
                        deleteTest={this.deleteTest}
                        runTest={this.runTest}
                        project={version}
                        updateTests={this.updateTestDetail}
                        set_error={this.props.set_error}
                        tests_count={tests_count}
                        tests_external={tests_external}
                        tests_monitoring={tests_monitoring}
                        tests_tags={tests_tags}
                        account_type={account_type}
                        read_only={read_only}
                    />
                }

                {!isFetching && !selected_test_id &&
                    <Tests 
                        tests={tests}
                        tests_config={tests_config}
                        tests_saved={tests_saved}
                        is_running={is_running}
                        runTest={this.runTest}
                        runTests={this.runTests}
                        viewTest={this.viewTest}
                        fixTests={this.fixTests}
                        createTest={this.createTest}
                        updateTests={this.updateTestList}
                        uploadTests={this.uploadTests}
                        deleteTest={this.deleteTest}
                        project={version}
                        tests_count={tests_count}
                        tests_external={tests_external}
                        tests_monitoring={tests_monitoring}
                        read_only={read_only}
                        inputs={inputs}
                    />
                }
                
                <Footer history={this.props.history} />

            </div>
        );
    }
}

export default versions_hoc(VersionTests);