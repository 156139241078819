import { last, get } from 'lodash';

export function getHashId(history) {
    return last(get(history, 'location.hash', '').split('/'))
}

export function getId(history) {
    return last(get(history, 'location.pathname', '').split('/'))
}

export function getQuery(history, query) {
    const queries = last(get(history, 'location.search', '').split(`?`));
    const item = last(queries.split('&').filter(q => q.includes(query)));
    if (item) {
        return last(item.split('='));
    } else {
        return '';
    }
}

export function isVersionPage(history) {
    return get(history, 'location.search', '').includes('/version');
}