import React, { Component } from 'react';
import { sortBy } from 'lodash';

import TopNav from '../../components/Layout/TopNav';

import TeamUserList from '../../components/Team/UserList';
import TeamRoleList from '../../components/Team/RoleList';
import TeamUserAdd from '../../components/Team/UserAdd';

import GlobalLoading from '../../../../components/GlobalLoading';

class SettingsTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            projects: [],
        }
        this.selectUser = this.selectUser.bind(this);
        this.addUser = this.addUser.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.updateAdmin = this.updateAdmin.bind(this);
    }

    async componentDidMount() {
        await this.props.swallow_users_get_all({});
        await this.props.swallow_projects_get_all({});
        const { data: users = [] } = this.props.swallow_users;
        if (users.length) await this.selectUser(users[0].user_reference);
    }

    async selectUser(user_reference) {
        await this.props.swallow_users_get({
            id: user_reference,
        });
    }

    async addUser({ email, pool_reference }) {
        await this.props.swallow_users_post({
            data:{
                email,
                pool_reference,
            },
        });
    }

    async updateUser(project) {
        await this.props.swallow_users_put({
            id: project.user_reference,
            data:{
                project
            },
        });
    }

    async updateAdmin({ is_admin, is_admin_primary, user_reference }) {
        if (is_admin_primary) return console.info('Can not make the primary admin not an admin') // Should be error banner
        await this.props.swallow_users_put({
            id: user_reference,
            data:{
                is_admin,
            },
        });
    }

    render() {

        const {
            data: users = [],
            selected: selected_user = {},
            isFetching: isFetchingUsers
        } = this.props.swallow_users;

        const {
            data: projects = [],
            isFetching: isFetchingProjects
        } = this.props.swallow_projects;

        const isFetching = isFetchingProjects || isFetchingUsers;

        return (
            <div className="swallow_app">
                <TopNav history={this.props.history} />

                { isFetching && <GlobalLoading /> }

                {!isFetching &&
                    <section className="main-content slide-left">

                        <div className="team-area">
                            <div className="team-area-left">
                                <h4>Team Members <span className="button small black no-hover">{users.length}</span></h4>
                                <TeamUserList 
                                    users={sortBy(users, u => u.user_reference)} 
                                    selectUser={this.selectUser}
                                    selected_user={selected_user} 
                                />
                                <h4>Invite Team Member</h4>
                                <div className="edit-inputs">
                                    <TeamUserAdd 
                                        users={users}
                                        selected_user={selected_user}
                                        addUser={this.addUser}
                                    />
                                </div>
                            </div>
                            <div className="team-area-right">
                                <div>
                                    <div className="project-divide">
                                        <div className="project-role-header">
                                            <div className="project-role-header-left">
                                                <h4>Project Access</h4>
                                            </div>
                                            <div className="project-role-header-right">
                                                {!selected_user.is_admin && <button onClick={() => this.updateAdmin({
                                                    is_admin: true,
                                                    user_reference: selected_user.user_reference,
                                                    is_admin_primary: selected_user.is_admin_primary,
                                                })} className="button small">
                                                    <i className="fa fa-solid fa-user-gear"></i>Make User Admin
                                                </button>}
                                                {selected_user.is_admin && <button onClick={() => this.updateAdmin({
                                                    is_admin: false,
                                                    user_reference: selected_user.user_reference,
                                                    is_admin_primary: selected_user.is_admin_primary,
                                                })} className="button small">
                                                    <i className="fa fa-regular fa-user"></i> Remove User Admin
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                    {projects.map(p => <TeamRoleList 
                                        key={p.project_reference}
                                        project={p}
                                        updateUser={this.updateUser}
                                        updateAdmin={this.updateAdmin}
                                        selected_user={selected_user}
                                    />)}
                                </div>
                            </div>
                        </div>

                    </section>
                }

            </div>
        );
    }
}

export default SettingsTeam;