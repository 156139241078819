import React, { Component } from 'react';
import { steps } from '../../../utils/steps';
import slugify from '../../../utils/slugify';

function map_icon(step, account_config) {
    const map = {
        factors: {
            icon: 'fa-scale-unbalanced',
            label: 'Factors',
            category: 'pricing',
            is_active: account_config.publish_factors,
        },
        calculation: {
            icon: 'fa-calculator',
            label: 'Calculation',
            category: 'pricing',
            is_active: account_config.publish_calculation,
        },
        transform: {
            icon: 'fa-diagram-project',
            label: 'Transform',
            category: 'enrich',
            is_active: account_config.publish_transform,
        },
        external: {
            icon: 'fa-trowel-bricks',
            label: 'Integrate API',
            category: 'enrich',
            is_active: account_config.publish_external,
        },
        links: {
            icon: 'fa-database',
            label: 'Data Set',
            category: 'enrich',
            is_active: account_config.publish_links,
        },
        exclusion: {
            icon: 'fa-circle-stop',
            label: 'Exclusions',
            category: 'decision',
            is_active: account_config.publish_exclusion,
        },
        endorsements: {
            icon: 'fa-ticket',
            label: 'Endorsements',
            category: 'decision',
            is_active: account_config.publish_endorsements,
        },
        excesses: {
            icon: 'fa-money-bills',
            label: 'Excesses',
            category: 'decision',
            is_active: account_config.publish_excesses,
        },
        refer: {
            icon: 'fa-user-nurse',
            label: 'Refer',
            category: 'decision',
            is_active: account_config.publish_refer,
        },
        collection: {
            icon: 'fa-database',
            label: 'Collection',
            category: 'collection',
            is_active: account_config.publish_collection,
        },
        /*
        label: { 
            icon: 'fa-tags',
            label: 'Labels',
            category: 'enrich',
            is_active: publish_label,
        },
        */
    };
    return map[step] || 'fa-plus';
}

class StepEmpty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step_name: null,
            step_type: null,
        }
    }

    render() {
        const {
            id,
            updateComponent,
            editComponent,
            deleteComponent,
            account_config,
        } = this.props;

        const {
            step_name,
            step_type,
        } = this.state;

        if (step_type) {
            return (
                <div key={id} className="empty-module">
                    <h4>&nbsp;</h4>
                    <div className="component-delete">
                        <i onClick={() => deleteComponent(id, true)} className="fa fa-circle-xmark"></i>
                    </div>
                    <form>
                        <fieldset>
                            <input onKeyUp={(e) => {
                                this.setState({
                                    step_name: e.target.value,
                                })
                            }} type="text" />
                            <label>Enter Step Name</label>
                        </fieldset>
                        <button onClick={(e) => {
                            e.preventDefault();
                            const key = slugify(step_name);
                            updateComponent({
                                id,
                                data: {
                                    step: step_type,
                                    name: step_name,
                                    key,
                                    id,
                                },
                                empty: true,
                            });
                            editComponent(id);
                        }} className="button secondary small">+ Add</button>
                    </form>
                </div>
            );
        } else {

            return (
                <div key={id} className="empty-module">
                    <h4>Select Step</h4>
                    <div className="component-delete">
                        <i onClick={() => deleteComponent(id, true)} className="fa fa-circle-xmark"></i>
                    </div>
                    <ul>
                        {steps
                        .map(s => {
                            return {
                                ...s,
                                ...map_icon(s.step, account_config),
                            }
                        })
                        .filter(s => {
                            // TODO is_free based on account type
                            return !(s.step === 'input' || s.step === 'output' || s.step === 'label');
                        }).map(t => {
                            const {
                                label,
                                category,
                                icon,
                                is_active,
                            } = t;

                            return (
                                <li key={`${t.id}-${t.step}`} onClick={() => {
                                    if (!is_active) return;
                                    this.setState({
                                        step_type: t.step,
                                    });
                                }}>
                                    {/*!is_active && <div className="button main small contact">Activate</div>*/}
                                    <div className={`module-type-item ${is_active ? '' : 'disabled'}`}>
                                        <div className="module-type-tab">
                                        </div>
                                        <div className="module-type-icon">
                                            <i className={`fa ${icon}`}></i>
                                        </div>
                                        <div className="module-type-label">
                                            <h4>{label} <span className="button no-hover small version">{category}</span></h4>
                                            <p>{t.description}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
    }
}

export default StepEmpty;