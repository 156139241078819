import React, { Component } from 'react';

class ProjectCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            description: null,
            template: null,
        }
    }
    
    render () {
        const { projects = [], templates = [] } = this.props;
        const { name, description, template } = this.state;
        return (
            <div className="project" style={{ marginTop: '30px' }}>
                <div className="project-create-buttons">
                    <div className="project-create-buttons-left"></div>
                    <div className="project-create-buttons-right">
                        <button onClick={this.props.openProject} className="button small grey mr">
                            <i className="fa fa-circle-xmark"></i> Close
                        </button>
                        <button onClick={() => {
                            this.props.createProject({
                                name,
                                description,
                                template,
                            });
                        }} className="button small main">Create Pricing Project</button>
                    </div>
                </div>
                <div className="project-create">
                    <div className="project-create-left">
                        <div className="project-box">
                            { projects.length === 0 && <h4>Create your first project</h4> }
                            { projects.length > 0 && <h4>Create Project</h4> }
                            <p>You can change the name or description at any time.</p>
                            <fieldset className={'spacing'}>
                                <label>Project Name</label>
                                <input 
                                    onChange={(e) => {
                                        this.setState({
                                            name: e.target.value,
                                        })
                                    }}
                                    value={name}
                                />
                            </fieldset>
                            <fieldset className={'spacing'}>
                                <label>Project Description</label>
                                <textarea 
                                    onChange={(e) => {
                                        this.setState({
                                            description: e.target.value,
                                        })
                                    }}
                                    value={description}
                                />
                            </fieldset>
                        </div>
                    </div>
                    <div className="project-create-right">
                        <div className="project-box">
                            <h4>Pick Template</h4>
                            <p>Select template, clone a project or start from scratch.</p>
                            <div className="project-templates">
                                <fieldset key={'select-template'}>
                                    <label>Select Template</label>
                                    <select 
                                        value={this.state.template} 
                                        onChange={(e) => {
                                            this.setState({
                                                template: e.target.value
                                            });
                                        }
                                    }>
                                        <optgroup label="Blank">
                                            <option value='blankTemplate'>Blank Template</option>
                                        </optgroup>
                                        <optgroup label="Template">
                                            {templates.map(l => {
                                                return (<option value={l.id}>{l.name}</option>);
                                            })}
                                        </optgroup>
                                        <optgroup label="Clone Your Projects">
                                            {projects.map(p => {
                                                return (<option value={`project::${p.project_reference}`}>{p.name}</option>);
                                            })}
                                        </optgroup>
                                        
                                    </select>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectCreate;