import React, { Component } from 'react';

import { DownloadButton } from '../../../../utils/download';
import { UploadArea } from '../../../../utils/upload';
import { VirtualTable } from '../../../../utils/virtualTable';

import { isEmpty, keys } from 'lodash';

class Links extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: {},
            data: [],
            upload: false,
        }
        this.updateStep = this.updateStep.bind(this);
        this.uploadLinks = this.uploadLinks.bind(this);
    }

    componentDidMount() {
        const { step } = this.props;
        this.setState({
            step,
        });
    }

    updateStep(step) {
        this.setState({
            step,
            upload: false,
        }, () => {
            this.props.updateStep(step);
        }) 
    }

    async uploadLinks(results = {}) {
        const { step } = this.props;

        const {
            exp,
            format,
            links,
        } = results;

        await this.updateStep({
            ...step,
            exp,
            format,
            links,
        });
    }

    render() {
        const { inputs, is_approved } = this.props;
        const {
            upload,
            step = {},
        } = this.state;

        const  { links = {} } = step;

        return (
            <div>
                <div key={`full-buttons`} className="upload-buttons">
                    <button className="button small grey no-hover mr">Rows : {keys(links).length}</button>
                    <DownloadButton 
                        data={{
                            links,
                        }}
                        label={'Data Set'}
                        file_name={`${step.key}.csv`}
                        type={'links'}
                        className={'mr'}
                    />
                    {!is_approved && <button className="button small secondary mr" onClick={() => this.setState({ upload: !upload})}><i className="fa fa-angles-up"></i>Upload CSV</button>}
                </div>

                {(upload  || isEmpty(links)) &&
                    <UploadArea 
                        complete={this.uploadLinks}
                        inputs={inputs}
                        type='links'
                    />
                }

                {!upload && !isEmpty(links) &&
                    <div className="exclusions-edit">
                        <VirtualTable
                            data={links} 
                            type='links'
                        />
                    </div>
                }
            </div>
        );
    }
}

export default Links;