import React, { Component } from 'react';
import config from '../../../../config';
import Badge from '../Layout/Badge';

class TopNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url : 'form',
        }
    }

    render() {
        const { 
            history, 
            version_reference, 
            project_reference, 
            version_status, 
            version_number,
        } = this.props;
        
        let try_url = `/project/try/${project_reference}`;
        if (version_reference) {
            try_url = `/version/try/${version_reference}`;
        }

        let api_url = `/sw/quotes/${project_reference}`;
        if (version_reference) {
            api_url = `/sw/quotes/${project_reference}?version_reference=${version_reference}`;
        }

        return (
            <div className="version-url">
                <ul>
                    <li>
                        <Badge 
                            type={version_status}
                            label={`${version_status} #${version_number}`} 
                        />
                        { this.state.url === 'form' && <input value={`${config.root}${try_url}`} /> }
                        { this.state.url === 'api' && <input value={`${config.root}${api_url}`} /> }
                    </li>
                    <li>
                        <button onClick={() => this.setState({ url: this.state.url === 'api' ? 'form' : 'api' }) } className="button white small mr">
                            <i className="fa fa-shuffle"></i>
                            { this.state.url === 'api' ? 'VIEW FORM' : 'VIEW API' }
                        </button>
                        <button onClick={() => history.push(`${try_url}?section=${this.state.url}`)} className="button main small">
                            <i className="fa fa-laptop"></i>
                            TRY
                        </button>
                    </li>
                </ul>
            </div>
        );
    }
}

export default TopNav;