import React, { Component } from 'react';

import { expressionToCommand, commandToExpression, evaluateLists } from '@swa_llow/pricing_engine';
import ExpressionCommandForm from './ExpressionCommandForm';

import { keys } from 'lodash';

const operators = [{
    value: 'boolean_equals',
    text: 'Boolean equals',
    type: ['boolean'],
}, {
    value: 'text_equals',
    text: 'Text equals',
    type: ['string', 'date'],
}, {
    value: 'text_contains',
    text: 'Text contains',
    type: ['string'],
}, {
    value: 'text_starts',
    text: 'Text starts with',
    type: ['string'],
},{
    value: 'greater_than',
    text: 'Greater than',
    type: ['number', 'decimal', 'integer'],
}, {
    value: 'greater_than_equal',
    text: 'Greater than or equal',
    type: ['number', 'decimal', 'integer'],
}, {
    value: 'less_than',
    text: 'Less than',
    type: ['number', 'decimal', 'integer'],
}, {
    value: 'less_than_equal',
    text: 'Less than or equal',
    type: ['number', 'decimal', 'integer'],
}, {
    value: 'equals',
    text: 'Equals',
    type: ['number', 'decimal', 'integer'],
}, {
    value: 'not_equal',
    text: 'Does not equal',
    type: ['number', 'decimal', 'integer'],
}];

class ExpressionCommands extends Component {
    constructor(props) {
        super(props);
        const { exp } = this.props;

        let commands = [];

        try {
            commands = exp ? expressionToCommand(exp) : [{ variable: '', operator: '', value: ''}];
        } catch(e) {
            commands = [{ variable: '', operator: '', value: ''}];
        }

        this.state = {
            lists: evaluateLists(this.props.version),
            inputs: this.props.inputs,
            exp: this.props.exp,
            commands,
        };

        this.updateCommand = this.updateCommand.bind(this);
        this.addCommand = this.addCommand.bind(this);
        this.deleteCommand = this.deleteCommand.bind(this);
        this.renderCommandItems = this.renderCommandItems.bind(this);
    }

    updateCommand({
        key,
        value,
        index,
    }) {
        let { commands }  = this.state;
        
        if(!commands[index]) {
            commands[index] = { variable: '', operator: '', value: ''};
        }

        commands[index][key] = value;

        this.setState({ commands });

        let exp;
        try {
            exp = commandToExpression(commands);
        } catch(e) {
            exp = '';
        }
        this.props.updateExpression(exp);
    }

    addCommand(statement) {
        let { commands }  = this.state;
        this.setState({
            commands: [
                ...commands,
                { statement },
                { variable: '', operator: '', value: ''},
            ]
        })
    }

    deleteCommand(index) {
        let { commands }  = this.state;
        commands = commands.filter((c, i) => !(i === index || i === (index - 1)));
        this.setState({
            commands,
        });
    }

    renderCommandItems (command = {}, index = 0, is_approved) {
        const { inputs, lists } = this.state;

        function key_inputs () {
            return ['', ...keys(inputs)];
        };

        let {
            statement,
            variable,
            operator,
            value,
        } = command;

        const type = variable && inputs && inputs[variable] ? inputs[variable].type : 'decimal';
        const found_lists = (lists[variable] && operator === 'text_equals') ? lists[variable].data : [];

        if (statement) {
            return (
                <div className="command-builder-item" style={{ padding: 10, background: 'none', border: 'none' }}>
                    <button className="button grey no-hover">{statement}</button>
                </div> 
            )
        }

        return (
            <div className="command-builder-item">
                <div className="command-builder-item-left">
                    <fieldset className={'spacing'}>
                        <label>Input <em style={{fontWeight: 100, color: '#a1a1a1'}}>{type ? `(${type})` : ''}</em></label>   
                        <ExpressionCommandForm 
                            lists={key_inputs()}
                            type={'string'}
                            command_key={'variable'}
                            index={index}
                            value={variable}
                            updateCommand={this.updateCommand}
                        />
                    </fieldset>
                </div>
                <div className="command-builder-item-center">      
                    <fieldset className={`spacing ${variable ? '' : 'disabled'}`}>
                        <label>Operator</label>                        
                        <select disabled={!variable} value={operator} onChange={(e) => {
                            const { value } = e.target;
                            this.updateCommand({
                                key: 'operator',
                                value,
                                index,
                            });
                        }}>
                            <option value={''}>{''}</option>
                            {operators.filter(o => {
                                return o.type.includes(type);
                            }).map(op => {
                                return (<option value={op.value}>{op.text}</option>)
                            })}
                        </select>
                    </fieldset>
                </div>
                <div className="command-builder-item-right">
                    <fieldset className={`spacing ${variable ? '' : 'disabled'}`}>
                        <label>Value</label>    
                        <ExpressionCommandForm 
                            lists={['', ...found_lists]}
                            type={type}
                            command_key={'value'}
                            index={index}
                            value={value}
                            updateCommand={this.updateCommand}
                        />
                    </fieldset>
                </div>
                <div className="command-builder-item-buttons">
                    { index !== 0 && !is_approved &&
                        <button className="button small invalid" onClick={(e) => {
                            e.preventDefault();
                            this.deleteCommand(index)
                        }}>
                            <i className="fa fa-circle-xmark"></i>
                            DELETE
                        </button>
                    }
                </div>
            </div>
        )
    }

    render() {
        const { commands } = this.state;
        const { is_approved } = this.props;
        return (
            <form className="command-builder" autoComplete="false">

                {commands.map((com, i) => {
                    return (this.renderCommandItems(com, i, is_approved));
                })}

                {!is_approved && 
                    <div className="command-builder-footer">
                        <button className='button small main mr'onClick={(e) => {
                                e.preventDefault();
                                this.addCommand('AND');
                            }}>+ ADD</button>
                        <button className='button small main' onClick={(e) => {
                                e.preventDefault();
                                this.addCommand('OR');
                            }}>+ OR</button>
                    </div>
                    }
            </form>
        );
    }
}

export default ExpressionCommands;