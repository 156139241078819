import React, { Component } from 'react';
import config from '../../../../config';
import FormElementSelector from './FormElementSelector';
import ActualResult from '../Test/ActualResult';
import Badge from '../Layout/Badge';

import { test_runner } from '../../utils/runner';

import { isUndefined, find, get } from 'lodash';

class TryArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_running: false,
            section: this.props.section || 'form',
            quote: {},
            quote_errors: [],
            quote_result: null,
        }
        this.runQuote = this.runQuote.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.clearQuote = this.clearQuote.bind(this);
        this.updateQuote = this.updateQuote.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        this.setDefaults();
    }

    async validate() {
        const { inputs = [] } = this.props;
        let { quote } = this.state;
        quote = quote || {};
        let errors = [];

        inputs.forEach(input => {
            if (input.static) return;
            if(isUndefined(quote[input.key]) || quote[input.key] === ''){
                errors = [ input, ...errors ];
            }
        });
        await this.setState({
            quote_errors: errors,
        });
        return errors.length === 0;
    }

    async runQuote () {
        const { quote } = this.state;
        const { project } = this.props;

        const valid = await this.validate();

        if(valid) {
            this.setState({ is_running: true });
            const result = await test_runner({
                project: project,
                test: {
                    input: quote,
                    output: {},
                },
                debug: true,
            });
            this.setState({ 
                quote_result: result,
                is_running: false, 
            });
        }
    }

    updateQuote(data) {
        const { quote } = this.state;
        this.setState({
            quote: {
                ...quote,
                ...data,
            }
        });
    }

    clearQuote() {
        this.setState({
            quote: null,
            quote_result: {},
            quote_errors: [],
        });
    }

    setDefaults() {
        const { inputs } = this.props;
        const quote = {};
        inputs.forEach(input => {
            if (input.static) return;
            quote[input.key] = input.def;
        });
        this.setState({
            quote,
        }, () => this.validate());
    }

    render() {
        const { inputs = [], process = {}, lists = {}, auth = {}, project = {}, is_latest_published = false } = this.props;

        let { 
            is_running,
            quote_result,
            quote_errors,
            quote,
            section,
        } = this.state;

        let url;
        let current_status;
        let current_version;

        if (is_latest_published) {
            current_version = get(project, 'meta.version');
            current_status = get(project, 'meta.status');
            const project_reference = get(project, 'meta.project_reference');
            url = `${config.root}/sw/quotes/${project_reference}`;
        } else {
            let { project_reference, version_reference, status, version } = process.version || {};
            current_status = status;
            current_version = version;
            url = `${config.root}/sw/quotes/${project_reference}?version_reference=${version_reference}`;
        }

        const { client_id_key, client_secret_key } = auth;

        return (
            <div className="try-area">
                <div className="try-area-center">
                    <ul className="try-area-nav-buttons">
                        <li onClick={()=> {
                            this.setState({
                                section: 'form',
                            })
                        }} className={`${ section === 'form' ? 'active' : '' }`}>Form</li>
                        <li onClick={()=> {
                            this.setState({
                                section: 'api',
                            }, () => {
                                this.setDefaults();
                            })
                        }} className={`${ section === 'api' ? 'active' : '' }`}>API</li>
                        {/*<li onClick={()=> {}}>Chatbot</li>*/}
                    </ul>
                    <div className="try-area-content">

                        { section === 'form' &&
                            <div className="try-area-form">
                                <div className="try-area-form-buttons">
                                    <button className="button grey small mr" onClick={this.clearQuote}>Clear Quote</button>
                                    <button className="button grey small mr" onClick={this.setDefaults}>Set Defaults</button>
                                    <button className="button secondary small" onClick={this.runQuote}>Run Quote</button>
                                </div>
                                <form>
                                    {inputs.filter(i => !i.static).map(input => {
                                        const { type, key, label } = input;
                                        const error = find(quote_errors, e => e.key === key);
                                        return (
                                            <FormElementSelector
                                                lists={lists} 
                                                key={key}
                                                error={error}
                                                quote={quote}
                                                type={type}
                                                form_key={key}
                                                label={label}
                                                updateQuote={this.updateQuote}
                                            />
                                        )
                                    })}
                                </form>
                                <div className="try-area-form-buttons"></div>
                            </div>
                        }

                        { section === 'api' &&
                            <div className="try-area-api">
                                <fieldset className={'spacing'}>
                                    <label>URL <em>(POST)</em></label>
                                    <input 
                                        disabled={true}
                                        value={url}
                                    />
                                    <button className="secondary button small copy" onClick={() => navigator.clipboard.writeText(url)}>Copy</button>
                                </fieldset>
                                <fieldset className={'spacing'}>
                                    <label>Client ID Key</label>
                                    <input 
                                        disabled={true}
                                        value={client_id_key}
                                    />
                                    <button className="secondary button small copy" onClick={() => navigator.clipboard.writeText(client_id_key)}>Copy</button>
                                </fieldset>
                                <fieldset className={'spacing'}>
                                    <label>Client Secret Key</label>
                                    <input 
                                        disabled={true}
                                        value={client_secret_key}
                                    />
                                    <button className="secondary button small copy"  onClick={() => navigator.clipboard.writeText(client_secret_key)}>Copy</button>
                                </fieldset>
                                <div className="code-block">
                                    <label>Example Request</label>
                                    <textarea 
                                        value={JSON.stringify(quote, null, 4)}
                                    />
                                    <button className="button grey small reset" onClick={this.setDefaults}>Set Defaults</button>
                                    <button className="secondary button small copy" onClick={this.runQuote}>Run Quote</button>
                                </div>
                                <div className="code-block">
                                    <label>Example CURL</label>
                                    <textarea value={`curl --request POST --url ${url} --header 'content-type: application/json' --header 'client_secret_key: ${client_secret_key}' --header 'client_id_key: ${client_id_key}' --data '${JSON.stringify(quote)}'`} />
                                </div>
                            </div>
                        }
                        
                        <div className="try-area-chatbot"></div>
                    </div>
                </div>
                <div className="try-area-right">
                    {!is_running && quote_result && 
                        <div className="edit-inputs">
                            <Badge 
                                label={`${current_status} #${current_version}`}
                                type={current_status}
                                style={{ margin: 10 }}
                            />

                            {!is_latest_published &&
                                <Badge 
                                    label={`VERSION`}
                                    type={'version'}
                                />
                            }

                            {is_latest_published &&
                                <Badge 
                                    label={`LIVE PUBLISHED`}
                                    type={current_status}
                                />
                            }

                            <ActualResult
                                quote={quote_result}
                                is_running={is_running}
                                updateIsLogOpen={this.updateIsLogOpen}
                            /> 
                        </div>
                    }

                    {!quote_result && !quote_result && 
                        <div className="edit-inputs">
                            <Badge 
                                label={`${current_status} #${current_version}`}
                                type={current_status}
                                style={{ margin: 10 }}
                            />

                            {!is_latest_published &&
                                <Badge 
                                    label={`VERSION`}
                                    type={'version'}
                                />
                            }           

                            {is_latest_published &&
                                <Badge 
                                    label={`LIVE PUBLISHED`}
                                    type={current_status}
                                />
                            }

                            <ActualResult
                                quote={{
                                    result: { result: 0.00 },
                                    debug: {},
                                }}
                                is_running={is_running}
                                updateIsLogOpen={this.updateIsLogOpen}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default TryArea;