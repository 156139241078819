import React, { Component } from 'react';

class Failure extends Component {
    render() {
        const { email } = this.props;
        return (
            <div id="failure" className="wf">
                <h1>Oh Dear!</h1>
                <h2>Something went wrong</h2>
                <div className="wf-alert" style={{margin: '20px -10px 20px'}}><p>Sorry, you failed to be authorised to via the access link within the email.</p></div>
                <p><b><i className="fa-regular fa-envelope" style={{ marginRight: 10 }}></i> {email || 'email@email.com'}</b></p>
                <button style={{marginTop: 40 }} onClick={() => {
                    window.location.href = '/login?status=START';
                }}>
                    Try Again
                </button>
            </div> 
        );
    }
}
export default Failure;