const strings = {
    languages: {
        en: 'english',
        fr: 'french',
    },
    login: {
        email_address: 'Email address',
        sign_in: 'Sign in',
        sign_out: 'Sign out',
    },
}

export default strings;
