import React, { Component } from 'react';
import { evaluateExpressions } from './ExpressionTextarea';
import { isString, isBoolean, has } from 'lodash';

class ExpressionTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test: null,
        }
        this.testExpression = this.testExpression.bind(this);
    }

    componentDidUpdate(nextProps) {
        // Cleans out old expression tests
        if (this.state.test) {
            const { expression = {} } = nextProps;
            const { key } = this.state.test;
            if (expression.key !== key) {
                this.setState({ test: null })
            }
        }
    }

    testExpression () {
        const { inputs, expression, collection, updateDefaultFromExpression } = this.props;
        if (!expression.exp) return;

        const [ result ] = evaluateExpressions({
            inputs,
            collection,
            items: [expression]
        }) || [];

        this.setState({
            test: result,
        }, () => {
            if (!result.invalid) {
                if (updateDefaultFromExpression) {
                    updateDefaultFromExpression(result.result);
                }
            }
        });
    }

    render() {
        const { 
            test,
        } = this.state;

        let result;

        function toBoolean(result = '') {
            if (result === null) result = '';
            if (isBoolean(result)) {
                result = result.toString().toUpperCase();
            } else if (!isString(result)) {
                result = result.toString();
            } else {}
            return result;
        }

        if (test && has(test, 'result')) {
            result = test.result;
            result = toBoolean(result);
        }

        return (
            <div className="table-control-panel bottom">
                <div className="table-control-panel-left">
                    {test && !test.invalid &&
                        <div className="expression-debug">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Expression Query</th>
                                        <th style={{ width: 200 }}>Result Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(test.elements || []).map((el, i) => {
                                        return (
                                            <tr key={`exp-${i}`}>
                                                <td><span className="variable">{el.query}</span></td>
                                                <td>{ toBoolean(el.value) }</td>
                                            </tr>
                                        )
                                    })}
                                    <tr key={`exp-evaluated`} className="expression-evaluated">
                                        <td>Evaluated</td>
                                        <td>{ result }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    { test && test.invalid &&
                        <div className="expression-debug invalid">
                            <p>{ test.message }</p>
                        </div>
                    }
                </div>
                <div className="table-control-panel-right">
                    <button className="button secondary small"
                        onClick={this.testExpression}
                    > Test Expression</button>
                </div>
            </div>
        );
    }
}

export default ExpressionTest;