
import React, { Component } from 'react';

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

class TeamUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            valid: null,
        }
        this.updateUser = this.updateUser.bind(this);
        this.addUser = this.addUser.bind(this);
    }

    updateUser (value) {
        const valid = validateEmail(value);
        this.setState({ 
            email: value,
            valid,
        });
    }

    async addUser() {
        const { pool_reference } = this.props.selected_user;
        const { valid, email } = this.state;
        if (valid) {
            await this.props.addUser({
                email,
                pool_reference,
            });
            this.setState({
                email: '',
                valid: null,
            });
        }
    }

    render() {
        const { 
            email,
            valid,
        } = this.state;

        const { users = [] } = this.props;

        return (
            <div>
                <p>Your account allows <b>{ 10 - users.length }</b> more Team Members</p>
                <fieldset className={`${email === '' ? '' : (valid ? 'success' : 'error')} spacing`}>
                    <label>Email</label>
                    <input
                        onChange={(e) => this.updateUser(e.target.value)} 
                        value={email}
                    />

                    {email !== '' && valid &&
                        <i className="fa fa-circle-check"></i>
                    }

                    {email !== '' && !valid &&
                        <i className="fa fa-circle-exclamation"></i>
                    }

                </fieldset>
                <button onClick={this.addUser} className={`button small main`}>+ Add New Team Member</button>
            </div>
        )
    }

}

export default TeamUserList;