import React, { Component } from 'react';
import { capitalize, find, each } from 'lodash';

// TODO - this should exist in own util for sharing with steps
export const format = [{
    step: 'transform',
    icon: 'fa-code-merge',
    type: 'enrich',
},{
    step: 'external',
    icon: 'fa-code',
    type: 'enrich',
},{
    step: 'links',
    icon: 'fa-database',
    type: 'enrich',
},{
    step: 'collection',
    icon: 'fa-filter',
    type: 'enrich',
},{
    step: 'calculation',
    icon: 'fa-calculator',
    type: 'pricing',
},{
    step: 'factor',
    icon: 'fa-scale-unbalanced-flip',
    type: 'pricing',
},{
    step: 'exclusions',
    icon: 'fa-circle-stop',
    type: 'decisions',
},{
    step: 'refer',
    icon: 'fa-user-group',
    type: 'decisions',
},{
    step: 'excess',
    icon: 'fa-money-bills',
    type: 'decisions',
},{
    step: 'endorsements',
    icon: 'fa-ticket',
    type: 'decisions',
},{
    step: 'input',
    icon: 'fa-arrow-right-to-bracket',
    type: 'input',
},{
    step: 'output',
    icon: 'fa-arrow-right-from-bracket',
    type: 'output',
}];

// TODO should run tests on this

export function buildDataIndex ({ version }) {
    let data = []; 
    const { input = {}, output = {}, steps = [] } = version;       
    
    each((input.format || {}), (f, k) => {
        data = [...data, {
            step: 'input',
            id: 'input',
            key: k,
            label: f.label,
        }]
    });
    
    each((output.format || {}), (f, k) => {
        data = [...data, {
            step: 'output',
            id: 'output',
            key: k,
            label: f.label,
        }]
    });

    steps.forEach(s => {  
        console.log(s)
        each((s.format || {}), (f, k) => {
            data = [...data, {
                step: s.step,
                key: k,
                label: f.label || f.name,
                id: s.id,
            }]
        });
        if (s.items) {
            (s.items || []).forEach(item => {
                data = [...data, {
                    step: s.step,
                    key: s.key,
                    label: item.label || item.name,
                    id: s.id,
                }];  
            })
        }
        if (s.step === 'calculation') {
            data = [...data, {
                step: s.step,
                key: s.key,
                label: s.label || s.name,
                id: s.id,
            }]
        }
        if (s.step === 'factors') {
            each(s.factors, (f, k) => {
                data = [...data, {
                    step: s.step,
                    key: k,
                    label: s.key,
                    id: s.id,
                }]
            });
        }
    });

    return data;
}

export class Spotlight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            term: '',
            active: false,
            selectedIndex: 0,
        };
        this.spotlight = this.spotlight.bind(this);
        this.buildData = this.buildData.bind(this);
        this.filter = this.filter.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    async UNSAFE_componentWillMount() {
        this.buildData();
        document.addEventListener('keydown', this.spotlight);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.spotlight);
    }

    componentDidMount() {
        document.getElementById('spotlight-search-input-el').focus();
    }

    buildData() {
        const { version = {} } = this.props;
        const data =  buildDataIndex({ version })
        this.setState({ data });
    }

    spotlight(e = {}) {
        const { selectedIndex = 0, filtered_data:data = [] } = this.state;
        const selected_item = document.getElementsByClassName('selected search-spotlight-result-item')[0];

        if (e.code === 'ArrowDown') {
            if (selectedIndex < data.length) {
                this.setState({ selectedIndex: selectedIndex + 1 });
                if(selected_item) selected_item.scrollIntoView({
                    block: "center", 
                    behaviour: "smooth", 
                    inline: "nearest"
                });
            }
        }

        if (e.code === 'ArrowUp') {
            if (selectedIndex > 0) {
                this.setState({ selectedIndex: selectedIndex - 1 });
                if(selected_item) selected_item.scrollIntoView({
                    block: "center", 
                    behaviour: "smooth", 
                    inline: "nearest"
                });
            }
        }

        if (e.code === 'Enter') {
            if (selectedIndex > 0) {
                this.onSelect({ selectedIndex });
            }
        }
    }

    onSelect ({ selectedIndex }) {
        const { filtered_data:data = [] } = this.state;
        const { version_reference, history } = this.props;
        const { key, id } = data[selectedIndex - 1];
        const url = `/version/publish/${version_reference}?step_id=${id}&item_id=${key}`;
        this.setState({ selectedIndex: 0, term: '', loading :true });
        // Reactivate scroll on body
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
        history(url);
    }

    filter() {
        const { term = '', data = []} = this.state;
        if (term === '') return [];
        let found = data.filter(d => {
            const label = d.label.includes(term);
            const key = d.key.includes(term);
            return label || key;
        });
        // Shortens label returned
        found = found.map(f => {
            if ((f.label || '').length > 60 && f.label.includes(term)) {
                const arr = f.label.split(term);
                const label = '...' + arr[0].slice(-27) + term + arr[1].slice(0, 27) + '...';
                return {
                    ...f,
                    label,
                }
            }
            if ((f.label || '').length > 60) {
                return {
                    ...f,
                    label: f.label.substr(0, 57) + '...',
                }
            }
            return f;
        });

        this.setState({
            filtered_data: found,
        });
    }

    render() {
        const { version = {} } = this.props;
        const { meta = {} } = version;
        const { active = false, selectedIndex = 0, filtered_data:data = [] } = this.state;
        const has_results = data.length > 0;
        return (
            <div className="search-spotlight">
                <div className={`search-spotlight-container ${has_results ? 'has_results' : ''}`}>
                    <div className={`search-spotlight-box ${active ? 'active' : ''}`}>
                        <div className="search-spotlight-input">
                            <div className="search-spotlight-input-left">
                                <i className="fa fa-magnifying-glass"></i>
                            </div>
                            <div className="search-spotlight-input-center">
                                <input 
                                    id="spotlight-search-input-el"
                                    type="text"
                                    onFocus={() => this.setState({ active : true, selectedIndex: 0 })}
                                    placeholder='search everything'
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        this.setState({ term: value }, () => {
                                            this.filter({ selectedIndex });
                                        });
                                    }}
                                    value={this.state.term}
                                />
                            </div>
                            <div className="search-spotlight-input-right">
                                <div className="search-spotlight-close" onClick={this.props.close}>
                                    <i className="fa fa-xmark" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {has_results &&
                        <div className="search-spotlight-results">
                            <div className="search-spotlight-group">
                                <h3>In this project: {meta.name}</h3>
                                {data.map((d, i) => {
                                    const { step, label, key } = d;
                                    const { type, icon } = find(format,f => f.step === step) || {};
                                    return (
                                        <div 
                                            className={`search-spotlight-result-item ${type} ${selectedIndex === i + 1 ? 'selected' : ''}`}
                                            onClick={() => this.onSelect({ selectedIndex : (i + 1)})}
                                        >
                                            <div className="search-spotlight-result-icon">
                                                <div className="search-spotlight-icon-box">
                                                    <i className={`fa ${icon}`}></i>
                                                </div>
                                            </div>  
                                            <div className="search-spotlight-result-step">
                                                <p>{capitalize(step)} <span>{label}</span></p>
                                            </div>
                                            <div className="search-spotlight-result-key">
                                                <p><span>{key}</span></p>
                                            </div>  
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Spotlight;