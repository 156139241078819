import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const companys = new BaseActions({
    path: `${base.root}/a/companys`,
    type: 'ALLOW_COMPANYS',
});

export default companys;