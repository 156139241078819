import React, { Component } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import moment from 'moment';
import Badge from '../components/Layout/Badge';
import { 
    each, 
    max, 
    keys, 
    isString, 
    isUndefined, 
    indexOf, 
    get,
    round,
    isBoolean,
} from 'lodash';

// TODO unit tests all the data mapping

const mapMethods = (type) => {
    const map = {
        factor: {
            dataMap: dataFactor,
        },
        collection: {
            dataMap: dataCollection,
        },
        links: {
            dataMap: dataLinks,
        },
        tests: {
            dataMap: dataTests,
        },
        users: {
            dataMap: dataUsers,
        },
        companys: {
            dataMap: dataCompanys,
        },
        quotes: {
            dataMap: dataQuotes,
        },
        versions: {
            dataMap: dataVersions,
        },
        default: {
            dataMap: dataDefault,
        }
    };
    return map[type] || map['default'];
}

export function dataVersions ({ data = [] }) {
    const rows = data.map(item => {
        return {
            name: item.name,
            status: item.status,
            version: item.version,
            updated: item.updated_at,
        }
    });

    const columns = [{
        key: 'name',
        label: 'Model',
    }, {
        key: 'status',
        label: 'Status',
        render: (item) => {
            return (<Badge type={item.status} label={`${item.status} #${item.version}`} />)
        }
    }, {
        key: 'updated',
        label: 'Updated',
        render: (item) => {
            if (!item.updated) return (<div />);
            return (<span className="button date small">{moment(item.updated).fromNow()}</span>)
        }
    }];

    return {
        columns,
        rows,
    };
}

export function dataCompanys ({ data = [] }) {
    const rows = data.map(item => {
        return {
            company: item.company_name,
            company_reference: item.company_reference,
            company_client_id: item.company_client_id_key,
            company_client_secret: item.company_client_secret_key,
            account_type: item.account_type,
            created_at: item.created_at,
        }
    });

    const columns = [{
        key: 'company',
        label: 'Company',
    },{
        key: 'company_reference',
        label: 'Reference',
    },{
        key: 'company_client_id',
        label: 'Client ID',
    },{
        key: 'company_client_secret',
        label: 'Client Secret',
    }, {
        key: 'account_type',
        label: 'Account',
    }, {
        key: 'created_at',
        label: 'Created',
        render: (item) => {
            if (!item.created_at) return (<div />);
            return (<span className="button date small">{moment(item.created_at).format('lll')}</span>)
        }
    }];

    return {
        columns,
        rows,
    };
}

export function dataQuotes ({ data = [] }) {
    const rows = data.map(item => {
        return {
            indicators: item.indicators,
            valid: item.valid,
            result: item.result,
            created_at: item.created_at,
        }
    });

    const columns = [{
        key: 'indicators',
        label: 'Indicators',
        render: (item) => {
            return item.indicators.split('::').map(ind => {
                return (<Badge type={'white'} label={`#${ind}`} />);
            });
        }
    }, {
        key: 'result',
        label: 'Result',
    }, {
        key: 'valid',
        label: 'Valid',
        render: (item) => {
            if (item.valid) {
                return (<Badge type={'valid'} label={`VALID`} />)
            } else {
                return (<Badge type={'invalid'} label={`INVALID`} />)
            }
        }
    }, {
        key: 'created_at',
        label: 'Created',
        render: (item) => {
            if (!item.created_at) return (<div />);
            return (<span className="button date small">{moment(item.created_at).fromNow()}</span>)
        }
    }];

    return {
        columns,
        rows,
    };
}

export function dataUsers ({ data = [] }) {
    const rows = data.map((item, i) => {
        return {
            index: (i + 1),
            email: item.email,
            client_id_key: item.client_id_key,
            client_secret_key: item.client_secret_key,
            name: (item.custom || {}).name,
            admin: item.is_admin,
            last_login: item.last_token_check_at,
        }
    });

    const columns = [{
        key: 'email',
        label: 'Email',
        render: (item) => {
            return (<span>{item.index} - {item.email}</span>)
        }
    }, {
        key: 'name',
        label: 'Name',
    }, {
        key: 'admin',
        label: 'Admin',
        render: (item) => {
            if (item.admin) {
                return (<span className="button valid small">ADMIN</span>)
            } else {
                return (<div/>)
            }
        }
    }, {
        key: 'last_login',
        label: 'Last Login',
        render: (item) => {
            if (!item.last_login) return (<div />);
            return (<span className="button date small">{moment(item.last_login).fromNow()}</span>)
        }
    }];

    return {
        columns,
        rows,
    };
}

export function dataTests ({ data = {} }) {
    const rows = data.map(v => {
        const actual_result = (v.result || {}).result;
        const expected_result = (v.output || {}).result;
        let movement = actual_result ? 
            round(((actual_result - expected_result) / expected_result) * 100, 1) : 
            0;
        if(isNaN(movement)) movement = 0;
        if (movement === Infinity) movement = 100;
        return {
            name: v.name,
            expected_result,
            expected_valid: (v.output || {}).valid,
            actual_result,
            actual_valid: (v.result || {}).valid,
            movement,
            tags: v.tags,
            id: v.id,
        };
    });

    function renderValidity(item, key) {
        if (!isBoolean(item[key])) {
            return (<div></div>)
        }
        if (item[key]) {
            return (
                <button className="button small no-hover valid">
                    <i className="fa fa-circle-check"></i> TRUE
                </button>
            )
        } else {
            return (
                <button className="button small no-hover invalid">
                    <i className="fa fa-circle-xmark"></i> FALSE
                </button>
            )
        }
    }

    function renderMovement(item, key) {
        if (item[key] === '-' || item[key] === 0) {
            return (
                <button className="button small no-hover white">
                    <i className="fa fa-circle-minus"></i> 0%
                </button>
            )
        }
        if (item[key] > 0) {
            return (
                <button className="button small no-hover valid">
                    <i className="fa fa-circle-up"></i>{item[key]}%
                </button>
            )
        } else if (item[key] < 0){
            return (
                <button className="button small no-hover invalid">
                    <i className="fa fa-circle-down"></i>{item[key]}%
                </button>
            )
        }
    }

    let columns = [{ 
        key: 'name',
        label: 'Name',
    }, {
        key: 'expected_result',
        label: 'Expected Result'
    }, {
        key: 'expected_valid',
        render: (item) => renderValidity(item, 'expected_valid'),
        label: 'Expected Validity'
    }, {
        key: 'actual_result',
        label: 'Actual Result'
    }, {
        key: 'actual_valid',
        render: (item) => renderValidity(item, 'actual_valid'),
        label: 'Actual Validity'
    }, {
        key: 'movement',
        label: 'Movement',
        render: (item) => renderMovement(item, 'movement'),
    }];

    return {
        columns, 
        rows,
    };
}

export function dataCollection ({ data = [] }) {
    const rows = [];
    const columns = [];
    const [ first ] = data;

    if(first) {
        keys(first).forEach(k => {
            columns.push({
                key: k,
            })
        })
    }

    if (data) {
        each(data, (v) => {
            rows.push(v);
        });
    }
    
    return {
        columns, 
        rows,
    };
}

export function dataLinks ({ data = {} }) {
    const rows = [];
    const columns = [];

    each(data, (v, k) => {
        rows.push(v);
    });

    const [ first ] = rows;
    keys(first).forEach(k => {
        columns.push({
            key: k,
        })
    })
    
    return {
        columns, 
        rows,
    };
}

export function dataFactor ({ data = {}}) {
    const { 
        dimensions = {}, 
        hashes = {}, 
        def = {},
    } = data;

    const rows = [];
    const columns = [];
    let count = 0;

    if(dimensions) {
        each(dimensions, (dimension, k) => {
            count = count + 1;

            columns.push({
                key: 'input_' + count,
                label: `Input (${count}D)`,
                type: 'string',
                def: 0,
                render: (item) => {
                    return (<button className="button small no-hover version">{`{{ ${item[`input_${count}`]} }}`}</button>);
                }
            });

            columns.push({
                key: 'value_' + count,
                label: `Value (${count}D)`,
                type: 'string',
                def: 0,
            });

            columns.push({
                key: 'value_to_' + count,
                label: `Value To (${count}D)`,
                type: 'string',
                def: 0,
            });

        });
    }

    columns.push({
        key: 'weight',
        label: 'result',
        type: 'decimal',
        def: def['weight'],
    });

    columns.push({
        key: 'exclude',
        label: 'exclude',
        type: 'boolean',
        def: def['exclude'],
        render: (item) => {
            if (item.exclude) {
                return (
                    <button className="button small no-hover valid">
                        <i className="fa fa-circle-check"></i> TRUE
                    </button>
                )
            } else {
                return (
                    <button className="button small no-hover invalid">
                        <i className="fa fa-circle-xmark"></i> FALSE
                    </button>
                )
            }
        }
    });

    if (!isUndefined(def['excess'])) {
        columns.push({
            key: 'excess',
            label: 'excess',
            type: 'decimal',
            def: def['excess'],
            render: (item) => {
                return (<button className="button small no-hover version">{item.excess}</button>)
            }
        });
    }

    if (!isUndefined(def['endorsement'])) {
        columns.push({
            key: 'endorsement',
            label: 'endorsement',
            type: 'string',
            def: def['endorsement'],
            render: (item) => {
                return (<button className="button small no-hover version">{item.endorsement}</button>)
            }
        });
    }

    if (!isUndefined(def['refer'])) {
        columns.push({
            key: 'refer',
            label: 'refer',
            type: 'boolean',
            def: def['refer'],
            render: (item) => {
                if (item.refer) {
                    return (<button className="button small no-hover valid"><i className="fa fa-circle-check"></i> TRUE</button>)
                } else {
                    return (<button className="button small no-hover invalid"><i className="fa fa-circle-xmark"></i> FALSE</button>)
                }
            }
        });
    }

    function get_value_to ({
        dimensions,
        dimension,
        value,
    }) {
        const value_index = indexOf(get(dimensions, `${dimension}.value`, []), value);
        const value_to = get(dimensions, `${dimension}.value_to`, [])[value_index];
        return isUndefined(value_to) ? null : value_to;
    }

    if (hashes) {
        let row_count = 0;
        each(hashes, (hash, k) => {
            const [value_1, value_2, value_3] = k.split('::');
            const [input_1, input_2, input_3] = keys(dimensions);
            const value_to_1 = get_value_to({ dimensions, dimension: input_1, value: value_1});

            let obj = { 
                input_1,
                value_1,
                value_to_1, 
            };

            if (value_2) obj = { 
                ...obj, 
                input_2,
                value_2,
                value_to_2: get_value_to({ dimensions, dimension: input_2, value: value_2 }),
            };

            if (value_3) obj = { 
                ...obj, 
                input_3,
                value_3 ,
                value_to_3: get_value_to({ dimensions, dimension: input_3, value: value_3 }),
            };

            obj = {
                ...obj,
                ...hash,
            };

            rows[row_count] = obj;
            row_count = row_count + 1;
        });
    }

    return {
        columns, 
        rows,
    };
}

export function dataDefault ({ data = [] }) {
    return data;
}

export function formatCell(cell) {
    if (isUndefined(cell)) cell = '';
    if (cell === null) cell = '';
    cell = isString(cell) ? cell : cell.toString()
    if (cell === 'true') cell = 'TRUE';
    if (cell === 'false') cell = 'FALSE';
    return cell;
}

export class VirtualTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters : {}
        }
        this.table = React.createRef();
        this.renderCell = this.renderCell.bind(this);
        this.setTableDimensions = this.setTableDimensions.bind(this);
        this.filter = this.filter.bind(this);
        this.filter_rows = this.filter_rows.bind(this);
    }

    componentDidMount() {
        this.setTableDimensions();
    }

    async shouldComponentUpdate(nextProps) {
        // Stops the component re-rendering too much
        if (nextProps.data.length !== this.props.data.length) {
            await this.setTableDimensions();
        }
        return true;
    }

    setTableDimensions() {
        const { type, data, height, minWidth = 200  } = this.props;
        const { dataMap } = mapMethods(type);
        const { columns, rows } = dataMap({ data });
        const total_row_height = (rows.length * 35) + 20;
        let new_height = height || window.innerHeight - 360;
        if (total_row_height < new_height) new_height = total_row_height;
        const width = this.table.current.offsetWidth - 36;
        const columnWidth = max([(columns.length ? (width / columns.length) : minWidth), minWidth]);
        const maxWidth = max([width, columnWidth * (columns.length || 1) ]);
        this.setState({ 
            columns,
            rows,
            height: new_height,
            width,
            columnWidth,
            maxWidth
        });
    }

    renderCell({ columnIndex, rowIndex, style }) {
        const { columns = [] } = this.state;
        const { clickRow } = this.props;
        const column = columns[columnIndex] || {};
        const { key, render, align = 'left' } = column;
        const filtered_rows = this.filter_rows();
        const item = filtered_rows[rowIndex];
        const cell = filtered_rows[rowIndex][key];
        return (
            <div 
                style={{ ...style, align }} 
                className={`${isUndefined(clickRow) ? '' : 'clickable'} cell ${rowIndex % 2 ? 'alt' : ''}`}
                onClick={() => clickRow ? clickRow(rowIndex) : false }
            >
                {!render && formatCell(cell)}
                {render && render(item)}
            </div>
        )
    }

    filter({ value, column }) {
        const { filters } = this.state;
        this.setState({
            filters: {
                ...filters,
                [column]: value,
            }
        })
    }


    filter_rows() {
        const { rows = [], filters = {} } = this.state;
        let result = rows;
        each(filters, (value, column) => {
            result = result.filter(r => {
                let haystack = !isUndefined(r[column]) ? r[column] : '';
                let needle = !isUndefined(value) ? value : '';
                haystack = formatCell(haystack).toLowerCase();
                needle = needle.toLowerCase();
                return haystack.includes(needle)
            });
        });
        return result;
    }

    render() {
        const {
            columns = [], 
            height = 0, 
            width = 0,
            columnWidth = 0,
            maxWidth = 0,
        } = this.state;

        const { xScroll = 'scroll' } = this.props;

        const tableStyle = width ? {
            width,
            overflowX: xScroll,
            maxWidth,
        } : {};

        const rows = this.filter_rows();

        return (
            <div ref={this.table} className={`virtualise-table`} style={tableStyle}>
                <div className="virtualise-table-header" style={{width: maxWidth}}>
                    {columns.map((c) => {
                        const { key, label, align = 'left' } = c;
                        const text = label || key;
                        return (
                            <div key={key} style={{width: columnWidth, position: 'relative', textAlign: align}} >
                                <span>{text}</span>
                                <input 
                                    type="text" 
                                    placeholder="Filter term"
                                    onChange={(e) => {
                                        const { value = '' } = e.target;
                                        this.filter({
                                            value,
                                            column: key,
                                        })
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
                <div>
                    {rows.length > 0 &&
                        <Grid
                            columnCount={columns.length}
                            columnWidth={columnWidth}
                            height={height - 20}
                            rowCount={rows.length}
                            rowHeight={35}
                            width={maxWidth + 20}
                        >
                            {this.renderCell}
                        </Grid>
                    }
                </div>
            </div>
        );
    }
}