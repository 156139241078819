import React, { Component } from 'react';

import WithData from './WithData';

class DataLinks extends Component {
    render() {
        const { 
            step = {}, 
            is_approved,
            errors,
            inputs,
        } = this.props;
        return (
            <WithData
                key={'with-data'}
                step={step}
                updateStep={this.props.updateStep}
                is_approved={is_approved}
                inputs={inputs}
                errors={errors}
            />
        ) 
    }
}

export default DataLinks;