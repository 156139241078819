import React, { Component } from 'react';
import FormatEdit from '../Shared/Format/Format';

import { stepTransformSchema } from '@swa_llow/pricing_engine';
import { keys } from 'lodash';
import { DownloadButton } from '../../../../utils/download';
import { UploadArea } from '../../../../utils/upload';

class TransformEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upload: false,
        }
        this.uploadedTransform = this.uploadedTransform.bind(this);
    }

    uploadedTransform(results) {
        const { step } = this.props;
        this.setState({ upload: false });
        this.props.updateStep({
            ...step,
            format: results,
        });
    }

    render() {
        const { 
            selected_item_id,
            step,
            inputs,
            is_approved,
            errors,
        } = this.props;

        const { upload } = this.state;

        const { format = {} } = step;
        const count = keys(format).length;

        return (
            <div>
                <div className="inputs-upload">
                    <div key={`full-buttons`} className="upload-buttons">
                        <button className="button small grey no-hover mr">Count : {count}</button>
                        <DownloadButton 
                            data={{
                                format,
                            }}
                            label={'Transforms'}
                            file_name={`${step.key}.csv`}
                            type={'format'}
                            className={'mr'}
                        />
                        {!is_approved && <button className="button small secondary" onClick={() => this.setState({ upload: !this.state.upload })}>
                            <i className="fa fa-angles-up"></i>Upload Inputs
                        </button>}
                    </div>
                    {upload &&
                        <div className="exclusions-edit">
                            <UploadArea
                                complete={this.uploadedTransform}
                                type='format' 
                            />
                        </div>
                    }
                </div>
                <FormatEdit
                    schema={stepTransformSchema}
                    errors={errors}
                    set_error={this.props.set_error}
                    updateStep={this.props.updateStep}
                    step={step}
                    inputs={inputs}
                    header={'Transforms'}
                    description={'This section enables you to transform properties from the previous steps or to define new properties.'}
                    tag={'Transform'}
                    is_approved={is_approved}
                    selected_item_id={selected_item_id}
                />
            </div>
        );
    }
}

export default TransformEdit;