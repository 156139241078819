import React, { Component } from 'react';
import slugify from '../../../../../utils/slugify';
import DragList from './DragList';

import { evaluateExpressions } from '../Expression/ExpressionTextarea';
import ExpressionTest from '../Expression/ExpressionTest';

import Input from '../Forms/Input';
import Select from '../Forms/Select';
import Expression from '../Forms/Expression';

import { validate } from '../../../../../utils/validate_project';

import { DownloadButton } from '../../../../../utils/download';
import { UploadArea } from '../../../../../utils/upload';

import { each, omit, isUndefined, find } from 'lodash';

class FormatEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            upload: false,
        }
        this.updateFormats = this.updateFormats.bind(this);
        this.selectFormat = this.selectFormat.bind(this);
        this.validate = this.validate.bind(this);
        this.updateFormat = this.updateFormat.bind(this);
        this.clearFormat = this.clearFormat.bind(this);
        this.updateFormatFromTable = this.updateFormatFromTable.bind(this);
        this.updateDefaultFromExpression = this.updateDefaultFromExpression.bind(this);
        this.uploadFormat = this.uploadFormat.bind(this);
        this.topForm = React.createRef();
    }

    map_formats(format = {}) {
        let arr = [];
        each(format, (v, k) => {
            arr = [...arr, {
                key: k,
                ...v,
            }];
        });
        return arr;
    }

    componentDidMount() {
        const { selected_item_id, step = {} } = this.props;
        if (selected_item_id) {
            const { format = {} } = step;
            const selected = format[selected_item_id];
            if (selected) {
                this.selectFormat(selected)
            }
        }
    }

    validate(data = {}) {
        let { step, inputs } = this.props;
        let { collection } = step;
        this.setState({ errors: [] })

        const result = validate({
            step: {
                ...step,
                ...data,
            }
        });

        if (!result.valid) {
            this.setState({ errors: result.errors });
            return false;
        }

        if(!inputs) return true;

        if (data.format) {
            const items = this.map_formats(data.format);
            
            let exp_errors = evaluateExpressions({
                inputs,
                collection,
                items,
            }).filter(exp => exp.invalid);

            this.setState({
                errors: exp_errors.map(e => {
                    return {
                        type: 'step',
                        key: data.key,
                        path: `format.${data.key}.exp`,
                        message: e.message,
                        keyword: 'expression'
                    }
                })
            });
            if (exp_errors.length > 0) return false;
        }

        return true;
    }

    updateFormat (data) {
        const { selected = {} } = this.state;

        let new_data = {
            ...selected,
            ...data,
        }

        this.setState({
            selected: new_data
        });
    }

    updateDefaultFromExpression(result) {
        let { selected } = this.state;

        if (isUndefined(selected.def)) {
            this.setState({
                selected: {
                    ...selected,
                    def: result,
                }
            });
        }
    }

    clearFormat () {
        this.setState({
            selected: null,
            test: null,
        });
    }

    selectFormat (selected) {
        this.setState({
            selected,
            test: null,
        }, () => {
            const { step } = this.props;
            if (step.step !== 'output') {
                window.scrollTo({
                    top: 140,
                    behavior: 'smooth',
                });
            }
        });
    }

    async updateFormats() {
        const { step, updateStep, inputs } = this.props;
        const { format, collection } = step;

        let { selected = {} } = this.state;

        // Auto creates a def from the result of the expression
        if (isUndefined(selected.def)) {
            const [ result ] = evaluateExpressions({
                inputs,
                collection,
                items: [selected],
            });
            if (!result.invalid) {
                selected = {
                    ...selected,
                    def: result.result,
                }
            }
        }

        let new_format = {
            ...format,
            [selected.key]: {
                ...selected,
            }
        };

        const payload = {
            ...step,
            retain: true,
            format: new_format
        }

        if(!this.validate(payload)) return;
        await updateStep(payload);
        this.clearFormat();
    }

    updateFormatFromTable({ items = [] }) {
        const format = {};
        const { step } = this.props;

        items.forEach(ni => {
            format[ni.key] = omit(ni, ['key', 'id']);
        });

        this.props.updateStep({
            ...step,
            format,
        });
    }


    async uploadFormat(results) {
        const { step } = this.props;
        const payload = {
            ...step,
            retain: true,
            format: results,
        };
        console.log({ payload })
        if(!this.validate(payload)) return;
        this.setState({ upload: false });
        await this.props.updateStep(payload);
    }

    render() {
        const { 
            step,
            inputs,
            header,
            description,
            tag = 'Format',
            is_approved = false,
        } = this.props;

        const { format, collection } = step;

        const { selected, errors, upload = false } = this.state;

        let {
            id = '',
            label = '',
            exp = '',
            def = '',
            key = '',
            type = '',
        } = selected || {};

        const items = this.map_formats(format);

        return (
            <div ref={this.topForm}>
                {(upload) &&
                    <div className="exclusions-edit">
                        <UploadArea 
                            complete={this.uploadFormat}
                            type='format'
                            errors={errors}
                        />
                    </div>
                }
                {selected && 
                    <div key={`${id}-edit`} className="exclusions-edit">
                        {header && <h4>{header}</h4>}
                        {description && <p>{description}</p>}
                        <div className="table-control-panel">
                            <div className="table-control-panel-left"></div>
                            {!is_approved &&
                                <div className="table-control-panel-right">
                                    <button className="button grey small mr"
                                        onClick={this.clearFormat}
                                    > Clear {tag}</button>
                                    <button className="button secondary small" 
                                        onClick={this.updateFormats}
                                    > Save {tag}</button>
                                </div>
                            }
                        </div>

                        <div className="edit-columns">
                            <div className="edit-column">
                                <form>
                                    <Input
                                        label={`${tag} Label`}
                                        disabled={false}
                                        value={label}
                                        errors={find(errors, e => e.path.includes('.label'))}
                                        updateStep={(value) => {
                                            let polyfill = { label: value };
                                            this.updateFormat(polyfill);
                                        }}
                                        updateBlur={(value) => {
                                            let polyfill = { label: value };
                                            if (!key) polyfill.key = slugify(value);
                                            if (!inputs) polyfill.exp = `{{${polyfill.key}}}`;
                                            this.updateFormat(polyfill);
                                        }}
                                    />

                                    <Input 
                                        label={`${tag} Key`}
                                        disabled={false}
                                        value={key}
                                        errors={find(errors, e => e.path.includes('.key'))}
                                        updateStep={(value) => {
                                            this.updateFormat({
                                                key: slugify(value),
                                            });
                                        }}
                                    />

                                    <Select 
                                        label={`${tag} Data Type`}
                                        disabled={false}
                                        value={type}
                                        errors={find(errors, e => e.path.includes('.type'))}
                                        options={[{
                                            value: 'boolean',
                                            text: 'Boolean',
                                        },{
                                            value: 'decimal',
                                            text: 'Decimal',
                                        },{
                                            value: 'integer',
                                            text: 'Integer',
                                        },{
                                            value: 'string',
                                            text: 'String',
                                        },{
                                            value: 'date',
                                            text: 'Date',
                                        }]}
                                        updateStep={(value) => {
                                            if (value === '') return;
                                            this.updateFormat({
                                                type: value,
                                                def: null,
                                            });
                                        }}
                                    />

                                    {inputs && collection &&
                                        <Expression 
                                            label={`${tag} Query`}
                                            disabled={false}
                                            inputs={inputs} 
                                            value={exp}
                                            errors={find(errors, e => e.path.includes('.exp'))}
                                            updateStep={(value) => {
                                                this.updateFormat({
                                                    exp: value,
                                                });
                                            }}
                                        />
                                    }

                                    {type === 'boolean' &&
                                        <Select 
                                            label={`${tag} Default Value`}
                                            disabled={false}
                                            value={def}
                                            errors={find(errors, e => e.path.includes('.def'))}
                                            type={'boolean'}
                                            updateStep={(value) => {
                                                if (value === '') return;
                                                this.updateFormat({
                                                    def: value,
                                                });
                                            }}
                                        />
                                    }

                                    {type === 'string' &&
                                        <Input 
                                            label={`${tag} Default Value`}
                                            type={'text'}
                                            disabled={false}
                                            value={def}
                                            errors={find(errors, e => e.path.includes('.def'))}
                                            updateStep={(value) => {
                                                this.updateFormat({
                                                    def: value,
                                                });
                                            }}
                                        /> 
                                    }

                                    {(type === 'integer' || type=== 'decimal') &&
                                        <Input 
                                            label={`${tag} Default Value`}
                                            type={'number'}
                                            disabled={false}
                                            value={def}
                                            errors={find(errors, e => e.path.includes('.def'))}
                                            updateStep={(value) => {
                                                if (value !== '') value = Number(value);
                                                this.updateFormat({
                                                    def: value,
                                                });
                                            }}
                                        /> 
                                    }

                                    {type === 'date' &&
                                        <Input 
                                            label={`${tag} Default Value`}
                                            type={'date'}
                                            disabled={false}
                                            value={def}
                                            errors={find(errors, e => e.path.includes('.def'))}
                                            updateStep={(value) => {
                                                this.updateFormat({
                                                    def: value,
                                                });
                                            }}
                                        /> 
                                    }

                                </form>
                            </div>
                        </div>
                        {exp !== '' && inputs && collection &&
                            <ExpressionTest 
                                inputs={inputs}
                                collection={collection}
                                expression={selected}
                                updateDefaultFromExpression={this.updateDefaultFromExpression}
                            />
                        }
                    </div>
                }

                <div className="exclusions-table">
                    <DragList 
                        placeholder={tag}
                        headers={[
                            `${tag}`,
                            'Type',
                            'Default',
                            '',
                        ]}
                        items={items}
                        updateComponent={this.updateFormatFromTable}
                        selectItem={this.selectFormat}
                        is_approved={is_approved}
                    />
                    {!selected &&
                        <div className="exclusions-table-buttons">
                            <button className="button small main mr" onClick={() => this.setState({ upload: !upload})}><i className="fa fa-angles-up"></i>Upload</button>
                            <DownloadButton 
                                data={{
                                    format,
                                }}
                                label={'Format'}
                                file_name={`${step.key}_formats.csv`}
                                type={'format'}
                                className={'mr'}
                            />
                            <button className="button small main" onClick={() => this.setState({ selected: {}})}>Create</button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default FormatEdit;