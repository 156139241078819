import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const versions = new BaseActions({
    path: `${base.root}/sw/projects/versions`,
    type: 'SWALLOW_VERSIONS',
});

export default versions;