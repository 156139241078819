import React, { Component } from 'react';
import { each, isString, has, isBoolean } from 'lodash';
import moment from 'moment';

function map_result(result) {
    let arr = [];
    each(result, (v, k) => {
        if(k === 'result') return;
        if(k === 'valid') return;
        arr.push({
            key: k,
            value: v,
        })
    });
    return arr;
}

function capitialise(str = '') {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_logs_open: false,
            open_excesses: true,
            open_exclusions: true,
            open_refers: true,
            open_endorsements: true,
            open_additional: true,
        }
        this.renderSubResults = this.renderSubResults.bind(this);
        this.renderAdditionalOutputs = this.renderAdditionalOutputs.bind(this);
    }

    renderAdditionalOutputs ({ data = [] }) {
        return (
            <ul className={`run-result-items additional-outputs`}>
                <li className="run-result-item-header">
                    Additional Outputs 
                </li>
                {data.map(d => {
                    return (
                        <li key={d.key}>
                            <span>{capitialise(d.key)}</span>
                            <span>{d.value}</span>
                        </li>
                    );
                })}
            </ul>
        )
    }

    renderSubResults ({ data = [], type }) {
        const key = type.toLowerCase();
        const state = this.state[`open_${key}`];

        const new_data = data.map(d => {
            let result = d.result;

            if (isBoolean(result)) {
                result = result.toString().toUpperCase();
            } else if (!isString(result)) {
                result = result.toString();
            } else if (moment(result).isValid()) {
                result = moment(result).format('DD/MM/YYYY');
            } else {}

            return {
                step: d.step,
                result,
                name: d.name,
                value: d.value,
            }
        });

        return (
            <ul key={key} className={`run-result-items ${state ? '' : 'close'} ${data.length ? '' : 'zero'}`} onClick={() => {
                this.setState({
                    [`open_${key}`]: !state,
                })
            }}>
                <li className="run-result-item-header">
                    {state && <i className="fa fa-circle-chevron-down"></i>}
                    {!state && <i className="fa fa-circle-chevron-up"></i>}
                    {type}
                    {key === 'exclusions' && <span className="warn_count">{ data.length }</span>}
                </li>

                {(key === 'endorsements' || key === 'excesses') && new_data.map((d, i) => {
                    return (
                        <li key={`${key}-${d.name}-${d.step}-${i}`}>
                            {d.value || d.result} in {d.step} {d.name ? `(${d.name})` : ''}
                        </li>
                    );
                })}

                {(key === 'refers' || key === 'exclusions') && new_data.map((d, i) => {
                    return (
                        <li key={`${key}-${d.name}-${d.step}-${i}`}>
                            {d.name} in {d.step}
                        </li>
                    );
                })}
                <div className="list-divide"></div>
            </ul>
        )
    }

    render() {
        const {
            quote,
        } = this.props;

        const {
            output:result = {},
            exclusions = [],
            excesses = [],
            refers = [],
            endorsements = [],
        } = quote;

        const additional_outputs = map_result(result);

        const has_additional_outputs = additional_outputs.length > 0;
        const has_exclusions = exclusions.length > 0;
        const has_excesses = excesses.length > 0;
        const has_endorsements = endorsements.length > 0;
        const has_refers = refers.length > 0;

        return(
            <div className="run-area">
                <div className="run-area-top">
                    <div className="run-result">
                        <h6>CALCULATED RESULT</h6>
                        <h4>{result.result || '-'}</h4>
                        {has(result, 'valid') && result.valid && <div className="button small valid no-hover">VALID</div>}
                        {has(result, 'valid') && !result.valid && <div className="button small invalid no-hover">INVALID</div>}
                        {!result.result && <p className='no-quote'>Enter data and click "Run Quote" for your results.</p>}
                    </div>

                    {has_additional_outputs && this.renderAdditionalOutputs({
                        data: additional_outputs,
                        type: 'Additional Outputs',
                    })}
                </div>
                
                {has_exclusions && this.renderSubResults({
                    data: exclusions,
                    type: 'EXCLUSIONS',
                })}

                {has_excesses && this.renderSubResults({
                    data: excesses,
                    type: 'EXCESSES',
                })}

                {has_endorsements && this.renderSubResults({
                    data: endorsements,
                    type: 'ENDORSEMENTS',
                })}

                {has_refers && this.renderSubResults({
                    data: refers,
                    type: 'REFERS',
                })}

                <div className="run-area-footer">
                    <button className="button tertiary" onClick={this.props.clearQuote}>Back to quote</button>
                </div>

            </div>
        )
    }
}

export default Result;