import React, { Component } from 'react';
import BooleanSelect from './BooleanSelect';

class Select extends Component {
    render() {
        let { 
            label = 'Step Name', 
            value = '',
            errors = null,
            disabled = false,
            options = [],
            updateStep = (() => {}),
            type = 'normal'
        } = this.props;
        const { message } = errors || {};

        const normal_select = () => {
            return (
                <select 
                    value={value} 
                    onChange={(e) => {
                        updateStep(e.target.value)
                    }}
                    disabled={disabled}
                >
                    <option value={''}></option>
                    {options.map(op => {
                        return ( <option value={op.value}>{op.text}</option>)
                    })}
                </select>
            )
        }

        const boolean_select = () => {
            return (
                <BooleanSelect 
                    value={value}
                    disabled={disabled}
                    update={(data = {}) => {
                        const { value } = data;
                        updateStep(value);
                    }}
                />
            )
        }

        return (
            <fieldset className={`${message ? 'error' : ''} ${disabled ? 'disabled' : ''} spacing`}>
                <label>{label}</label>
                {type === 'normal' && normal_select()}
                {type === 'boolean' && boolean_select()}
                {message && <p className='error-message'>{message}</p>}
            </fieldset>
        );
    }
}

export default Select;