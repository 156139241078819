import React, { Component } from 'react';
import projects_hoc from '../../hoc/projects';

import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import ChangeLog from '../../components/Review/ChangeLog';
import ProjectMiniContainer from '../../components/Project/ProjectMiniContainer';
import GlobalLoading from '../../../../components/GlobalLoading';

import { getId } from '../../../../utils/url';

class Project extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_create_open: false,
            expand: false,
        }
        this.createVersion = this.createVersion.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
    }

    async UNSAFE_componentWillMount() {
        // Clear current version details
        await this.props.swallow_versions_reset();
        await this.props.swallow_process_reset();
        await this.props.swallow_tests_reset();

        const id = getId(this.props.history);
        await this.props.swallow_projects_get({ id });
        const { selected:project = {} } = this.props.swallow_projects;
        await this.props.swallow_versions_get_all({ project_reference: project.project_reference });
    }

    async createVersion({ version_reference }) {
        const { selected:project = {} } = this.props.swallow_projects;

        let data  = {
            project_reference: project.project_reference,
        };

        if (version_reference) {
            data = {
                ...data,
                version_reference,
            }
        }

        // New Version
        await this.props.swallow_versions_post({
            data,
        });

        // cleans version state
        const { selected:version } = this.props.swallow_versions;
        await this.props.swallow_versions_get({
            id: version.version_reference,
        });
    }

    async deleteProject() {
        const { selected: project = {} } = this.props.swallow_projects;

        // New Version
        await this.props.swallow_projects_delete({
            id: project.project_reference,
        });

        this.props.history.push('/projects');
    }

    render() {
        const { selected: project = {}, isFetching: isFetchingProjects } = this.props.swallow_projects;
        let { data: versions = [], isFetching: isFetchingVersions } = this.props.swallow_versions;

        const has_draft = versions.filter(v => v.status === 'draft').length > 0;
        versions = sortBy(versions, v => -v.version);

        const isFetching = (isFetchingProjects || isFetchingVersions);

        return (
            <div className="swallow_app slide-left">
                <TopNav history={this.props.history} project={project} auth={this.props.auth} account_type={this.props.account_type} />

                {isFetching && <GlobalLoading /> }

                {!isFetching &&
                    <section className="main-content">
                        <div className="main-content-nav">
                            <div className="main-content-nav-left">
                                <Link className="button white" to={'/home'}>&lt; Back to Projects</Link>
                            </div>
                            <div className="main-content-nav-right" >
                                <button onClick={this.deleteProject} className="button big invalid mr">
                                    Delete Project
                                </button>
                                {!has_draft && <button onClick={this.createVersion} className="button big main">
                                    Create New Version
                                </button>}
                            </div>
                        </div>

                        <ProjectMiniContainer
                            project={project}
                            is_versions={false}
                            history={this.props.history}
                        />

                        {/*
                        <div>
                            <div className="project mini">
                                <div className="project-box">
                                    <ProjectGantt versions={versions} />
                                </div>
                            </div>
                        </div>
                        */}

                        <div className="main-content-nav">
                            <div className="main-content-nav-left">
                                <h2 className="version-history-header">Version History</h2>
                            </div>
                            <div className="main-content-nav-right">
                                <button onClick={() => this.setState({ expand: !this.state.expand })}style={{margin: 10 }} className="button main">{this.state.expand ? 'Collapse' : 'Expand'} All</button>
                            </div>
                        </div>

                        {versions.map(v => {
                            return (<ChangeLog version={v} expand={this.state.expand} createVersion={this.createVersion} versions={versions} />);
                        })}

                    </section>
                }

                <Footer history={this.props.history} />
            </div>
        );
    }
}

export default projects_hoc(Project);