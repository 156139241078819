import React, { Component } from 'react';
import versions_hoc from '../../hoc/versions';

import TryArea from '../../components/Try/TryArea';

import { getQuery } from '../../../../utils/url';
import { each } from 'lodash';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import { evaluateLists } from '@swa_llow/pricing_engine';

function map_formats(formats = {}) {
    let arr = [];
    each(formats, (v, k) => {
        arr = [...arr, {
            key: k,
            ...v,
        }]
    });
    return arr;
}

class VersionTry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: [],
            isFetching: false,
        }
    }

    async componentDidMount() {
        const { version = {}, process } = this.props;
        const { input } = version;
        const section = getQuery(this.props.history, 'section');
        const lists = evaluateLists(version);
        this.setState({
            process,
            version,
            section,
            inputs: map_formats(input.format),
            lists,
        });
    }

    render() {

        const { version = {}, process, auth = {}, read_only, account_type, history  } = this.props;
        const { input } = version;
        const inputs = map_formats(input.format);
        const section = getQuery(this.props.history, 'section');
        const lists = evaluateLists(version);

        const { isFetching: isFetchingVersion } = this.props.swallow_versions;
        const { isFetching: isFetchingProcess } = this.props.swallow_process;

        const isFetching = (isFetchingVersion || isFetchingProcess);

        return (
            <div className="swallow_app try-form">
                { !isFetching &&
                    <TopNav 
                        history={history} 
                        version={process.version} 
                        auth={auth} 
                        account_type={account_type} 
                        read_only={read_only}
                        show_url={false}
                    />
                }

                { !isFetching &&
                    <div style={{marginTop: 30}}>
                        <TryArea 
                            process={process}
                            project={version}
                            inputs={inputs}
                            section={section}
                            lists={lists}
                            auth={auth.selected}
                        />
                    </div>
                }

                <Footer history={this.props.history} />
            </div>
        );
    }
}

export default versions_hoc(VersionTry);