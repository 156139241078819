import React, { Component } from 'react';
import { extendedDateFormat } from '../../../utils/format'

import {
    Link,
} from 'react-router-dom';

class UsersTable extends Component {
    render() {
        const { data = [] } = this.props;
        return (
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Pool</th>
                        <th>Validated</th>
                        <th>Created</th>
                        <th className="wf-btn-cell"></th>
                        <th className="wf-btn-cell"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((d, i) => {
                        return (
                            <tr key={`users-${i}`}>
                                <td>{d.email}</td>
                                <td>{d.pool_reference}</td>
                                <td>{d.last_token_check_at ? extendedDateFormat(d.last_token_check_at) : ''}</td>
                                <td>{extendedDateFormat(d.created_at)}</td>
                                <td className="wf-btn-cell">
                                    {!d.is_enabled && <div className="wf-invalid"></div>}
                                </td>
                                <td className="wf-btn-cell">
                                    <Link to={`/users/${d.user_reference}`}><button className="wf-btn-sm">View</button></Link>
                                </td>
                            </tr>
                        )
                    })}

                    {data.length === 0 &&
                        <tr key={`users`}>
                            <td colSpan={5}>No Users</td>
                        </tr>
                    }
                    
                </tbody>
            </table>
        );
    }
}

export default UsersTable;
