const env = (() => {
    const url = window.location.href;
    if (url.includes('localhost:')) return 'dev';
    if (url.includes('test.') || url.includes('test-')) return 'test';
    return 'production';
})();

let config = {
    dev: {
        env,
        landing: 'https://swallow.app',
        root: 'http://localhost:33333',
        allow: {
            root: 'http://localhost:3002',
            pool_reference: '098ae4f2',
            poll_timeout: 300000,

        },
        swallow: {
            url: 'http://localhost:3005',
            pool_reference: '098ae4f2',
        },
    },
    test: {
        env,
        landing: 'https://swallow.app',
        root: 'https://test.api.llow.io',
        allow: {
            root: 'https://test.a.llow.io',
            pool_reference: '098ae4f2',
            poll_timeout: 300000,
        },
        swallow: {
            url: 'https://test.swa.llow.io', 
            pool_reference: '098ae4f2',
        },
    },
    prod: {
        env,
        landing: 'https://swallow.app',
        root: 'https://api.llow.io',
        allow: {
            root: 'https://a.llow.io',
            pool_reference: '098ae4f2',
            poll_timeout: 300000,
        },
        swallow: {
            url: 'https://swa.llow.io',
            pool_reference: '098ae4f2',
        },
    } 
}

// env switch to pick right actions
let main_component = process.env.REACT_APP_MAIN;

if (!main_component) main_component = 'swallow';

let base = {
    ...config.dev[main_component],
    root: config.dev.root,
    landing: config.dev.landing,
    // need for auth logins in each service
    allow: config.dev.allow,
    env,
};

if (env === 'test') {
    base = {
        ...config.test[main_component],
        root: config.test.root,
        landing: config.test.landing,
        // need for auth logins in each service
        allow: config.test.allow,
        env,
    };
}

if (env === 'production') {
    base = {
        ...config.prod[main_component],
        root: config.prod.root,
        landing: config.prod.landing,
        // need for auth logins in each service
        allow: config.prod.allow,
        env,
    };
}

export default base;