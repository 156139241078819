import React, { Component } from 'react';

import VersionsTable from '../Project/VersionsTable';

class ChangeLog extends Component {
    render () {
        const { version, versions, expand, createVersion } = this.props;
        const { version_reference, summary } = version;
        return (
            <div key={version_reference} className="project mini project-log">
                <div className="project-box">
                    <VersionsTable items={[version]} history={this.props.history} createVersion={createVersion} versions={versions} />
                </div>
                <div className="project-box">
                    <div className="change-log">
                        {expand && <h3>Summary</h3>}
                        {expand && <div dangerouslySetInnerHTML={{__html: summary}}></div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangeLog;