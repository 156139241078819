import React, { Component } from 'react';

import { sign_out } from '../../../../auth/index';

import { Link } from 'react-router-dom';

import Logo from '../Logo';
import { isEmpty } from 'lodash';

import Badge from '../Layout/Badge';
import TryUrl from '../Layout/TryUrl';

class TopNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url : 'form',
        }
    }

    render() {
        const { history, version, project, auth = {}, account_type = '', read_only = false, show_url = true } = this.props;
        const { selected: user = {} } = auth;
        const { is_admin_super = false } = user;
        const { pathname } = history.location;

        let name, version_status, version_number, project_reference, version_reference;

        if (version) {
            name = version.name || '';
            version_number = version.version || '';
            version_status = version.status || '';
            version_reference = version.version_reference || '';
            project_reference = version.project_reference || '';
        }

        if (project) {
            name = project.name || '';
            version_number = project.version || '';
            version_status = project.status || '';
            version_reference = project.version_reference || '';
            project_reference = project.project_reference || '';
        }

        function is_active(name) {
            if (name === 'home' && pathname === '/home') return 'active';
            if (name === 'project' && pathname.includes('/project')) return 'active';
            if (name === 'publish' && pathname.includes('/version/publish')) return 'active';
            if (name === 'process' && pathname.includes('/version/process')) return 'active';
            if (name === 'data' && pathname.includes('/version/data')) return 'active';
            if (name === 'tests' && pathname.includes('/version/tests')) return 'active';
            if (name === 'settings' && pathname.includes('/project/settings')) return 'active';
            return '';
        }

        let products = [{
            name: 'Process',
            unlock: true,
            icon: 'fa fa-gear'
        }, {
            name: 'Tests',
            unlock: true,
            icon: 'fa fa-flask-vial'
        }, {
            name: 'Publish',
            unlock: true,
            icon: 'fa fa-rocket'
        }, {
            name: 'Data',
            unlock: true,
            icon: 'fa fa-server'
        }];

        if (read_only) products = products.filter(p => p.name !== 'Publish');
        const is_version = !isEmpty(this.props.version);

        return (
            <div className="top-nav">

                <div className={`top-tier ${is_version ? '' : 'nav-project'}`}>
                    <div className="logo-nav">
                        <Logo size={'v-small'} />
                    </div>
                    <ul className="links-nav">

                        <li className={is_active('projects')}>
                            <Link to={'/home'}>
                                <i className="fa fa-diagram-project"></i>
                                Projects
                            </Link>
                        </li>
                        <li>
                            <Link to={`/project/${project_reference}`}>
                                {name && <i className="fa fa-chevron-right"></i>}
                                {name}
                            </Link>
                        </li>
                        <li>
                            {this.props.version && 
                                <div className="version">
                                    {version && <i className="fa fa-chevron-right"></i>}
                                    <Badge 
                                        label={`${version_status} #${version_number}`}
                                        type={version_status}
                                    />
                                </div>
                            }
                        </li>
                    </ul>

                    <div>
                        <ul>
                            <li>
                                {is_admin_super &&
                                    <button onClick={() => {
                                        this.props.history.push('/super');
                                    }} className="button main small mr">
                                        SUPER ADMIN
                                    </button>
                                }

                                <Badge 
                                    label={`${account_type} ACCOUNT`}
                                    type={'account'}
                                    style={{ marginRight: 10 }}
                                />

                                <button onClick={() => {
                                    sign_out();
                                }} className="button small black">
                                    LOGOUT
                                </button>
                            </li>
                        </ul>
                    </div>
                    
                </div>

                {is_version &&
                    <div className="version-tier">
                        <ul className="links-nav">
                            {products.map((p, i) => {
                                const { name = '', unlock = false, icon = '' } = p;
                                return (
                                    <li key={version_reference + i} className={`${is_active(name.toLowerCase())} ${unlock ? '' : 'unlock'}`}>
                                        <Link to={
                                            unlock ? 
                                            `/version/${name.toLowerCase()}/${version_reference}` : 
                                            `/version/review/${version_reference}`
                                        }>
                                            <i className={`fa ${icon}`}></i>
                                            {name}
                                        </Link>
                                        {!unlock &&
                                            <span className="nav-unlock">
                                                <i className="fa fa-lock"></i>
                                                UNLOCK
                                            </span>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                }

                {is_version && show_url &&
                    <TryUrl 
                        version_reference={version_reference}
                        project_reference={project_reference}
                        version_number={version_number}
                        version_status={version_status}
                        history={this.props.history}
                    />
                }

            </div >
        );
    }
}

export default TopNav;