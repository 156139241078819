import React, { Component } from 'react';

import FormatEdit from '../Shared/Collection/Format';

import { DownloadButton } from '../../../../utils/download';
import { UploadArea } from '../../../../utils/upload';
import { VirtualTable } from '../../../../utils/virtualTable';

class CollectionData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: {},
            data: [],
            upload: false,
            working: false,
        }
        this.updateStep = this.updateStep.bind(this);
        this.updateFormat = this.updateFormat.bind(this);
        this.uploadCollection = this.uploadCollection.bind(this);
    }

    componentDidMount() {
        const { step } = this.props;
        this.setState({ step });
    }

    updateStep(step) {
        this.setState({
            step,
            upload: false,
        }, () => {
            this.props.updateStep(step);
        }) 
    }

    updateFormat(step) {
        this.setState({
            step,
        }, () => {
            this.props.updateStep(step);
        });
    }

    async uploadCollection(results = []) {
        const { step } = this.props;
        const { format = {} } = step;
        await this.updateStep({
            ...step,
            format,
            collection: results,
        });
    }

    render() {
        const {
            upload,
            step = {},
        } = this.state;

        const {
            inputs,
            is_approved,
            errors,
        } = this.props;

        const  { collection = [] } = step;

        return (
            <div>
                <div key={`full-buttons`} className="upload-buttons">
                    <button className="button small grey no-hover mr">rows : {collection.length}</button>
                    <DownloadButton 
                        data={{
                            data: collection,
                        }}
                        label={'Collection'}
                        file_name={`${step.key}.csv`}
                        type={'default'}
                        className={'mr'}
                    />
                    <button className="button small secondary" onClick={() => this.setState({ upload: !upload})}><i className="fa fa-angles-up"></i>Upload CSV</button>
                </div>

                {(upload  || collection.length === 0) &&
                    <UploadArea 
                        type={'collection'}
                        complete={this.uploadCollection}
                    />
                }

                {!upload && collection.length > 0 &&
                    <div className="exclusions-edit">
                        <VirtualTable
                            data={collection} 
                            type='collection'
                        />
                    </div>
                }

                {!upload && collection.length > 0 &&
                    <FormatEdit 
                        updateStep={this.updateFormat}
                        step={step}
                        inputs={inputs}
                        header={'Define Initial Properties'}
                        description={'You can query the collection to create new properties'}
                        tag={'Property'}
                        is_approved={is_approved}
                        errors={errors}
                        selected_item_id={this.props.selected_item_id}
                    />
                }
            </div>
        );
    }
}

export default CollectionData;