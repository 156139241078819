import React, { Component } from 'react';

import { getId } from '../../../../utils/url';
import config from '../../../../config/index';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import GlobalLoading from '../../../../components/GlobalLoading';
import DataList from '../../components/Data/DataList';

import { DownloadButton } from '../../utils/download';

class ProjectData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open_api: false,
        }
        this.searchQuotes = this.searchQuotes.bind(this);
        this.openAPI = this.openAPI.bind(this);
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        this.setState({ project_reference: id })
        await this.props.swallow_projects_get({ id });
        const { selected: project } = this.props.swallow_projects;
        await this.props.swallow_quotes_get_all({ project_reference: project.project_reference });
    }

    async searchQuotes(value) {
        const { selected: project } = this.props.swallow_projects;
        this.props.swallow_quotes_get_all({
            search: value,
            project_reference: project.project_reference,
        })
    }

    openAPI() {
        const { open_api } = this.state;
        this.setState({ open_api: !open_api});
    }

    render() {
        const { project_reference, open_api } = this.state;
        const { auth } = this.props;
        const { selected: project, isFetching: isFetchingProject = true } = this.props.swallow_projects;
        const { data = [], isFetching: isFetchingData = true } = this.props.swallow_quotes;

        const url = `${config.root}/sw/quotes?project_reference=${project_reference}`;
        const { client_id_key = '', client_secret_key = '' } = auth.selected || {};

        return (
            <div class="swallow_app">
                <TopNav history={this.props.history} auth={this.props.auth} account_type={this.props.account_type} project={project} />

                {(isFetchingData || isFetchingProject) && <GlobalLoading />}

                <div className="design-button-area">
                    <div className="design-button-area-left">
                        <button className="button main small grey">Count : {data.length}</button>
                    </div>
                    
                    <div className="design-button-area-right">
                        <DownloadButton 
                            data={{
                                data,
                            }}
                            label={'Data'}
                            file_name={`data.csv`}
                            type={'data'}
                            className={'mr'}
                        />
                        <button className="button main small" onClick={this.openAPI}><i className="fa fa-database"></i> Data API</button>
                    </div>
                </div>

                {open_api && 
                    <div>
                        <div className="code-block" style={{ height: 120 }}>
                            <label>Data API</label>
                            <textarea style={{ height: 50, minHeight: 'auto' }} value={`curl --request GET --url '${url}' --header 'content-type: application/json' --header 'client_secret_key: ${client_secret_key}' --header 'client_id_key: ${client_id_key}'`} />
                        </div>
                    </div>
                }

                {!(isFetchingData || isFetchingProject) && <DataList data={data} history={this.props.history} searchQuotes={this.searchQuotes} /> }

                <Footer history={this.props.history}/>

            </div>
        );
    }
}

export default ProjectData;