import React, { Component } from 'react';
import CalculationTextarea, { evaluateCalculations } from './CalculationTextarea';
import CalculationTest from './CalculationTest';

import { validate } from '../../../../../utils/validate_project';

import { isUndefined, find } from 'lodash';

class CalculationEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.validate = this.validate.bind(this);
        this.updateCalculation = this.updateCalculation.bind(this);
        this.updateDefaultFromCalculation = this.updateDefaultFromCalculation.bind(this);
    }

    validate(data) {
        let { step, inputs } = this.props;

        this.setState({ errors: [] })

        const result = validate({
            step: {
                ...step,
                ...data,
            }
        });

        if (!result.valid) {
            this.setState({ errors: result.errors });
            return false;
        }

        if(!inputs) return true;

        if (data.formula) {
            let result = evaluateCalculations({
                inputs,
                formula: data.formula,
            });
            if (result.invalid) {
                this.setState({ errors:[{
                    type: 'step',
                    key: step.key,
                    path: `${step.key}.formula`,
                    message: result.message,
                    keyword: 'calculation'
                }]});
                return false;
            }
        } else {
            if (data.formula === '') {
                return this.setState({ errors:[{
                    type: 'step',
                    key: step.key,
                    path: `${step.key}.formula`,
                    message: 'Formula should not be empty',
                    keyword: 'calculation'
                }]});
            }
        }

        return true;
    }

    updateCalculation (data) {
        const { step } = this.props;
        const new_data = {
            ...step,
            ...data,
        }
        this.validate(new_data);
        this.props.updateStep(new_data);
    }

    updateDefaultFromCalculation(result) {
        let { step } = this.props;
        const { def } = step;

        if (isUndefined(def) || def === '') {
            this.props.updateStep({
                ...step,
                def: result,
            });
        }
    }

    render() {
        const { 
            inputs,
            step = {},
        } = this.props;

        let {
            def = '',
            formula = '',
        } = step;

        let { errors = [] } = this.state;
        errors = [...errors, ...this.props.errors || []];

        const def_error = find(errors, e => e.path.includes('def'));
        const formula_error = find(errors, e => e.path.includes('formula'));

        return (
            <div key={`${step.id}-edit`}>
                <div className="edit-columns">
                    <div className="edit-column">
                        <form>
                            <fieldset className={`spacing ${formula_error ? 'error' : ''}`}>
                                <label>Formula</label>
                                <CalculationTextarea 
                                    inputs={inputs} 
                                    value={formula}
                                    updateCalculation={(value) => {
                                        this.updateCalculation({
                                            formula: value,
                                        });
                                    }}
                                />
                                {formula_error && <p className='error-message'>{formula_error.message}</p>}
                            </fieldset>
                            <fieldset className={`spacing ${def_error ? 'error' : ''}`}>
                                <label>Default Value</label>
                                <input type="number" onChange={(e) => {
                                    this.updateCalculation({
                                        def: e.target.value === '' ? '' : Number(e.target.value),
                                    })
                                }} value={def} />
                                {def_error && <p className='error-message'>{def_error.message}</p>}
                            </fieldset>
                        </form>
                    </div>
                </div>
                {formula !== '' &&
                    <CalculationTest 
                        inputs={inputs}
                        formula={formula}
                        updateDefaultFromCalculation={this.updateDefaultFromCalculation}
                    />
                }
            </div> 
        );
    }
}

export default CalculationEdit;