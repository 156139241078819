import React, { Component } from 'react';

import Inputs from '../Edit/Shared/Sidebar/Inputs';
import StepButtons from './StepButtons';

class StepInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_logs_open: false,
        }
        this.openLog = this.openLog.bind(this);
    }

    openLog() {
        this.setState({
            is_logs_open: !this.state.is_logs_open,
        });
    }

    render() {
        const {
            is_logs_open,
        } = this.state;

        const {
            id,
            editComponent,
            contents,
            hide_outputs,
            is_approved,
        } = this.props;

        const {
            is_completed,
            outputsFormatted,
            outputs,
            errors,
            timer,
            logs,
        } = contents;

        return (
            <div key={id} className="design-area-module-wrapper">
                <div className={is_completed ? "design-area-module completed" : "design-area-module"}>
                    <div className="design-area-module-left">
                        <span className="icon start"></span>
                    </div>
                    <div className="design-area-module-centre">
                        <h6 className="module-type">Input</h6>
                        <h4><span>When the</span><br/>Quote is started</h4>

                        {!hide_outputs &&
                            <Inputs
                                id={id} 
                                inputs={outputsFormatted}
                                is_output={true}
                            />
                        }

                        <StepButtons 
                            id={id}
                            outputs={outputs}
                            hide_outputs={hide_outputs}
                            logs={logs}
                            errors={errors}
                            timer={timer}
                            openLog={this.openLog}
                        />           

                    </div>
                    <div className="design-area-module-right">
                        <span className="icon edit" onClick={() => editComponent('input')}></span>
                    </div>
                </div>
                {logs && is_logs_open && 
                    <div className="show-logs-area">
                        <pre>
                            <code>
                                {JSON.stringify(logs, null, 4)}
                            </code>
                        </pre>
                    </div>
                }
                <div className="connector">
                    <div className="straight-connector"></div>
                    {!is_approved &&<div className="node-connector" onClick={() => this.props.addComponent(0)}>
                        <i className="fa fa-circle_plus"></i>
                    </div>}
                </div>
            </div>
        )
    }
}

export default StepInput;