import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const projects = new BaseActions({
    path: `${base.root}/sw/projects`,
    type: 'SWALLOW_PROJECTS',
});

export default projects;