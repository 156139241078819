import React, { Component } from 'react';
import ControlsBoxProgress from './ControlsBoxProgress';

class ControlsBox extends Component {

    show_buttons({ 
            rules = {}, 
            tasks = [], 
            user = {}, 
            team = [], 
            version_status = '',
            account_type,
            viewer_roles,
        }) {

        // For Approvals
        let show_approval = false;

        const { number_of_approvals = 1, tasks_completed = false } = rules;
        const pass_approvals = team.filter(a => a.approval).length >= number_of_approvals;
        const approval_done_tasks = tasks.filter(t => t.user_reference).length;
        const approval_tasks = !tasks_completed || approval_done_tasks === tasks.length;
        const approval_user = (viewer_roles || []).includes('approve') || user.is_admin;

        if (account_type === 'free') {
            show_approval = approval_user && version_status === 'draft' && pass_approvals;
        } else {
            show_approval = approval_tasks && pass_approvals && approval_user && version_status === 'draft';
        }

        // For Delete
        const delete_user = (viewer_roles || []).includes('write') || user.is_admin;
        let show_delete = delete_user && version_status === 'draft';

        // For Publish
        const publish_user = (viewer_roles || []).includes('approve') || (viewer_roles || []).includes('publish') || user.is_admin;
        let show_publish = publish_user && version_status === 'approved';

        return {
            show_approval,
            show_delete,
            show_publish,
        }
    }

    render() {
        const { 
            page = 'publish',
            account_type,
            viewer_roles,
            process = {},
        } = this.props;

        const {
            version_reference, 
            status: version_status,
            version: version_number,
            project_reference, 
        } = process.version;

        const {
            show_approval,
            show_delete,
            show_publish,
        }  = this.show_buttons({
            ...process,
            version_status,
            account_type,
            viewer_roles,
        }) 
        
        if (page === 'publish') {
            let url = `/${version_reference ? 'version' : 'project'}/try/${project_reference}`;
            return (
                <div className="download-box">
                    <div className="download-item" onClick={() => window.open(`${url}?form=true`, '_blank')}>
                        <i className="fa fa-desktop"></i>
                        <div className="download-name"><b>Try</b> Web Form</div>
                    </div>
                    <div className="download-item" onClick={() => window.open(`${url}?api=true`, '_blank')}>
                        <i className="fa fa-desktop"></i>
                        <div className="download-name"><b>Try</b> API</div>
                    </div>
                </div>
            )
        }

        if (page === 'process') {
            return (
                <div className="download-box">
                    <div class="download-box-label">Actions</div>
                    {version_status === 'approved' && show_publish &&
                        <ControlsBoxProgress 
                            version_number={version_number}
                            label={'Publish'}
                            onClick={this.props.publish_version}
                        />
                    }

                    {version_status === 'draft' && show_approval &&
                        <ControlsBoxProgress 
                            version_number={version_number}
                            label={'Approve'}
                            onClick={this.props.approve_version}
                        />
                    }

                    {version_status === 'draft' && show_delete && 
                        <ControlsBoxProgress 
                            version_number={version_number}
                            label={'Delete'}
                            onClick={this.props.delete_version}
                        />
                    }
                </div>
            )
        }

        return (<div></div>)

    }
}

export default ControlsBox;