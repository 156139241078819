import React from 'react';
import logo from '../pages/assets/app/white-swallow.png';

const Logo = ({ size = '', click }) => {
    return (
        <div onClick={click} className={`logo-container ${size}`}>
            <h1 className="logo">
                <img alt="swallow logo" src={logo} />
                <span>Swallow</span>
            </h1>
        </div>
    );
};

export default Logo;