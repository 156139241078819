import React, { Component } from 'react';

import LoginWrapper from '../components/Login';

class LoginContainer extends Component {
    render() {
        return <LoginWrapper history={this.props.history} />
    }
}

export default LoginContainer;