import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer>
                <p>A.llow is a Llow Group Company.</p>
            </footer>
        );
    }
}

export default Footer;
