import React, { Component } from 'react';
import Table from './Table';
// import DataChart from './DataChart';

class DataList extends Component {
    constructor(props) {
        super(props);
        this.filterData = this.filterData.bind(this);
    }

    filterData(data = []) {
        let new_data = data.map(d => {
            return {
                ref: d.quote_reference,
                indicators: d.indicators.split('::'),
                result: d.result,
                valid: d.valid,
                date: d.created_at,
            }
        });
        return new_data;
    }

    render() {

        const {
            data = [],
        } = this.props;

        const filtered_data = this.filterData(data);

        return (
            <section className="main-content slide-left">

                <div className="data-area">
                    <div className="data-area-left">
                        {/*<div className="data-area-chart">
                            <DataChart 
                                version_status={version.status}
                            />
                        </div>*/}
                        <Table 
                            items={filtered_data}
                            placeholder={`Search Data`}
                            hide_checkboxes={true}
                            serverSearch={(e) => {
                                const { value } = e.target;
                                this.props.searchQuotes(value);
                            }}
                            headers={[
                                'Indicators',
                                'Result',
                                'Valid',
                                'Date',
                            ]}
                            history={this.props.history}
                        />

                        {data.length === 0 && 
                            <div className="table-wrapper" style={{marginTop: 10}}>
                                <div className="help-block" style={{ margin: 0 }}>
                                    <p>No quotes yet!. You can create a quote via the <span className="button small main" onClick={this.props.openAPI}><i className="fa fa-database"></i> Data API</span>.</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="data-area-right">
                    </div>
                </div>

            </section>
        );
    }
}

export default DataList;