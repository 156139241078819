/* eslint eqeqeq:"off" */

import React, { Component } from 'react';
import { find, get } from 'lodash';

import StepWrapper from './Step/StepWrapper';
import ActualResult from '../../components/Test/ActualResult';
import moment from 'moment';

import { DownloadButton } from '../../utils/download';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { validate } from '../../utils/validate_project';
import { UploadArea } from '../../utils/upload';

import { test_runner } from '../../utils/runner';

class DesignArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hide_outputs: true,
            is_logs_open: false,
            is_upload_open: false,
            is_running: false,
            selected_test: null,
            validation_errors: [],
        }
        this.runTest = this.runTest.bind(this);
        this.updateIsLogOpen = this.updateIsLogOpen.bind(this);
        this.hideOutputs = this.hideOutputs.bind(this);
        this.clearSelectedTest = this.clearSelectedTest.bind(this);
        this.uploadProject = this.uploadProject.bind(this);
        this.saveVersion = this.saveVersion.bind(this);
    }

    saveVersion() {
        const {
            steps = [], 
            input, 
            output, 
            version = {}, 
        } = this.props;

        this.setState({ validation_errors: [] })

        const result = validate({ 
            version : {
                ...version, 
                steps, 
                input, 
                output, 
            }
        });

        if (!result.valid) {
            console.warn({ version, errors: result.errors });
            this.setState({
                validation_errors: result.errors,
            });
        }
        
        this.props.saveVersion({ to_cloud: true });
    }

    async runTest (test_id) {
        const { tests } = this.props;
        const test = find(tests, t => t.id === test_id);
        this.setState({ is_running: true });
        const result = await test_runner({
            project: this.props.version,
            test,
            debug: true,
        });
        this.setState({ 
            selected_test: result,
            is_running: false, 
        });
    }

    renderLatestStatus ({ status = '', version_number }) {
        const map = {
            draft: 'secondary',
            approved: 'tertiary',
            published: 'tertiary',
        };
        const cn = map[status] || 'grey';
        return (<button className={`button no-hover ${cn} small`}>{status.toUpperCase()} #{version_number}</button>);
    }

    clearSelectedTest () {
        this.setState({
            hide_outputs: true,
            selected_test: null,
        })
    }

    updateIsLogOpen() {
        this.setState({
            is_logs_open: !this.state.is_logs_open,
        })
    }

    hideOutputs() {
        this.setState({
            hide_outputs: !this.state.hide_outputs,
        })
    }

    async uploadProject(project) {
        const { version } = this.props;
        const uploaded_project = {
            // We have already validated we can parse it
            ...JSON.parse(project),
            meta: version.meta,
        };
        await this.props.saveVersion({ to_cloud: true, uploaded_project })
        this.setState({ is_upload_open : false });
    }

    render() {
        const { 
            is_logs_open,
            is_running,
            hide_outputs,
            is_upload_open,
            selected_test,
            validation_errors,
        } = this.state;

        const { 
            steps = [], 
            input, 
            output, 
            process = {},
            tests = [],
            account_type,
            account_config,
            version_size,
            version_saved_at
        } = this.props;

        const {
            status = '',
        } = process.version || {};

        const is_approved = (status === 'published' || status === 'approved');

        const { version } = this.props;
        const file_name = get(version, 'meta.name', '') + '.json';

        return (
            <section className={`main-content slide-left`}>

                <div className="design-button-area">
                    <div className="design-button-area-left">
                        <button className="button small main mr" onClick={() => {
                            this.setState({ is_upload_open : !this.state.is_upload_open })
                        }}>
                            <i class="fa fa-angles-up"></i> Upload Model
                        </button>
                        <DownloadButton 
                            data={{
                                version,
                            }}
                            label={'Model'}
                            file_name={file_name}
                            type={'version'}
                        />
                    </div>
                    
                    {/*!is_approved &&
                        <div className="design-button-area-center">
                            <Video />
                        </div>
                    */}
                    
                    <div className="design-button-area-right">
                        <button className="button white no-hover small mr">Size: {version_size} KB</button>
                        <button className="button date no-hover small mr">
                            <i className="fa fa-circle-check"></i> Saved: {moment(version_saved_at).format('HH:mm')} ({moment(version_saved_at).fromNow()})
                        </button>
                        {selected_test &&
                            <button className="button white big mr" onClick={this.clearSelectedTest}>
                                Clear Test Data
                            </button>
                        }
                        {!is_approved &&
                            <button className="button secondary big" onClick={this.saveVersion}>
                                Save Project
                            </button>
                        }
                    </div>
                </div>

                <div className="design-area">

                    <div className="design-area-left" style={{ display: is_logs_open ? 'none' : 'block' }}>
                    </div>

                    <div className="design-area-center" style={{ display: is_logs_open ? 'none' : 'block' }}>
                        {is_upload_open && 
                            <div style={{ margin: '-10px 20px 20px 0px' }}>
                                <UploadArea type='version' complete={this.uploadProject} />
                            </div>
                        }

                        <StepWrapper
                            id={'input'}
                            index={0}
                            step={{
                                ...input,
                                id: 'input',
                                key: 'input',
                                step: 'input'
                            }}
                            quote={selected_test}
                            hide_outputs={hide_outputs}
                            addComponent={this.props.addComponent} 
                            updateComponent={this.props.updateComponent} 
                            editComponent={this.props.editComponent}
                            is_approved={is_approved}
                        />

                        {steps.length > 0 &&
                            <div style={{ minHeight: steps.length * (selected_test ? 150 : 115) }}>
                                <DragDropContext onDragEnd={this.props.moveComponent}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {steps.map((s, i) => {
                                                    return(
                                                        <Draggable key={s.id} draggableId={s.id} index={i}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <StepWrapper
                                                                        key={s.id} // To remove React key warning
                                                                        id={s.id}
                                                                        index={i}
                                                                        step={s}
                                                                        quote={selected_test}
                                                                        hide_outputs={hide_outputs}
                                                                        addComponent={this.props.addComponent} 
                                                                        updateComponent={this.props.updateComponent} 
                                                                        editComponent={this.props.editComponent}
                                                                        deleteComponent={this.props.deleteComponent}
                                                                        hideOutputs={this.hideOutputs}
                                                                        is_approved={is_approved}
                                                                        account_type={account_type}
                                                                        account_config={account_config}
                                                                        errors={validation_errors.filter(ve => ve.key === s.key)}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        }

                        <StepWrapper
                            id={'output'}
                            index={0}
                            step={{
                                ...output,
                                id: 'output',
                                key: 'output',
                                step: 'output'
                            }}
                            quote={selected_test}
                            hide_outputs={hide_outputs}
                            addComponent={this.props.addComponent} 
                            updateComponent={this.props.updateComponent} 
                            editComponent={this.props.editComponent}
                            is_approved={is_approved}
                        />
                    </div>

                    <div className="design-area-right">
                        {validation_errors.length > 0 &&
                            <div className="help-block error-block">
                                <p>Can not save project. There is an errors in your steps. Scroll down and expand the affected step.</p>
                            </div>
                        }
                        <div className="edit-inputs"> 
                            <form>
                                <fieldset>
                                    <label>Run Test Scenario</label>
                                    <select 
                                        value={(selected_test || {}).id || ''} 
                                        onChange={(e) => {
                                            if(e.target.value == '') return;
                                            this.runTest(e.target.value);
                                        }}>
                                        <option value={''}>Pick Test to Run</option>
                                        {tests.map(t => {
                                            return (<option key={t.id} value={t.id}>{t.name}</option>);
                                        })}
                                    </select>
                                </fieldset>
                            </form>
                            {(selected_test || is_running) &&
                                <ActualResult
                                    quote={selected_test}
                                    is_running={is_running}
                                /> 
                            }
                        </div>
                    </div>
                    
                </div>
            </section>
        )
    }
}

export default DesignArea;