import { last } from 'lodash';

export function error_response({
    error = {},
    label = 'Test',
}) {
    let error_message = `UNFORMATTED : ${JSON.stringify(error)}`;
    let path = last((error.path || '').split('.'));
    let keyword = last((error.keyword || '').split('.'));

    if (error.keyword === 'required') {
        error_message = `${label} ${path} is required`
    }
    
    if (error.keyword === 'type') {
        error_message = `${label} ${path} value is not correct type`
    }

    if (error.keyword === 'minLength') {
        error_message = `${label} ${path} must not be empty`
    }

    return {
        message: error_message,
        path,
        keyword,
    };
}