/* eslint no-throw-literal:0 */

import { get } from 'lodash';
import axios from 'axios';
import moment from 'moment';

import base from '../config/index';

// Example Session POST POST /sessions Requests
/*
    {
        "pool_reference": "098ae4f2",
        "email": "cjr+test@llow.io",
        "success_url": "https:swa.llow.io/success"
    }
*/

// Example Session POST /sessions Response
/*
    {
        "pool_reference": "098ae4f2",
        "user_reference": "30b06be6-1239-4d3f-8c19-2803c4deab02",
        "email": "cjr+test@llow.io",
        "session": "AYABeEJLrwyNRQDF8bLyvB1P_l4AHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMAS2Fybjphd3M6a21zOmV1LXdlc3QtMzozMzU4NzkyNzQwMjY6a2V5LzczYzdjZmIzLTE3NDctNGFhNS05YmVlLTc2MTA1NGZkNGI2ZAC4AQIBAHihOMDnBJj5QoO_HBuEjj0XFGjV_A4WdMqyF7xbLX6AFQGcKwYpi6ZzuhunzD8ldxukAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQM8purA4O89IElYZ0BAgEQgDtuzSVXs0JMR4Yg1Pc2ESJs_S_O9snfFlyKGTXtUp5xCCboAOqZ6qIshFN8qQWPa_zkwKclngWz__Q8sAIAAAAADAAAEAAAAAAAAAAAAAAAAAAMBfvpN5RpfRj0urzZ2U6N_____wAAAAEAAAAAAAAAAAAAAAEAAAEdnYCE4X4E1Vns64VzbuQ3bLv9_ESjivf-3J6z2CL6aN3MpYf28d5j_BeZkMc4s608X3qPUe9Wl14F7LkOFebnS6ouL41_vK7q7tVtms1uhHATCVnIorSPkgD2RanSVmSgusNGKxOex0b1FiCifQQ0P9FeVofGIpeSmmrb1Zc6_hYPWdjFTfcpERDcJ1Xx5HUWsTRPl759fvAZ1IAiGQn6ybAzV1aDoa4-OWteoyn4MJPUnXIpu8kh3q3kzt9C5HRXtzZjfwjjc9Nd-rH8t1NAS_mwBpWlaZhN-IbpfscCQkOWZeopXA59fjgu-6CcwxAuwIQ7XGkii-OBOHyCWxkUzrYlrbIhaP_wcZHgLWgLOkjwIZmRNczCOtxE5gMjAWoaXur2Eos8zLAglHfGvQ",
        "created_at": "2022-07-06T09:13:40.616Z"
    }
*/

// Example Success GET /sessions/:session Response
/*
    {
        "pool_reference": "098ae4f2",
        "user_reference": "30b06be6-1239-4d3f-8c19-2803c4deab02",
        "email": "cjr+test@llow.io",
        "session": "AYABeEJLrwyNRQDF8bLyvB1P_l4AHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMAS2Fybjphd3M6a21zOmV1LXdlc3QtMzozMzU4NzkyNzQwMjY6a2V5LzczYzdjZmIzLTE3NDctNGFhNS05YmVlLTc2MTA1NGZkNGI2ZAC4AQIBAHihOMDnBJj5QoO_HBuEjj0XFGjV_A4WdMqyF7xbLX6AFQGcKwYpi6ZzuhunzD8ldxukAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQM8purA4O89IElYZ0BAgEQgDtuzSVXs0JMR4Yg1Pc2ESJs_S_O9snfFlyKGTXtUp5xCCboAOqZ6qIshFN8qQWPa_zkwKclngWz__Q8sAIAAAAADAAAEAAAAAAAAAAAAAAAAAAMBfvpN5RpfRj0urzZ2U6N_____wAAAAEAAAAAAAAAAAAAAAEAAAEdnYCE4X4E1Vns64VzbuQ3bLv9_ESjivf-3J6z2CL6aN3MpYf28d5j_BeZkMc4s608X3qPUe9Wl14F7LkOFebnS6ouL41_vK7q7tVtms1uhHATCVnIorSPkgD2RanSVmSgusNGKxOex0b1FiCifQQ0P9FeVofGIpeSmmrb1Zc6_hYPWdjFTfcpERDcJ1Xx5HUWsTRPl759fvAZ1IAiGQn6ybAzV1aDoa4-OWteoyn4MJPUnXIpu8kh3q3kzt9C5HRXtzZjfwjjc9Nd-rH8t1NAS_mwBpWlaZhN-IbpfscCQkOWZeopXA59fjgu-6CcwxAuwIQ7XGkii-OBOHyCWxkUzrYlrbIhaP_wcZHgLWgLOkjwIZmRNczCOtxE5gMjAWoaXur2Eos8zLAglHfGvQ",
        "created_at": "2022-07-06T09:13:40.616Z",
        "refresh_token": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.ktTIQD5jcDPEiYptdMUPEdLgOLhnuni5em9i95yO3HOjBNuXqcGGV8xzV4zW3DTbppuReqhXXJQAsQmDQ2-yfbAWCQn6zsF7RoYbw3JX8c0rmw660AUl4lXQDJRs2r78yqcPoTqkgUufNrucBnqBr7_BAsHUEzf1ifuJDNbzfLDejguNxJ596mJArjyQRu7mvXCr4xMf5d34cKL0j78dFt55rcww7r8fudIc2Qlm-ngKgeyCjM0cCxJ5OWM9wvlJcro6nD3NEWaOLxuUy4DfOpiLz2FFtlbvxS5mwwNP8tSgPaFs7_om34lX0Cudj6dVz_CB684hbqOKO7Ahx_DR7w.qq-zvwz0wiP6MEqT.eZotdb2fRhgITNiWu_w0ndk8U2px_pOipCDwYtZWwrbYwUZBHO_G97JFwNW4cQy-3HCRPcjR0NPoLuD6lQyUvtV_SUmYi5duNU5kFAFHgZnh8tYYmpO7MWCCbOXr-Tk9Qy8MYXtJdzowKemgvUt2IOGPRn9_Wsac1a7KW78R203Ab2wTMJpQNlW7IdfGqdKD2w5pReV9dh8MfGTM-nihzjZr33QnGVCJR8XvAK62HACSODpJo4N6_EUlWYYlq8XmetcX5-DpU0jEKGvzjqMHPd8G8cBLEb-H-V6bpgfZZGOMB_HmZl2jN7_719-3LlM5kiAjy0zH_n78uqzL3eENUtN8anxeTSObPvsFMZ9lbNgjfPS7A4Ocnfu_Piu5Wtco6joard3qNqjMUx1e9mNapttqvtjeR2nqQv2ksZj0WpPwZL2CaOMENeWh3jTku7s9cWrxQS4kntwwG_Cyt80fEF3-GqEqTKCIraN-7bdL1R4aUOFuvdItuEkP9BfyspGgPB13q28lkAcLA_z6qYz8EGx0drZUXIq9hFAKA6DkvtwV_pWXg5JyKF23zUL_XNQAfrew9tqSK1vBvGJWX_P9WZnalKJNyXdKDrA1xfY2BmwHbYmpxzfpiq-koJIJa4-8DC1fCBbsynubeGgrFCveFnW8rH5acpDYZ-ZzMJNGixp4DWGy1uJvw0HhgW0oc_UwLuXEXIcsiJvZeAKbAquxaTf3pXjmivt9LWppVRf62HEJX6WvkFDC0kiyx2zqxpEsIqKHCQp8DKasatzc8N-BVG7l5_R42Deh7c0WJ2By7A19BR8JluRRI2zlck0AjyaCP12L3cat8-ELyf8y96YfAFQIY0XjqNDZfSnN7B7K0Q4q8mbIuJIhfegUaA7VvuZZBxOdAJuAC7-J-lgrIdOrYdmc2V11y6e9zTykjQ8DJYNjXG1-Ye5su5P0J96K2GN0ibLK7TR--M7Oz71ftHwEaPGTiaIZYEsvP4rHRgtq4G4Tce8rhkHpYLTIeFuo74NnJR806s5xqs86YNhkLK66jpAn2uD3ZTCtMwyEmG9uvArGrq1SYNSLWzftIZFBectOMv_ViCC1KqI2SQIsl96iiicD35oJQ7spdB57tzcDOrOOk87iyOZ61vplVdoN1LVANoNA-l8v3GG_Qxd6yBOOOPpx0zVnXxHZ1tnHpCafO2PqffEenr9YytZISvIL8gx2OU_a4cNTgPjZkZ7iQUgZl9JOKcoMVGs5adEmzs5WQimFC2tqOB0z4kDsHd8iN2sr2MzGC5DrnRlmJYsZ5JF_7ZQmsIhiIXd2j9SAwpyDky-RyQZbmA1s-Q63bHc.c3-luhFkfrcNIUnzPqEpNw",
        "access_token": "eyJraWQiOiJOK2RLU3VHXC9MTW52U0ZDeXBzM3VyQzRMY2VzY1NlNjJzazRCSUFcL0FNaGM9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIzOWQ4ZWU0Yi1jYzlmLTRlNjItODRjOC02NDgzYTAzMWE0MTciLCJldmVudF9pZCI6ImEzYTk0NTljLTU0NWMtNDc2Ny1hYzg0LTFjYWUwODhjMTQxYyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NTcwOTg4MjgsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS13ZXN0LTMuYW1hem9uYXdzLmNvbVwvZXUtd2VzdC0zX2VkZWdrUTE2NSIsImV4cCI6MTY1NzEwMjQyNywiaWF0IjoxNjU3MDk4ODI4LCJqdGkiOiJhM2M5YTQ4ZC1mMGYxLTRjMjktYTA4My1jN2E0NmYyZjZkMjkiLCJjbGllbnRfaWQiOiIxOTFnazkzYnZwOGdzbzI2ZnE2Ym9oNmExMSIsInVzZXJuYW1lIjoiMzBiMDZiZTYtMTIzOS00ZDNmLThjMTktMjgwM2M0ZGVhYjAyIn0.r0Wx15OZraPUhGAW1oM6STqljzW1EqK_wZRKUNPeShT8XpV5isqqXgYa76dRRRMcZnVi6EFR0OHQjXr1E0-oxJUZF4Gy_HPKemiq4mvaWAcfbQzz6jwoKyIp8aa0ZwBiBje3UAGVwuUqqMda39fT90IWhOIbJWZi-1nhyEvFYdPnihX-IiCvlngyyqtR5jt_SAAzYR6cKy3hX2qX1fjXSMSBOedaHnHD07m3pBLYhtXNZtCb9-n0FMvzlZT6fI6P8j96iylgS78wQi6Pk1XIE9OTIZxGYah9_wAq51Wnqandy_6nCcD086tKJyPt3O4o0y80L6yQJ17kNJco220iIA"
    }
*/

function create_error(error) {
    window.localStorage.allow_auth = null;
    if (error.response) {
        const { message, status } = error.response.data;
        throw {
            message: message || error.response.data,
            status: status || error.response.status,
        };
    } else if (error.request) {
        throw {
            message: 'Server Error',
            status: 500,
        }
    } else {
        throw {
            message: error.data,
        }
    }
}

export async function post_company(data) {
    const result = await axios({
        url: base.root + '/a/companys',
        method: 'post',
        data: JSON.stringify({
            ...data,
        }),
        headers: {
            'Content-Type': 'application/json',
        }, 
    }).then(({data}) => {
        return data;
    }).catch((error) => create_error(error))
    return result;
}

export async function post_session(data, pool_reference, success_url) {
    const result = await axios({
        url: base.root + '/a/sessions',
        method: 'post',
        data: JSON.stringify({
            ...data,
            // pool_reference: pool_reference || base.allow.pool_reference,
            success_url,
        }),
        headers: {
            'Content-Type': 'application/json',
        }, 
    }).then(({data}) => {
        if (data.name) set_name((data.name || []).split(' ')[0]);
        return data;
    }).catch((error) => create_error(error))
    return result;
}

export async function get_session(session) {
    const result = await axios({
        url: base.root + '/a/sessions/' + session,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        }, 
    }).then(({data}) => {
        if (data.access_token) set_auth(data);
        return data;
    }).catch((error) => create_error(error))
    return result;
}

export function set_auth (data) {
    window.localStorage.allow_auth = JSON.stringify(data);
    return data;
} 

export function set_name (data) {
    window.localStorage.allow_name = data;
    return data;
} 

export async function get_auth () {
    let store = JSON.parse(get(window, 'localStorage.allow_auth', '{}')) || {};

    // Refresh Access Token
    if (store.expiry) {
        const expired = moment() > moment(store.expiry, 'X');
        if (expired) {
            // TODO implement Refresh endpoint
            /*
            const new_store = get_remote_token({
                refresh_token: store.refresh_token,
                status: 'REFRESH',
            });
            set_auth(new_store);
            */
            return store;
        }
    }
    
    return store;
} 

export async function get_name () {
    let store = get(window, 'localStorage.allow_name', '');    
    return store;
} 

export function sign_out () {
    window.localStorage.allow_auth = null;
    window.location.reload();
} 