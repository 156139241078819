import React, { Component } from 'react';
import ExpressionTextarea from '../Expression/ExpressionTextarea';

class Expression extends Component {
    render() {
        let { 
            label = 'Step Name', 
            value = '',
            inputs = {},
            errors = null,
            disabled = false,
            updateStep = (() => {}),
        } = this.props;
        const { message } = errors || {};
        return (
            <fieldset className={`${message ? 'error' : ''} ${disabled ? 'disabled' : ''} spacing`}>
                <label>{label}</label>
                <ExpressionTextarea 
                    inputs={inputs} 
                    value={value}
                    updateExpression={(value) => {
                        updateStep(value);
                    }}
                />
                {message && <p className='error-message'>{message}</p>}
            </fieldset>
        );
    }
}

export default Expression;