import React, { Component } from 'react';

import { getId, getQuery } from '../../../../utils/url';

import Api from '../../components/Public/Api/DataApi';
import Table from '../../components/Public/Table/Data';

import { downloadSave } from '../../utils/download';

class PublicData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            section: 'data',
        }
        this.fetchQuotes = this.fetchQuotes.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        const section = getQuery(this.props.history, 'section') || 'data';
        await this.fetchQuotes();
        this.setState({ section, project_reference: id })
    }

    async fetchQuotes() {
        const id = getId(this.props.history);
        await this.props.swallow_public_quotes_get_all({ 
            project_reference: id 
        });
    }

    async downloadCSV() {
        const { data = [] } = this.props.swallow_public_quotes;
        downloadSave({ type: 'data', data: { data }, file_name: 'quote_data.csv'});
    }

    render() {

        const { isFetching, data = [] } = this.props.swallow_public_quotes;
        const { section, project_reference } = this.state;

        return (
            <div className="swallow_public">
                <div className="try_form">
                    <div className="try-container">

                        <div className={`try-container-left`}>
                            <div className="try-button-header">
                                <div className="try-button-header-left">
                                    <div className="try-toggle-buttons">
                                        <button onClick={() => this.setState({ section: 'data'})} className={`button small ${section === 'api' ? 'off' : 'secondary'}`}>DATA</button>
                                        <button onClick={() => this.setState({ section: 'api'})} className={`button small ${section === 'data' ? 'off' : 'secondary'}`}>API</button>
                                    </div>
                                </div>

                                <div className="try-button-header-right">
                                    <span className="link no-mobile" onClick={this.downloadCSV}><i className="fa fa-download"></i>DOWNLOAD CSV</span>
                                    {!isFetching && <button className="button tertiary refresh" onClick={this.fetchQuotes}> Refresh</button>}
                                    {isFetching && <button className="button tertiary refresh"><i className="fa-solid fa-spinner fa-spin"></i> Refresh</button>}
                                </div>

                            </div>

                            {!isFetching && <div className={`try-content ${section === 'data' ? 'on' : ''}`}>
                                <Table 
                                    data={data.slice(0, 5)}
                                    project_reference={project_reference}
                                />
                            </div>}

                            {!isFetching && <div className={`try-content ${section === 'api' ? 'on' : ''}`}>
                                <Api 
                                    project_reference={project_reference}
                                />
                            </div>}

                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default PublicData;