import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const quotes = new BaseActions({
    path: `${base.root}/sw/quotes`,
    type: 'SWALLOW_QUOTES',
});

export default quotes;