import React from 'react';
import Table from '../Layout/Table';
import Avatar from '../Layout/Avatar';
import moment from 'moment';
import { omit } from 'lodash';

class EditTeamTable extends Table {
    constructor(props) {
        super(props);
        this.approval = this.approval.bind(this);
    }

    renderRow(data, i, status) {
        const {
            user_reference,
            name,
            company,
            roles = [],
            email,
            is_admin,
        } = data;

        const { version_reference, selected_user } = this.props;
        
        function map_color(role) {
            const map = {
                'editor': 'secondary',
                'read': 'grey',
                'approver': 'tertiary',
            };

            return map[role] || 'grey';
        }

        return (
            <tr key={user_reference} className={`${user_reference === selected_user.user_reference ? 'active' : ''}`}>
                <td>
                    <Avatar 
                        name={name}
                        inverse={true}
                        onClick={() => {
                            this.props.history.push(`/version/team/${version_reference}?user_reference=${user_reference}`)
                        }}
                    />
                    {is_admin && <button className="button small valid ml">Admin</button>}
                </td>
                <td>{company}</td>
                <td>{roles.map(r => (<span key={user_reference + r} className={`button no-hover ${map_color(r)} caps role`}>{r}</span>))}</td>
                <td>{email}</td>
                <td className="table-buttons">
                    <button className="button small main" onClick={() => this.props.selectUser(data)}>
                        Edit User
                    </button>
                </td>
            </tr>
        )
    }

    approval(i) {
        let action = null;
        const team = (this.state.team || this.props.items).map((t, index) => {
            if (i === index) {
                if (t.approval) {
                    return {
                        ...omit(t, 'approved'),
                        approval: null,
                    }
                } else {
                    action = {
                        type: 'approved',
                        content: '',
                    }
                    return {
                        ...t,
                        approval: moment().toISOString(), 
                    }
                }
            }
            return t;
        });

        this.setState({
            team,
        }, () => {
            this.props.save_process({
                team,
            }, action);
        });
    }

    render() {
        const { items = [], status } = this.props;
        return(
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Company</th>
                            <th style={{ width: '160px' }}>Role</th>
                            <th>Email</th>
                            <th style={{ width: '160px' }}></th>
                        </tr>
                    </thead>
                    <tbody>

                        {items.map((item, i) => {
                            return (this.renderRow(item, i, status));
                        })}

                        {(items || []).length === 0 &&
                            <tr>
                                <td colSpan={5}>
                                    <div className="empty-table">
                                        <i className="fa fa-warning"></i>
                                        No Team Members
                                    </div>
                                </td>
                            </tr>
                        }

                    </tbody>
                </table>
            </div>
        )
    }
}

export default EditTeamTable;