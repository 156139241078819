import React, { Component } from 'react';
import axios from 'axios';
import logo from '../../services/swallow/pages/assets/app/new-loader.png';
import config from '../../config';

class Verify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verified: false,
        }
        this.checkVerify = this.checkVerify.bind(this);
    }


    checkVerify() {
        const code = new URLSearchParams(window.location.search).get('code');
        const session = new URLSearchParams(window.location.search).get('session');
        axios({
            url: `${config.root}/a/verify/${code}/${session}?redirect=true`,
        }).then((res = {}) => {
            const { data = {} } = res;
            const { message, session, code } = data;
            if (code === 200) {
                window.location.href = `/login?status=SESSION::${session}`;
            } else {
                window.location.href = `/login?status=ERROR::${message}`;
            }
        }).catch(e => {
            window.location.href = `/login?status=ERROR::${e.message}`;
        })
    }

    componentDidMount() {
        this.checkVerify();
    }

    render() {        
        return (
            <div className="wf wf-br">
                <img className="spinning-logo-loader" alt="swallow logo" src={logo} />
            </div>
        );
    }
}
export default Verify;