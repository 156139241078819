import React, { Component } from 'react';
import FormatEdit from '../Shared/Format/Format';
import Calculation from '../Shared/Calculation/Calculation';

import Select from '../Shared/Forms/Select';
import ExpressionBuilder from '../Shared/Forms/Expression';

import ExpressionTest from '../Shared/Expression/ExpressionTest';

import { validate } from '../../../../utils/validate_project';

import { find } from 'lodash';

class OutputEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
        }
        this.updateStep = this.updateStep.bind(this);
        this.validate = this.validate.bind(this);
    }

    validate(data = {}) {
        let { step } = this.props;

        this.setState({ errors: [] });

        let payload = {
            ...step,
            ...data,
        }

        const result = validate({ step : payload });

        if (!result.valid) {
            this.setState({ errors: result.errors });
            return false;
        }

        return true;
    }

    updateStep(data) {
        const { step } = this.props;
        this.validate(data);
        this.props.updateStep({
            ...step,
            ...data,
        });
    }

    render() {
        const { 
            step,
            inputs = {},
            is_approved,
        } = this.props;

        const new_inputs = {
            ...inputs,
            exclusions: {
                type: 'array',
                value: [],
            },
            refers: {
                type: 'array',
                value: [],
            },
            excesses: {
                type: 'array',
                value: [],
            },
            endorsements: {
                type: 'array',
                value: [],
            }
        };

        const { errors } = this.state;
        const { result, valid } = step;

        return (
            <div>

                <div className="exclusions-edit">
                    <h4>Calculated Final Result</h4>
                    <p>This section is for calculating the final result price or premium.</p>
                    <Calculation
                        step={result}
                        inputs={inputs}
                        errors={errors}
                        updateStep={(value) => {
                            this.updateStep({
                                result: {
                                    ...result,
                                    type: 'decimal',
                                    formula: value.formula,
                                    def: value.def,
                                }
                            });
                        }}
                        is_approved={is_approved}
                    />
                </div>

                <div className="exclusions-edit">
                    <h4>Is Quote Valid?</h4>
                    <p>This section is for defining if a quote is valid or not.</p>
                    <div className="edit-columns">
                        <div className="edit-column">
                            <form>
                                <Select 
                                    errors={find(errors, e => e.path.includes('.def'))}
                                    value={valid.def}
                                    disabled={false}
                                    label={'Default'}
                                    updateStep={(value) => {
                                        this.updateStep({
                                            valid: {
                                                ...valid,
                                                def: value,
                                            }
                                        });
                                    }}
                                    type = 'boolean'
                                />

                                <ExpressionBuilder 
                                    errors={find(errors, e => e.path.includes('.exp'))}
                                    inputs={new_inputs}
                                    value={valid.exp}
                                    label={'Expression'}
                                    updateStep={(value) => {
                                        this.updateStep({
                                            valid: {
                                                ...valid,
                                                exp: value,
                                            }
                                        })
                                    }}
                                    tag={'Valid'}
                                />

                            </form>
                        </div>
                    </div>
                    {valid.exp !== '' &&
                        <ExpressionTest 
                            inputs={new_inputs}
                            expression={valid}
                        />
                    }
                </div>

                <FormatEdit
                    errors={errors}
                    set_error={this.props.set_error}
                    updateStep={this.updateStep}
                    step={step}
                    inputs={inputs}
                    header={'Additional Outputs'}
                    description={'This section enables you to define additional parameters in your returned response.'}
                    tag={'Output'}
                    is_approved={is_approved}
                />
                
            </div>
        );
    }
}

export default OutputEdit;