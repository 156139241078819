import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import axios from 'axios';
import rootReducer from './reducers';
import { get_auth } from './auth';
 
const client = axios.create({
    responseType: 'json',
});

// This uses allow_sessions
const authentication = (store) => (next) => async (action) => {
    //This will always get the latest access_token from refresh_token if needed.
    const { access_token: token } = await get_auth();

    // Return if reset
    if ((action.type || '').includes('RESET_')) return next(action);

    // Add all authorization to requests
    if (action && action.payload) {
        return next({
            ...action,
            payload: {
                ...action.payload,
                request: {
                    ...action.payload.request,
                    headers: {
                        ...action.payload.request.headers,
                        authorization: token ? `Bearer ${token}` : '',
                    }
                }
            }
        })
    };

    return next(action);
};

const catchNetworkError = store => next => action => {

    // Network not available
    /*
    if (get(action, 'error.status') === 0) {
        store.dispatch({
            type: 'SET_ERROR',
            value: {
                message: 'Network Error',
            },
        });
    }
    */

    // All other Network fails (i.e. 404, 403, 401, 400)
    /*
    if (get(action, 'type', '').includes('_FAIL')) {
        store.dispatch({
            type: 'SET_ERROR',
            value: {
                message: `Network ${get(action, 'error.message', '')}`,
            },
        });
    }
    */
    
    // console.log(${get(action, 'error.message', ''))

    let result = next(action)
    return result
  }

const middleware = applyMiddleware(
    authentication,
    axiosMiddleware(client, { returnRejectedPromiseOnError: true }),
    catchNetworkError,
    thunkMiddleware,
);

const initStore = (initialState = {}) => createStore(rootReducer, initialState, middleware);

export default initStore;