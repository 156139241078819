import React, { Component } from 'react';
import Table from '../../../Layout/Table';
import Calculation from '../Shared/Calculation/Calculation';

import WithData from './WithData';
import { isEmpty, keys, each, omit } from 'lodash';
import slugify from '../../../../utils/slugify';
import { validate } from '../../../../utils/validate_project';
import { evaluateFactors, QL } from '@swa_llow/pricing_engine';

class FactorsTable extends Table {
    constructor(props) {
        super(props);
        this.state = {
            factor_name: null,
        }
    }

    renderRow(data, i) {
        const {
            name,
            dimension_count,
            def,
            factor_count,
        } = data;

        const { select_factor, delete_factor, is_approved } = this.props;

        return (
            <tr key={`factors-${i}`}>
                <td>
                    {name}
                </td>
                <td>{def}</td>
                <td><div className="button no-hover small white">{factor_count !== 0 ? `${factor_count}` : '-'}</div></td>
                <td><div className="button no-hover small version">{dimension_count !== 0 ? `${dimension_count}D` : '-'} </div></td>
                <td className="table-buttons">
                    {!is_approved && <button onClick={() => delete_factor(name)} className="button small invalid mr">
                        Delete Factor
                    </button>
                    }
                    <button onClick={() => select_factor(name)} className="button small secondary">
                        {is_approved ? 'View Factor' : 'Edit Factor' }
                    </button>
                </td>
            </tr>
        )
    }

    render() {
        const { items = [] } = this.props;

        return (
            <div>

                <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>Factor Name</th>
                                <th style={{ width: '80px' }}>Default</th>
                                <th style={{ width: '80px' }}>Rows</th>
                                <th style={{ width: '80px' }}>Dimensions</th>
                                <th style={{ width: '180px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, i) => {
                                return (this.renderRow(item, i));
                            })}
                            {(items || []).length === 0 &&
                                <tr>
                                    <td colSpan={5}>
                                        <div className="help-block empty-table">
                                            <i className="fa fa-warning"></i>
                                            No Factors
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

class Factors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: {
                ...this.props.step,
                result: this.props.step.result || {
                    key: this.props.step.key, 
                    name: this.props.step.key,
                    formula: '',
                    type: 'decimal',
                    def: 0,
                },
                factors: this.props.step.factors || {},
            },
            factor: null,
            errors: [],
            selected_factor: null,
        }
        this.to_items = this.to_items.bind(this);
        this.select_factor = this.select_factor.bind(this);
        this.create_factor = this.create_factor.bind(this);
        this.delete_factor = this.delete_factor.bind(this);
        this.update_step = this.update_step.bind(this);
        this.update_key = this.update_key.bind(this);
        this.clear_factor = this.clear_factor.bind(this);
        this.update_calculation = this.update_calculation.bind(this);
        this.validate = this.validate.bind(this);
    }

    validate(data = {}) {
        let { step } = this.state;

        this.setState({ errors: [] });

        let payload = {
            ...step,
        }

        if (data.key) {
            payload = {
                ...payload,
                key: data.key,
            }
        }

        if (data.factors) {
            payload = {
                ...payload,
                factors: {
                    ...payload.factors,
                    ...data.factors,
                }
            }
        }

        if (data.result) {
            payload = {
                ...payload,
                result: {
                    ...payload.result,
                    ...data.result,
                    type: 'decimal',
                }
            }
        }

        const result = validate({ step : payload });

        if (!result.valid) {
            this.setState({ errors: result.errors });
            return false;
        }

        return true;
    }

    to_items({ factors }) {
        let items = [];
        each(factors, (v, k) => {
            items = [
                ...items,
                {
                    name: k,
                    def: (v.def || {}).weight || 0,
                    dimension_count: keys(v.dimensions).length,
                    factor_count: keys(v.hashes).length,
                }
            ]
        });
        return items;
    }

    to_inputs({ factors = {}, inputs = {} }) {
        let new_inputs = {};
        let quote = {}
        
        each(inputs, (v, k) => {
            quote[k] = v.value;
        });

        each(factors, (factor, k) => {
            try {
                const [ factor_result ] = evaluateFactors({
                    original: QL(quote),
                    factors: { factor },
                }) || [];
                if (factor_result) {
                    new_inputs[k] = {
                        value: factor_result.weight,
                        type: 'decimal',
                    }
                }
            } catch(e) {}
        });

        return {
            ...new_inputs,
            ...inputs,
        };
    }

    async create_factor(factor_name = '') {
        const { step = {} } = this.props;
        const { factors = {} } = step;
        if (factor_name === '') return;
        const slug_name = slugify(factor_name);

        let payload = {
            ...step,
            factors: {
                ...factors,
                [slug_name]: {
                    description: '',
                    dimensions: {},
                    hashes: {},
                }
            },
        };

        await this.props.updateStep(payload);        
        this.setState({ step: payload });
        this.select_factor(slug_name);
    }

    async delete_factor(factor_name) {
        const { step = {} } = this.props;
        const { factors = {} } = step;
        let payload = {
            ...step,
            factors: omit(factors, [factor_name]),
        };
        await this.props.updateStep(payload);
        this.setState({ step: payload });
    }

    select_factor(name) {
        this.setState({
            selected_factor : name,
        });
    }

    clear_factor() {
        this.setState({
            selected_factor : null,
        });
    }

    update_key({ old_key, new_key }) {
        const { step } = this.props;
        const { factors } = step;

        if (new_key === '') return;

        let new_factors = {
            ...omit(factors, old_key),
            [new_key]: factors[old_key],
        }

        let new_step = {
            ...step,
            factors : new_factors,
        }

        this.setState({
            step: new_step,
            selected_factor: null,
        });

        this.validate(new_step)
        this.props.updateStep(new_step);
    }

    update_calculation(data) {
        const { step } = this.state;

        const new_step = {
            ...step,
            result: {
                ...data,
                type: 'decimal',
            }
        };

        this.setState({
            step: new_step
        });

        this.validate(new_step)
        this.props.updateStep(new_step);
    }

    update_step(data = {}) {
        const { selected_factor = '' } = this.state;
        const { step = {} } = this.state;
        const { factors = {}, result = {} } = step;
        const { factor, formula } = data;

        let new_step = {
            ...step,
        }

        if (formula) {
            new_step = {
                ...new_step,
                result: data,
            }
        }

        if (factor) {
            new_step = {
                ...new_step,
                factors: {
                    ...factors,
                    [selected_factor]: factor,
                },
            }

            if (result.formula === '') {
                new_step = {
                    ...new_step,
                    result: {
                        ...result,
                        formula: `({{${selected_factor}}})`
                    }
                }
            }
        }

        this.setState({ step: new_step });
        this.props.updateStep(new_step);
    }

    render() {
        const { 
            is_approved,
        } = this.props;

        const { selected_factor = '', step, errors }  = this.state;

        const { factors = {} } = step;
        const factor = factors[selected_factor];

        const items = this.to_items({ factors });
        const inputs = this.to_inputs({ factors, inputs: this.props.inputs });

        return (
            <div>
                {selected_factor && 
                    <WithData
                        upload_open={isEmpty(items)}
                        key={'with-data'}
                        inputs={inputs}
                        step={{
                            ...factor,
                            key: selected_factor,
                        }}
                        updateStep={this.update_step}
                        updateKey={this.update_key}
                        clearSelected={this.clear_factor}
                        is_approved={is_approved}
                        errors={errors}
                        selected_item_id={this.props.selected_item_id}
                    />
                }
                
                <div className="exclusions-table" style={{ marginBottom: 20, position: 'relative' }}>
                    <FactorsTable 
                        items={items}
                        select_factor={this.select_factor}
                        create_factor={this.create_factor}
                        delete_factor={this.delete_factor}
                        is_approved={is_approved}
                        errors={errors}
                    />
                    {!selected_factor &&
                        <div className="exclusions-table-buttons">
                            <button className="button small main" onClick={() => this.create_factor('new_factor')}>Create Factor</button>
                        </div>
                    }
                </div>

                {items.length > 0 &&
                    <div className="exclusions-edit">
                        <Calculation 
                            errors={errors}
                            step={step.result}
                            inputs={inputs}
                            updateStep={this.update_calculation}
                            is_approved={is_approved}
                        />
                    </div>
                }
                
            </div>
        )
    }
}

export default Factors;