import React, { Component } from 'react';
import ItemsEdit from '../Shared/Items/ItemsNonBooleanDefault';

import {
    stepLabelSchema,
} from '@swa_llow/pricing_engine';

class LabelEdit extends Component {
    render() {
        const { 
            step,
            inputs,
            is_approved,
            errors,
        } = this.props;

        return <ItemsEdit
            schema={stepLabelSchema}
            set_error={this.props.set_error}
            updateStep={this.props.updateStep}
            step={step}
            inputs={inputs}
            header={'Create a Label'}
            description={'A label is made from combining properties from previous steps. For example, you might look at vehicle factors and create label a vehicle "High Performance".'}
            tag={'Label'}
            is_approved={is_approved}
            errors={errors}
            selected_item_id={this.props.selected_item_id}
        />;
    }
}

export default LabelEdit;