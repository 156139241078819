import React, { Component } from 'react';
import { orderBy, last } from 'lodash';

import VersionsTable from './VersionsTable';
import ProjectChart from './MiniChart';
import Badge from '../Layout/Badge';

import config from '../../../../config';

import { Link } from 'react-router-dom';

function getLivePublished(versions) {
    const latest_published = last(orderBy(versions.filter(v => v.published_at), v => v.version));
    return latest_published;
}

class ProjectMiniContainer extends Component {

    render () {
        const { project, history } = this.props;
        const { versions = [], project_reference } = project;
        const latest_published = getLivePublished(versions);

        getLivePublished(versions)
        return (
            <div key={project.project_reference} className={`project mini ${latest_published ? 'show_try' : ''}`}>
                <div className="project-top">
                    <div className="project-top-left">
                        <div className="project-box meta">
                            <div className="project-box-detail">
                                <h2><Link className="button big main" style={{ fontSize: 18, padding: '6px 12px 6px' }} to={`/project/${project.project_reference}`}><i style={{ fontSize: 12 }} className="fa fa-diagram-project"></i> { project.name }</Link></h2>
                                <p style={{ height: 58 }}>{ project.description }</p>
                                <p className="project-date"></p>
                            </div>
                            <ProjectChart quotes={[]} dummy={true} project_reference={project_reference} history={history} />
                        </div>
                    </div>
                </div>

                <div className="project-bottom">
                    <div className="project-bottom-left">
                        <div className="project-box">
                            <VersionsTable items={[project]} history={this.props.history} versions={versions} />
                        </div>
                    </div>
                </div>

                {latest_published &&
                    <div className="top-nav">
                        <div class="version-url">
                            <ul>
                                <li>
                                    <Badge 
                                        type={latest_published.status}
                                        label={`${latest_published.status} #${latest_published.version}`} 
                                    />
                                    <input value={`${config.root}/project/try/${latest_published.project_reference}`} />
                                </li>
                                <li>
                                    {/*<button onClick={() => navigator.clipboard.writeText(`${config.root}/project/try/${latest_published.project_reference}`)} className="button white small mr">
                                        <i className="fa fa-copy"></i>
                                        COPY
                                    </button>*/}
                                    <button onClick={() => this.props.history.push(`/project/try/${latest_published.project_reference}`)} className="button main small">
                                        <i className="fa fa-laptop"></i>
                                        TRY
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default ProjectMiniContainer;