import React, { Component } from 'react';
import versions_hoc from '../../hoc/versions';

import config from '../../../../config/index';
import { getId } from '../../../../utils/url';
import { DownloadButton } from '../../utils/download';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import DataList from '../../components/Data/DataList';

class VersionData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_api: false,
            process: this.props.process,
        };
        this.searchQuotes = this.searchQuotes.bind(this);
        this.openAPI = this.openAPI.bind(this);
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        this.setState({ version_reference: id })
        await this.props.swallow_quotes_get_all({ version_reference: id });

    }

    async searchQuotes(value) {
        const { version_reference } = this.state;
        this.props.swallow_quotes_get_all({
            search: value,
            version_reference,
        })
    }

    openAPI() {
        const { open_api } = this.state;
        this.setState({ open_api: !open_api});
    }

    render() {
        const { open_api, version_reference } = this.state;
        const { auth = {}, read_only } = this.props;

        const { data = [], isFetching } = this.props.swallow_quotes;
        const { version = {} } = this.state.process || {};
        const { account_type } = this.props;


        const url = `${config.root}/sw/quotes?version_reference=${version_reference}`;
        const { client_id_key = '', client_secret_key = '' } = auth.selected || {};

        return [
            <div class="swallow_app data-area">

                <TopNav history={this.props.history} version={version} auth={this.props.auth} account_type={account_type} read_only={read_only} />
                

                <div className="design-button-area">
                    <div className="design-button-area-left">
                        <button className="button main small grey">Count : {data.length}</button>
                    </div>
                    
                    <div className="design-button-area-right">
                        <DownloadButton 
                            data={{
                                data,
                            }}
                            label={'Data'}
                            file_name={`data.csv`}
                            type={'data'}
                            className={'mr'}
                        />
                        <button className="button main small" onClick={this.openAPI}><i className="fa fa-database"></i> Data API</button>
                    </div>
                </div>

                {open_api && 
                    <div>
                        <div className="code-block" style={{ height: 120 }}>
                            <label>Data API</label>
                            <textarea style={{ height: 50, minHeight: 'auto' }} value={`curl --request GET --url '${url}' --header 'content-type: application/json' --header 'client_secret_key: ${client_secret_key}' --header 'client_id_key: ${client_id_key}'`} />
                        </div>
                    </div>
                }

                {!isFetching &&
                    <DataList 
                        data={data}
                        account_type={account_type}
                        history={this.props.history}
                        version={version}
                        searchQuotes={this.searchQuotes}
                        openAPI={this.openAPI}
                    />
                }

                <Footer history={this.props.history}/>

            </div>,

        ];
    }
}

export default versions_hoc(VersionData);