import React, { Component } from 'react';
import moment from 'moment';

class DataTable extends Component {
    renderRow(data) {
        const {
            created_at, 
            quote_reference,
            indicators = [],
            result,
            valid,
        } = data;

        return (
            <tr key={quote_reference}>
                <td className="no-mobile">
                    {quote_reference}
                </td>
                <td className="no-mobile">
                    {[indicators[0]].map((indicator = '') => {
                        return indicator.toUpperCase();
                    })}
                </td>
                <td>
                    {result}
                </td>
                <td>
                    {valid && <button className="button small no-hover valid">VALID</button>}
                    {!valid && <button className="button small no-hover invalid">INVALID</button>}
                </td>
                <td>
                    {moment(created_at).fromNow()}
                </td>
            </tr>
        )
    }

    render() {
        const { data = []} = this.props;

        const items = data.map(d => {
            return {
                quote_reference: d.quote_reference,
                indicators: d.indicators.split('::'),
                result: d.result,
                valid: d.valid,
                created_at: d.created_at,
            }
        });

        return(
            <table>
                <thead>
                    <tr>
                        <th className="no-mobile">QUOTE REF</th>
                        <th className="no-mobile">DATA</th>
                        <th>RESULT</th> 
                        <th>VALIDITY</th>
                        <th>WHEN</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(d => this.renderRow(d))}
                </tbody>
            </table>
        )
    }
}

export default DataTable;