import React, { Component } from 'react';
import FormatEdit from '../Shared/Format/Format';

import { inputSchema } from '@swa_llow/pricing_engine';
import { keys } from 'lodash';
import { DownloadButton } from '../../../../utils/download';
import { UploadArea } from '../../../../utils/upload';

class InputEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upload: false,
        }
        this.uploadedInput = this.uploadedInput.bind(this);
    }

    uploadedInput(results) {
        const { step } = this.props;
        this.setState({ upload: false });
        this.props.updateStep({
            ...step,
            format: results,
        });
    }

    render() {
        const { 
            selected_item_id,
            step,
            inputs,
            is_approved,
            errors,
        } = this.props;

        const { upload } = this.state;

        const { format = {} } = step;
        const count = keys(format).length;

        return (
            <div>
                <div className="inputs-upload">
                    <div key={`full-buttons`} className="upload-buttons">
                        <button className="button small grey no-hover mr">Count : {count}</button>
                        <DownloadButton 
                            data={{
                                format,
                            }}
                            label={'Inputs'}
                            file_name={`${step.key}.csv`}
                            type={'input'}
                            className={'mr'}
                        />
                        {!is_approved && <button className="button small secondary" onClick={() => this.setState({ upload: !this.state.upload })}>
                            <i className="fa fa-angles-up"></i>Upload Inputs
                        </button>}
                    </div>
                    {upload &&
                        <div className="exclusions-edit">
                            <UploadArea
                                complete={this.uploadedInput}
                                type='input' 
                            />
                        </div>
                    }
                </div>
                <FormatEdit
                    schema={inputSchema}
                    set_error={this.props.set_error}
                    updateStep={this.props.updateStep}
                    step={step}
                    inputs={inputs}
                    header={'Define Initial Properties'}
                    description={'You need to add at least one property that makes up your model. (e.g. age, gender, postcode).'}
                    tag={'Property'}
                    is_approved={is_approved}
                    errors={errors}
                    selected_item_id={selected_item_id}
                />
            </div>
        );
    }
}

export default InputEdit;