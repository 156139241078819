import React, { Component } from 'react';
import { Sparklines, SparklinesBars, SparklinesReferenceLine } from 'react-sparklines';

class ProjectFeed extends Component {
    render() {
        let { quotes, dummy, history, project_reference } = this.props;

        if (dummy) {
            quotes = {
                data: [ 50, 100, 50, 200, 80, 150, 50, 100, 50, 200, 50, 100, 50, 200, 80, 150, 50, 100, 50, 200, 50, 100, 50, 200 ],
                total: 2000,
                valid: 1200,
            };
        }

        const { data = [] } = quotes;
        const fill = ( dummy ? '#cccccc' : '#54cf00' );
        return (
            <div className="project-chart">
                <p className="label">Data (last 24 Hrs)</p>

                {/*
                <button className='button white small'>
                    <i className='fa fa-lock'></i>
                    UNLOCK
                </button>
                */}

                <button onClick={() => history.push(`/project/data/${project_reference}`)} className='button small main'>
                    <i className='fa fa-server'></i>
                    VIEW DATA
                </button>

                <Sparklines 
                    data={data} 
                    limit={24}
                    height={50} 
                    margin={5}
                    width={240}
                >
                    <SparklinesReferenceLine type={'avg'} style={{ stroke: 'grey', strokeOpacity: .5, strokeDasharray: '2, 2' }} />
                    <SparklinesBars style={{ fill: fill, width: '10px' }} />
                </Sparklines>
            </div>
        )
    }
}

export default ProjectFeed;