import React, { Component } from 'react';
import { WFInput } from '../../components/wireframes/modules';
import slugify from '../../../src/services/swallow/utils/slugify';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_names: '',
            last_names: '',
            email: '',
            company_name: '',
        };
        this.create = this.create.bind(this);
    }

    create(data) {
        this.setState(data);
    }

    render() {
        const { email, first_names, last_names, company_name, code } = this.state;
        const { isFetching } = this.props;

        return (
            <div id="register" style={{marginBottom: 40}} className="wf">

                <h1>Create Account</h1>

                <div className="register-code">
                    <p>You need an invite code to create an account. You can ask for one from an existing account holder or <a href="http://typeform.com">click here</a> to join the waiting list.</p>
                    <WFInput
                        name={'code'}
                        label={''}
                        submit={(value) => { 
                            if (value.code.length !== 8) {
                                this.props.set_auth_error('Invite code is not correct');
                            } else {
                                this.props.set_auth_error('');
                            }
                            this.create(value);
                        }}
                        placeholder={'Invite Code'}
                        validation={(value) => {
                            return value.length !== 8;
                        }}
                    />
                </div>

                <br/>

                <p>Please enter the details below</p>

                <WFInput
                    name={'email'}
                    label={''}
                    submit={(value) => { 
                        const { email = '' } = value
                        if (!(email.includes('@')&& email.includes('.'))) {
                            this.props.set_auth_error('Email is not correct');
                        } else {
                            this.props.set_auth_error('');
                        }
                        this.create(value);
                    }}
                    placeholder={'Email address'}
                    validation={(value) => {
                        if (!value.includes('@')) return false;
                        if (!value.includes('.')) return false;
                        return value.length < 100;
                    }}
                />

                <br/>

                <WFInput
                    name={'first_names'}
                    label={''}
                    submit={(value) => { 
                        const { first_names = '' } = value
                        if (first_names.length < 3) {
                            this.props.set_auth_error('Enter first name');
                        } else {
                            this.props.set_auth_error('');
                        }
                        this.create(value);
                    }}
                    placeholder={'First name'}
                    validation={(value) => {
                        return value.length < 100;
                    }}
                />

                <br/>

                <WFInput
                    name={'last_names'}
                    label={''}
                    submit={(value) => { 
                        const { last_names = '' } = value
                        if (last_names.length < 3) {
                            this.props.set_auth_error('Enter last name');
                        } else {
                            this.props.set_auth_error('');
                        }
                        this.create(value);
                    }}
                    placeholder={'Last name'}
                    validation={(value) => {
                        return value.length < 100;
                    }}
                />

                <br/>   

                <div className="register-company">
                    <WFInput
                        name={'company_name'}
                        label={''}
                        update={(value) => {
                            const new_value = slugify(value.company_name);
                            this.create({ company_name: new_value });
                            return new_value;
                        }}
                        submit={(value) => { this.create(value) }}
                        placeholder={'Account name'}
                        validation={(value) => {
                            return value.length < 100;
                        }}
                    />
                    <div className="register-company-url">
                        https://<b>{company_name}</b>.swallow.app
                    </div>
                </div>

                <br/>

                <button onClick={(e) => {
                    e.preventDefault();
                    this.props.create_account({
                        email,
                        first_names,
                        last_names,
                        company_name,
                        code,
                    });
                }}>
                    Create Account
                </button>

                <button style={{marginLeft: 20, background: '#bababa'}} onClick={(e) => {
                    e.preventDefault();
                    this.props.set_auth_status('START');
                }}>
                    Go Back
                </button>

                {isFetching &&
                    <div className="login-mask">
                        <div className="wf-loader">
                            <div className="loading-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}
export default Register;