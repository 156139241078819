import React, { Component } from 'react';
import BooleanSelect from '../Design/Edit/Shared/Forms/BooleanSelect';
import { isUndefined } from 'lodash';
import SelectSearch from 'react-select-search';

class FormElementSelector extends Component {
    render() {
        const{
            form_key: key,
            type,
            label,
            updateQuote,
            quote = {},
            error,
            lists = {},
        } = this.props;

        const value = (isUndefined(quote[key]) || quote[key] === null )? '' : quote[key];
        const list = lists[key];

        return (
            <fieldset key={key} className={error? 'error spacing' : 'spacing'}>
                <label>{label}</label>
                {error && <p className="tiny-error-message">REQUIRED</p>}

                {type === 'string' && !list &&
                    <input 
                        onChange={(e) => {
                            updateQuote({
                                [key]: e.target.value,
                            })
                        }} 
                        value={value}
                    />
                }

                {/*type === 'string' && list &&
                    <select 
                        value={value} 
                        onChange={(e) => {
                            updateQuote({
                                [key]: e.target.value
                            });
                        }
                    }>
                        <option value={''}>{''}</option>
                        {(list.data || []).map(l => {
                            return (<option value={l}>{l}</option>);
                        })}
                    </select>
                */}

                {type === 'string' && list &&
                    <SelectSearch 
                        options={(list.data || []).map(l => {
                            return { name: l, value: l };
                        })} 
                        value={value} 
                        search={true}
                        name={key} 
                        placeholder=""
                        onChange={(val) => {
                            updateQuote({
                                [key]: val,
                            })
                        }}  
                    />
                }

                {(type === 'integer' || type === 'decimal')&&
                    <input 
                        type="number"
                        onChange={(e) => {
                            let { value } = e.target;
                            value = (value !== '' ? Number(value) : '');
                            updateQuote({
                                [key]: value,
                            })
                        }} 
                        value={value}
                    />
                }

                {(type === 'date')&&
                    <input 
                        type="date"
                        className="date"
                        onChange={(e) => {
                            let { value } = e.target;
                            updateQuote({
                                [key]: value,
                            })
                        }} 
                        value={value}
                    />
                }

                {(type === 'boolean') &&
                    <BooleanSelect 
                        update={({ value }) => {
                            updateQuote({
                                [key]: value,
                            })
                        }}
                        value={value}
                    />
                }

            </fieldset>
        );
    }
}

export default FormElementSelector;