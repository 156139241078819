import {
    projectSchema,
    outputSchema,
    inputSchema,
    stepTransformSchema,
    stepExcessesSchema,
    stepEndorsementsSchema,
    stepExclusionSchema,
    stepReferSchema,
    stepLabelSchema,
    stepLinksSchema,
    stepExternalSchema,
    stepFactorsSchema,
    evaluateSchema,
    stepCalculationSchema,
    stepCollectionSchema,
} from '@swa_llow/pricing_engine';

export const mapSchema = (step) => {
    const map = {
        'output': outputSchema,
        'input': inputSchema,
        'transform': stepTransformSchema,
        'calculation': stepCalculationSchema,
        'excesses' : stepExcessesSchema,
        'endorsements' : stepEndorsementsSchema,
        'exclusion' : stepExclusionSchema,
        'refer' : stepReferSchema,
        'label' : stepLabelSchema,
        'links' : stepLinksSchema,
        'external': stepExternalSchema,
        'factors': stepFactorsSchema,
        'collection': stepCollectionSchema,
        'common': projectSchema,
    }
    return map[step] || map['common'];
}


export function validate({ version , step }) {

    // Used in EditArea
    if (step) {
        const result = evaluateSchema({ 
            project: step,
            schema: mapSchema(step.step), 
        });
        return result;    
    }

    // Used in DesignArea
    if (version) {
        const result = evaluateSchema({ 
            project: version,
            schema: mapSchema('common'), 
        });
        return result;
    }
}