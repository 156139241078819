import React, { Component } from 'react';
import {mean, round } from 'lodash';

class TestsAggregate extends Component {

    calculate(tests = [], key = 'output') {

        function aggregate_item ({ data = [], key = '', dps = 2, type }) {
            if (type === 'decimal' || type === 'integer') {
                const result = round(mean(data.map(t => t[key])), dps).toFixed(dps);
                if (isNaN(result)) return round(Number(0).toFixed(dps), dps);
                return round(result, dps);
            } else {
                //i.e. boolean
                return data.length;
            }
        }

        function aggregate_group ({ data = {}, key = '', dps = 2, type }) {
            return {
                true: aggregate_item({ data: data.true, key, dps, type  }),
                false: aggregate_item({ data: data.false, key, dps, type }),
                total: aggregate_item({ data: data.total, key, dps, type  }),
            }
        }

        const outputs = tests.map(t => t[key]).filter(t => t);
        if (outputs.length === 0) return null;

        const cohorts = {
            true: outputs.filter(t => t.valid),
            false: outputs.filter(t => !t.valid),
            total: outputs,
        };

        let totals = {
            result: aggregate_group({ data: cohorts, key: 'result', dps: 2, type: 'decimal' }),
            validity: {
                true: cohorts.true.length,
                false: cohorts.false.length, 
                total: cohorts.total.length,
            },
        }

        return totals;
    }

    renderAggregate({ data, name = 'Expected' }) {
        return (
            <div>
                <h3 style={{ margin : 10 }}>{name} Result</h3>
                <div className="test-section review-test-section">
                    <div className="review-block">
                        <p className="test-title">Validity</p>
                        <h4>{round((data.validity.true / data.validity.total) * 100, 2)}%</h4>
                        <ul>
                            <li>{data.validity.true} <span className="button small valid no-hover"><i className="fa fa-circle-check"></i>TRUE</span></li>
                            <li>{data.validity.false} <span className="button small invalid no-hover"><i className="fa fa-circle-xmark"></i>FALSE</span></li>
                        </ul>
                    </div>
                    <div className="review-block">
                        <p className="test-title">Result (Ave)</p>
                        <h4>{data.result.total}</h4>
                        <ul>
                            <li>{data.result.true}</li>
                            <li>{data.result.false}</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    renderMovement({ expected, actual }) {
        let total_movement = round(((actual.result.total - expected.result.total) / expected.result.total) * 100, 1);
        let valid_movement = round(((actual.result.true - expected.result.true) / expected.result.true) * 100, 1);
        let invalid_movement = round(((actual.result.false - expected.result.false) / expected.result.false) * 100, 1);

        if(isNaN(total_movement)) total_movement = 0;
        if(isNaN(valid_movement)) valid_movement = 0;
        if(isNaN(invalid_movement)) invalid_movement = 0;

        if (total_movement === Infinity) total_movement = 100;
        if (valid_movement === Infinity) valid_movement = 100;
        if (invalid_movement === Infinity) invalid_movement = 100;

        return (
            <div>
                <h3 style={{ margin : '10px 0px 10px 10px' }}>Movement</h3>
                <div className="test-section review-test-section tests-movement">
                    <div className="review-block">
                        <p className="test-title">Result (Ave)</p>
                        { total_movement > 0 && <div className="movement-main"><span className="button big valid no-hover"><i className="fa fa-circle-up"></i>{total_movement}%</span></div>}
                        { total_movement < 0 && <div className="movement-main"><span className="button big invalid no-hover"><i className="fa fa-circle-down"></i>{total_movement}%</span></div>}
                        { total_movement === 0 && <div className="movement-main"><span className="button big white no-hover"><i className="fa fa-circle-minus"></i>{total_movement}%</span></div>}
                        <ul>
                            { valid_movement > 0 && <li><span className="button small valid no-hover"><i className="fa fa-circle-up"></i>{valid_movement}%</span>&nbsp;</li>}
                            { valid_movement < 0 && <li><span className="button small invalid no-hover"><i className="fa fa-circle-down"></i>{valid_movement}%</span>&nbsp;</li>}
                            { valid_movement === 0 && <li><span className="button small white no-hover"><i className="fa fa-circle-minus"></i>{valid_movement}%</span>&nbsp;</li>}
                            { invalid_movement > 0 && <li><span className="button small valid no-hover"><i className="fa fa-circle-up"></i>{invalid_movement}%</span>&nbsp;</li>}
                            { invalid_movement < 0 && <li><span className="button small invalid no-hover"><i className="fa fa-circle-down"></i>{invalid_movement}%</span>&nbsp;</li>}
                            { invalid_movement === 0 && <li><span className="button small white no-hover"><i className="fa fa-circle-minus"></i>{invalid_movement}%</span>&nbsp;</li>}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let { tests = [] } = this.props;

        const expected_totals = this.calculate(tests, 'output');
        const actual_totals = this.calculate(tests, 'result');

        return (
            <div key={'tests-aggregate'} className="tests-aggregate-sections">
                {expected_totals && this.renderAggregate({ data: expected_totals, name: 'Expected' })}
                {actual_totals && this.renderAggregate({ data: actual_totals, name: 'Actual' })}
                {actual_totals && this.renderMovement({ actual: actual_totals, expected: expected_totals })}
            </div>
        )
    }
}

export default TestsAggregate;