import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../../actions';

export default (Page) => {
    class Projects extends Component {
        async UNSAFE_componentWillMount() {
            // Clear version details so that the latest will be fetched
            await this.props.swallow_versions_reset();
            await this.props.swallow_process_reset();
            await this.props.swallow_tests_reset();
        }  

        render() {
            return (
                <Page { ...this.props} />
            );
        }
    }

    function mapStateToProps(state) {
        return state;
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators({
            ...actions,
        }, dispatch);
    }

    return connect(mapStateToProps, mapDispatchToProps)(Projects);
};