import React, { Component } from 'react';

class ControlsBoxProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            load: false,
        };
        this.startProgress = this.startProgress.bind(this);
        this.stopProgress = this.stopProgress.bind(this);
    }

    startProgress() {
        this.interval = setInterval(() => {
            this.setState({ count: this.state.count + 20 }, () => {
                if (this.state.count === 1000) { 
                    clearInterval(this.interval);
                    this.setState({ load: true, count: 0 })
                    this.props.onClick();
                }
            });
        }, 20);
    }

    stopProgress() {
        clearInterval(this.interval);
        this.setState({ count: 0, load: false });
    }

    render() {
        const width = `${(this.state.count / 1000) * 100}%`;
        const { label, version_number } = this.props; 
        const { load } = this.state;

        const map_style = (label) => {
            const m = {
                'Approve' : 'control-approve',
                'Publish' : 'main',
                'Delete' : 'invalid',
            };
            return m[label] || 'approved';
        }

        const map_icon = (label) => {
            const m = {
                'Approve' : 'fa-code-merge',
                'Publish' : 'fa-rocket',
                'Delete' : 'fa-trash',
            };
            return m[label] || 'approved';
        }

        return (
            <div className="download-item" 
                onMouseDown={this.startProgress}
                onMouseUp={this.stopProgress}
            >
                <div className="download-name">
                    <b className={`button big ${map_style(label)} no-hover`}>
                        {!load && <i className={`fa ${map_icon(label)}`}></i>}
                        {load && <i class="fa-solid fa-spinner fa-spin"></i>}
                        {label} Version #{version_number}
                    </b>
                </div>
                <div className="control-progress-container">
                    <div className="control-progress-bar" style={{ width }}></div>
                </div>
            </div>
        )
    }
}

export default ControlsBoxProgress;