import React, { Component } from 'react';

import TryArea from '../../components/Try/TryArea';
import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import { evaluateLists } from '@swa_llow/pricing_engine';

// import { fetchVersion } from '../../utils/s3_versions';

import { getId } from '../../../../utils/url';
import { each } from 'lodash';

function map_formats(formats = {}) {
    let arr = [];
    each(formats, (v, k) => {
        arr = [...arr, {
            key: k,
            ...v,
        }]
    });
    return arr;
}

class ProjectTry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: [],
            isFetching: false,
        }
    }

    async componentDidMount() {
        await this.refreshVersion();
    }

    async refreshVersion() {
        this.setState({ isFetching: true });
        const id = getId(this.props.history);
        await this.props.swallow_projects_get({ id });
        const { selected: project = {} } = this.props.swallow_projects;
        const { version_reference } = project;
        await this.props.swallow_versions_get({ id: version_reference });
        let { selected:version = {} } = this.props.swallow_versions;
        const { input } = version;
        const lists = evaluateLists(version);
        this.setState({
            project,
            version,
            lists,
            inputs: map_formats(input.format),
            isFetching: false,
        });
    }

    render() {
        const { 
            version,
            project,
            lists,
            isFetching,
            inputs,
        } = this.state;
        
        const { auth, account_type, history } = this.props;

        const { selected } = auth;

        return (
            <div className="swallow_app try-form">

                { !isFetching && <TopNav 
                    history={history}
                    auth={auth} 
                    account_type={account_type}
                    project={project}
                />}

                <div className="approved" style={{ marginTop: 30 }}>
                    { !isFetching &&
                        <TryArea 
                            auth={selected}
                            project={version}
                            inputs={inputs}
                            lists={lists}
                            is_latest_published={true}
                        />
                    }
                </div>

                <Footer history={this.props.history} />

            </div>
        );
    }
}

export default ProjectTry;