import { isString } from "lodash";
export default function slugify (str = ''){
    if ( str === null) return '';
    if(!isString(str)) str = str.toString();
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '_')
        .replace(/^-+|-+$/g, '');
};