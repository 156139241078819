import React from 'react';
import Table from '../Layout/Table';
import moment from 'moment';

import { Link } from 'react-router-dom';
import Badge from '../Layout/Badge';

class VersionsTable extends Table {
    renderLatestStatus (status = '') {
        const cn = status === 'draft' ? 'secondary' : 'tertiary';
        return (<button className={`button ${cn} small`}>{status.toUpperCase()}</button>);
    }

    renderRow(data, any_draft) {
        const { createVersion } = this.props;
        const {
            version_reference,
            version,
            status,
            approved_at,
            published_at,
            created_at,
        } = data;

        return (
            <tr key={version_reference}>
                <td>
                    <Badge                
                        type={status}
                        label={`${status} #${version}`}
                    />
                    {createVersion && !any_draft &&
                        <button className={`button small main`} onClick={() => createVersion(version_reference)}>
                            Clone New Version
                        </button>
                    }
                </td>
                <td><span className="button date small">{created_at ? moment(created_at).fromNow() : '-'}</span></td>
                <td><span className="button date small">{approved_at ? moment(approved_at).fromNow() : '-'}</span></td>
                <td><span className="button date small">{published_at ? moment(published_at).fromNow() : '-'}</span></td>
                <td>
                    <Link className={`button small ${status} mr`} to={`/version/process/${version_reference}`}>
                        {status === 'draft' ? 'Edit' : 'View'} Version
                    </Link>
                </td>
            </tr>
        )
    }

    render() {
        const { items = [], versions = [] } = this.props;
        const any_draft = versions.filter(v => v.status === 'draft').length > 0;

        return(
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th style={{ width: '120px' }}>Created</th>
                            <th style={{ width: '120px' }}>Approved</th>
                            <th style={{ width: '120px' }}>Published</th>
                            <th style={{ width: '100px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => {
                            return (this.renderRow(item, any_draft));
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default VersionsTable;