import React, { Component } from 'react';
import Table from '../Layout/Table';
import moment from 'moment';
import Avatar from '../Layout/Avatar';

class TasksTable extends Table {
    renderRow(data, i, status) {
        const {
            task,
            user_reference,
        } = data;

        const { approve_task, team = [] } = this.props

        let [ user ] = team.filter(t => t.user_reference === user_reference);

        return (
            <tr key={'tasks-' + i}>
                <td>{task}</td>
                <td className="table-buttons">
                    {user && <Avatar inverse={true} name={user.name} />}
                    <div className={`toggle ${status !== 'draft' ? 'disabled' : ''}`} style={{marginLeft: 10 }}>
                        <input onChange={() => {
                            approve_task(i);
                        }} type="checkbox" defaultChecked={user_reference ? true : false} id={'task-' + i} />
                        <label htmlFor={'task-' + i}></label>
                    </div>
                </td>
            </tr>
        )
    }

    render() {
        const { items = [], status, team } = this.props;
        return(
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Task</th>
                            <th style={{ width: '300px', textAlign: 'right' }}>Done</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, i) => {
                            return (this.renderRow(item, i, status, team));
                        })}
                        {(items || []).length === 0 &&
                            <tr>
                                <td colSpan={2}>
                                    <div className="empty-table">
                                        <i className="fa fa-warning"></i>
                                        No Tasks
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

class TasksEditTable extends Table {
    renderRow(data, i) {
        const {
            task,
        } = data;

        const { update_task, delete_task } = this.props;

        return (
            <tr key={'tasks-edit-' + i}>
                <td>
                    <input onChange={(e) => update_task(e.target.value, i)} value={task} />
                </td>
                <td className="table-buttons">
                    <button onClick={() => delete_task(i)} className="button small submain"><i className="fa fa-trash"></i> Delete</button>
                </td>
            </tr>
        )
    }
    
    render() {
        const { items = [] } = this.props;
        return(
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Task</th>
                            <th style={{ width: '100px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, i) => {
                            return (this.renderRow(item, i));
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

class Tasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            tasks: this.props.items || [],
        }
        this.edit = this.edit.bind(this);
        this.update_task = this.update_task.bind(this);
        this.approve_task = this.approve_task.bind(this);
        this.delete_task = this.delete_task.bind(this);
        this.add_task = this.add_task.bind(this);
    }

    edit () {
        this.setState({ edit: !this.state.edit })
    }

    approve_task(i) {
        const { user } = this.props;
        const { user_reference } = user;
        const tasks = this.state.tasks.map((t, index) => {
            if (i === index) {
                if (t.user_reference) {
                    return {
                        task: t.task,
                        user_reference: null,
                        date: null
                    }
                } else {
                    return {
                        task: t.task,
                        user_reference,
                        date: moment().toISOString(),
                    }
                }
            }
            return t;
        });

        this.setState({
            tasks,
        }, () => {
            this.props.save_process({
                tasks,
            });
        })
    }

    update_task(task, i) {
        const tasks = this.state.tasks.map((t, index) => {
            if (i === index) {
                return {
                    ...t,
                    task,
                }
            }
            return t;
        })
        this.setState({
            tasks,
        })
    }

    delete_task(i) {
        const tasks = this.state.tasks.filter((t, index) => {
            return i !== index;
        });
        this.setState({
            tasks,
        })
    }

    add_task() {
        let { tasks } = this.state;
        this.setState({
            tasks: [
                {
                    task: '',
                    date: null,
                    user_reference: null,
                },
                ...tasks
            ],
        });
    }

    render() {
        const { edit_mode, team, status } = this.props;

        const { edit, tasks } = this.state;
        
        if (!edit) {
            return [
                <div key={`tasks-but`} className="project-buttons">
                    {edit_mode && status === 'draft' && <button onClick={this.edit} className="button small main">Edit Tasks</button> }
                </div>,
                <div key={`tasks`} className="review-block">
                    <TasksTable 
                        items={tasks}
                        team={team}
                        edit_mode={edit_mode}
                        approve_task={this.approve_task}
                        status={status}
                    />
                </div>
            ]
        }

        if (edit && edit_mode) {
            return [
                <div key={`tasks-but`} className="project-buttons">
                    <button className="button small main mr" onClick={this.add_task}>Add Task</button>
                    <button className="button small main" onClick={() => {
                        const {tasks} = this.state;
                        this.props.save_process({
                            tasks,
                        });
                        this.edit();
                    }}>Save Tasks</button>
                </div>,
                <div key={`tasks-edit`} className="review-block">
                    <TasksEditTable 
                        items={tasks}
                        edit_mode={edit_mode}
                        update_task={this.update_task}
                        delete_task={this.delete_task}
                        status={status}
                        team={team}
                    />
                </div>
            ]
        }
    }
}

export default Tasks;