import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const companys = new BaseActions({
    path: `${base.root}/w/landing`,
    type: 'SWALLOW_LANDING',
});

export default companys;