import React, { Component } from 'react';
import { Parser } from 'json2csv';
import saveAs from 'file-saver';
import { each, keys, omit, isUndefined, indexOf, get } from 'lodash';

// TODO unit tests all the mapping

export function versionToJSON({ version, file_name}) {
    const new_version = {
        ...omit(version, ['version_reference', 'created_at']),
        meta: {
            name: version.meta.name,
            description: version.meta.description,
        }
    };

    const content = new Blob([
        JSON.stringify(new_version, 4, null)
    ], {
        type: "text/plain;charset=utf-8",
        name: file_name,
    });

    return content;
}

export function inputToCSV({ format, file_name }) {
    let mapped_rows = [];

    each(format, (v, k) => {
        mapped_rows = [...mapped_rows, {
            key: k,
            ...v,
        }];
    });
    
    if (mapped_rows.length === 0) return {
        rows: [],
        columns: [],
        file_name: ''
    };

    const mapped_columns = [
        { key: 'key' },
        { key: 'label' },
        { key: 'def' },
        { key: 'type' },
        { key: 'exp' },
        { key: 'static' },
        { key: 'indicator' },
    ];

    return tableToCSV({
        rows: mapped_rows,
        columns: mapped_columns,
        file_name,
    });
}

export function factorToCSV({ factor, file_name}) {
    const { 
        dimensions = {}, 
        hashes = {}, 
        def = {},
    } = factor;

    const rows = [];
    const columns = [];
    let count = 0;

    if(dimensions) {
        each(dimensions, (dimension, k) => {
            count = count + 1;

            columns.push({
                key: 'input_' + count,
                label: `Input (${count}D)`,
                type: 'string',
                def: 0,
            });

            columns.push({
                key: 'value_' + count,
                label: `Value (${count}D)`,
                type: 'string',
                def: 0,
            });

            columns.push({
                key: 'value_to_' + count,
                label: `Value To (${count}D)`,
                type: 'string',
                def: 0,
            });

        });
    }

    columns.push({
        key: 'weight',
        label: 'result',
        type: 'decimal',
        def: def['weight'],
    });

    columns.push({
        key: 'exclude',
        label: 'exclude',
        type: 'boolean',
        def: def['exclude']
    });

    if (!isUndefined(def['excess'])) {
        columns.push({
            key: 'excess',
            label: 'excess',
            type: 'decimal',
            def: def['excess'],
        });
    }

    if (!isUndefined(def['endorsement'])) {
        columns.push({
            key: 'endorsement',
            label: 'endorsement',
            type: 'string',
            def: def['endorsement'],
        });
    }

    if (!isUndefined(def['refer'])) {
        columns.push({
            key: 'refer',
            label: 'refer',
            type: 'boolean',
            def: def['refer'],
        });
    }

    function get_value_to ({
        dimensions,
        dimension,
        value,
    }) {
        const value_index = indexOf(get(dimensions, `${dimension}.value`, []), value);
        const value_to = get(dimensions, `${dimension}.value_to`, [])[value_index];
        return isUndefined(value_to) ? null : value_to;
    }

    if (hashes) {
        let row_count = 0;
        each(hashes, (hash, k) => {
            const [value_1, value_2, value_3] = k.split('::');
            const [input_1, input_2, input_3] = keys(dimensions);
            const value_to_1 = get_value_to({ dimensions, dimension: input_1, value: value_1});

            let obj = { 
                input_1,
                value_1,
                value_to_1, 
            };

            if (value_2) obj = { 
                ...obj, 
                input_2,
                value_2,
                value_to_2: get_value_to({ dimensions, dimension: input_2, value: value_2 }),
            };

            if (value_3) obj = { 
                ...obj, 
                input_3,
                value_3 ,
                value_to_3: get_value_to({ dimensions, dimension: input_3, value: value_3 }),
            };

            obj = {
                ...obj,
                ...hash,
            };

            rows[row_count] = obj;
            row_count = row_count + 1;
        });
    }

    return tableToCSV({
        rows,
        columns,
        file_name,
    });
}

export function formatToCSV({ format, file_name }) {
    let mapped_rows = [];

    each(format, (v, k) => {
        mapped_rows = [...mapped_rows, {
            key: k,
            ...v,
        }];
    });

    if (mapped_rows.length === 0) return {
        rows: [],
        columns: [],
        file_name: ''
    };

    const mapped_columns = [
        { key: 'key' },
        { key: 'label' },
        { key: 'def' },
        { key: 'type' },
        { key: 'exp' },
    ];

    return tableToCSV({
        rows: mapped_rows,
        columns: mapped_columns,
        file_name,
    });
}

export function itemsToCSV({ items, file_name }) {
    let mapped_rows = items.map(item => omit(item, ['id', 'new_def']));

    if (mapped_rows.length === 0) return {
        rows: [],
        columns: [],
        file_name: ''
    };

    const mapped_columns = keys(mapped_rows[0]).map(k => {
        return { key : k };
    });

    return tableToCSV({
        rows: mapped_rows,
        columns: mapped_columns,
        file_name,
    });
}

export function linksToCSV({ links, file_name }) {
    let mapped_rows = [];

    each(links, (v, k) => {
        mapped_rows = [...mapped_rows, {
            key: k,
            ...v,
        }];
    });

    if (mapped_rows.length === 0) return {
        rows: [],
        columns: [],
        file_name: ''
    };

    const mapped_columns = keys(mapped_rows[0]).map(k => {
        return { key : k };
    });

    return tableToCSV({
        rows: mapped_rows,
        columns: mapped_columns,
        file_name,
    });
}


export function testsToCSV({
    tests = [],
    file_name = ''
}) {
    
    if (tests.length === 0) return {
        rows: [],
        columns: [],
        file_name: ''
    };

    const mapped_rows = tests.map(test => {
        let obj = {
            label: test.name,
        };

        keys(test.output || {}).forEach(t => {
            const key = 'expected::' + t;
            obj[key] = test.output[t];
        });

        keys(test.result || {}).forEach(t => {
            const key = 'result::' + t;
            obj[key] = test.result[t];
        });

        obj = {
            ...obj,
            ...test.input,
        }

        return obj;
    });

    const mapped_columns = keys(mapped_rows[0]).map(k => {
        return { key : k };
    });

    return tableToCSV({
        rows: mapped_rows,
        columns: mapped_columns,
        file_name,
    });
}

export function dataToCSV({
    data = [],
    file_name = ''
}) {
    if (data.length === 0) return {
        rows: [],
        columns: [],
        file_name: ''
    };

    const mapped_rows = data.map(d => {
        let base = {
            quote_reference: d.quote_reference,
            project_reference: d.project_reference,
            version_reference: d.version_reference,
            version: d.version,
            indicators: (d.indicators || '').split('::').join(','),
            created_at: d.created_at,
        };
        each(d.output, (v, k) => {
            base[`result::${k}`] = v;
        });
        each(d.input, (v, k) => {
            base[`input::${k}`] = v;
        });
        return base;
    });

    const mapped_columns = keys(mapped_rows[0]).map(k => {
        return { key : k };
    });

    return tableToCSV({
        rows: mapped_rows,
        columns: mapped_columns,
        file_name,
    });
}

export function defaultToCSV ({ data = [], file_name }) {
    if (data.length === 0) return {
        rows: [],
        columns: [],
        file_name: ''
    };

    const mapped_columns = keys(data[0]).map(k => {
        return { key : k };
    });

    return tableToCSV({
        rows: data,
        columns: mapped_columns,
        file_name,
    });
}

export function tableToCSV({ 
    rows = [], 
    columns = [],
    file_name = '', 
}) {

    const mapped_columns = columns.map(c => c.key);
    const parser = new Parser({ fields: mapped_columns });
    const mapped_csv = parser.parse(rows);
    const content = new Blob([mapped_csv], {
        type: 'text/csv',
        name: file_name,
    });
    return content;
}

export function downloadSave ({ data, format, type, file_name, worker = false}) {
    let content = data;

    //Custom template formatting
    if(format) content = format(data);

    // Defaults to templated mappings
    if (type === 'table') content = tableToCSV({ ...data, file_name });
    if (type === 'tests') content = testsToCSV({ ...data, file_name });
    if (type === 'data') content = dataToCSV({ ...data, file_name });
    if (type === 'format') content = formatToCSV({ ...data, file_name });
    if (type === 'input') content = inputToCSV({ ...data, file_name });
    if (type === 'version') content = versionToJSON({ ...data, file_name });
    if (type === 'links') content = linksToCSV({ ...data, file_name });
    if (type === 'factor') content = factorToCSV({ ...data, file_name });
    if (type === 'items') content = itemsToCSV({ ...data, file_name });
    if (type === 'default') content = defaultToCSV({ ...data, name: file_name });

    // TODO if worker, then send to background process
    // This is for larger files (> 1MB)
    saveAs(content, file_name);
}

export function downloadLink ({ file, url}) {
    let link = document.createElement("a");
    link.setAttribute('download', file);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export class DownloadButton extends Component {
    constructor(props) {
        super(props);
        this.state = { downloading: false };
        this.download = this.download.bind(this);
    }

    download() {
        const {
            type,
            file_name,
            format,
            data,
        } = this.props;

        this.setState({ downloading: true });
        
        downloadSave({
            type,
            file_name,
            format,
            data,
        });

        this.setState({ downloading: false });
    }   

    render() {
        const { label = '', className = '' } = this.props;
        const { downloading } = this.state;
        if (downloading) {
            return (
                <button className={`button main small ${className}`} >
                    <i class="fa fa-spinner fa-spin"></i> Download {label}
                </button>
            )
        } else {
            return (
                <button className={`button main small ${className}`} onClick={this.download}>
                    <i class="fa fa-angles-down"></i> Download {label}
                </button>
            )
        }

    }
}