import BaseActions from '../../../actions/base';
import config from '../../../config';

const base = config;

const pools = new BaseActions({
    path: `${base.root}/a/pools`,
    type: 'ALLOW_POOLS',
});

export default pools;