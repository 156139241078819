import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import csv from 'csvtojson';
import { unique_id } from './unique_id';
import slugify from './slugify';

import { 
    each, 
    first, 
    keys, 
    round, 
    isUndefined, 
    uniq, 
    values, 
    omit,
    isEmpty,
} from 'lodash';

import dataTypeSelector, { 
    dataTypeConvertor, 
    dataTypeDeclared,
} from './data_type_selector';

import { evaluateSchema, projectSchema } from '@swa_llow/pricing_engine/utils/schema';

// TODO unit tests all the validation and mapping

const mapMethods = (type) => {
    const map = {
        input: {
            upload: uploadInput,
            validate: validateInput,
        },
        format: {
            upload: uploadFormat,
            validate: validateFormat,
        },
        factor: {
            upload: uploadFactor,
            validate: validateFactor,
        },
        collection: {
            upload: uploadCollection,
            validate: validateDefault,
        },
        version: {
            upload: uploadDefault,
            validate: validateVersion,
        },
        links: {
            upload: uploadLinks,
            validate: validateLinks,
        },
        tests: {
            upload: uploadTests,
            validate: validateTests,
        },
        items: {
            upload: uploadItems,
            validate: validateItems,
        },
        default: {
            upload: uploadDefault,
            validate: validateDefault,
        }
    };
    return map[type] || map['default'];
}

export function clean_exp(exp = '') {
    let new_exp = exp;
    new_exp = new_exp.replace('(', '');
    new_exp = new_exp.replace('{{', '');
    new_exp = new_exp.replace('}}', '');
    new_exp = new_exp.replace(')', '');
    return new_exp.trim();
}

export function checkExists(value = '') {
    if (isUndefined(value)) return false;
    if (value === null) return false;
    if (value === '') return false;
    return true;
}

export function uploadDefault(results) {
    return results; 
}

export function validateDefault() {
    return { 
        valid: true, 
        errors: [] 
    }; 
}

export function uploadTests (results = []) {
    let mapped_results = [];

    results.forEach(r => {
        // This is what we store in the model as test schema
        let obj = {
            output: {},
            input: {},
            mocks: {},
            tags: [],
            id: unique_id(),
        };
        
        each(r, (v, k) => {
            // Output has to contain result and valid.
            if (k.includes('expected::')) {
                obj.output[k.split('::')[1]] = dataTypeConvertor(v);
            } else if (k === 'label') {
                obj.name = v;
                obj.key = slugify(v);
            // Optional : Tags are flagging cohorts of tests
            } else if (k === 'tags') {
                obj.tags = v.split(',');
            // Optional : Mock is for mocking the API results
            } else if (k.includes('mock::')) {
                // Adds mock::<input> key to input properties
                obj.input[k] = dataTypeConvertor(v);
            // This is the result from previously run tests 
            // which can be downloaded but we don't want uploaded
            } else if (k.includes('result::')) {
            // Optional : Prefix to input keys
            } else if (k.includes('input::')) {
                obj.input[k.split('::')[1]] = dataTypeConvertor(v);
            // Everything else is assumed to be an input
            } else {
                obj.input[k] = dataTypeConvertor(v);
            }
        });

        if (isEmpty(obj.output)) return;
        mapped_results.push(obj);
    });

    // removes empty columns
    mapped_results = mapped_results.map(result => omit(result, ''));

    // Clean empty rows on the bottom of an upload
    mapped_results = mapped_results
        .filter(result => !(result.label === '' && result['expected::result'] === ''));

    return mapped_results;
}

export function validateTests(results = []) {
    let errors = []
    if (results.length === 0) errors = ['No data uploaded'];

    const headers = keys(results[0]);
    if (!headers.includes('label')) errors = [ ...errors, 'label need to be defined'];
    if (!headers.includes('expected::result')) errors = [ ...errors, 'expected::result need to be defined'];
    if (!headers.includes('expected::valid')) errors = [ ...errors, 'expected::valid need to be defined'];
    // TODO check all valid are boolean
    // TODO check all result are numeric
    // TODO check all result are date

    if (errors.length > 0) {
        return { valid: false, errors }
    } else {
        return { valid: true, errors }
    }
}

export function validateItems(results = []) {
    let errors = []
    if (results.length === 0) errors = ['No data uploaded'];

    const headers = keys(results[0]);
    if (!headers.includes('name')) errors = [ ...errors, 'name need to be defined'];
    if (!headers.includes('exp')) errors = [ ...errors, 'exp need to be defined'];
    if (!headers.includes('def')) errors = [ ...errors, 'def need to be defined'];

    // TODO check all def are boolean

    if (errors.length > 0) {
        return { valid: false, errors }
    } else {
        return { valid: true, errors }
    }
}

export function uploadFormat (results = []) {
    let new_format = {};
    results.forEach(r => {
        if (r.key === '') return;
        new_format[r.key] = {
            key: r.key,
            label: r.label,
            exp: r.exp,
            def: dataTypeDeclared(r.def, r.type),
            type: r.type,
        }
    });
    return new_format;
}

export function validateFormat (results = []) {
    let errors = [];
    if (results.length === 0) errors = ['No data uploaded'];

    const headers = keys(results[0]);
    if (!headers.includes('exp')) errors = [ ...errors, 'exp needs to be defined'];
    if (!headers.includes('key')) errors = [ ...errors, 'key needs to be defined'];
    if (!headers.includes('label')) errors = [ ...errors, 'label needs to be defined'];
    if (!headers.includes('def')) errors = [ ...errors, 'def needs to be defined'];

    if (errors.length > 0) {
        return { valid: false, errors }
    } else {
        return { valid: true, errors }
    }
}

export function validateLinks(results = [], inputs = {}) {
    const inputs_keys = keys(inputs);
    const [ first ] = results;
    const header_keys = keys(first);

    // Check that one column is an input
    let check = false;
    header_keys.forEach(h => {
        if(inputs_keys.includes(h)) check = true;
    });

    if (!check) {
        return { 
            valid: false, 
            errors: [`There needs to be a column header matching an existing input property`] 
        }; 
    } else {
        return { 
            valid: true, 
            errors: [] 
        }; 
    }
}

export function uploadLinks(results = []) {
    const raw_columns = keys(results[0])
        .filter(k => k !== '' || k !== 'field1'); // cleans useless rows

    const [ first ] = results;

    let format = {};
    raw_columns.forEach(column => {
        format[column] = {
            label: column,
            type: dataTypeSelector(first[column]),
            // This could be last row. It is arbitary.
            def: first[column],
        };
    })
                
    let links = {};
    let exp;
    results.forEach((r, i) => {
        if (!exp) exp = '({{' + keys(r)[0] + '}})';
        const key = values(r)[0];
        links[key] = omit(r, key);
    });

    return {
        links,
        format,
        exp,
    }
}

export function uploadInput(results = []) {
    let format = {};
    results.forEach(result => {
        let base = {
            key: result.key,
            label: result.label,
            exp: `{{${result.key}}}`,
            def: dataTypeDeclared(result.def, result.type),
            type: result.type,
        };
        if (checkExists(result.static)) {
            base = {
                ...base,
                static: dataTypeDeclared(result.static, 'boolean'),
            }
        }
        if (checkExists(result.indicator)) {
            base = {
                ...base,
                indicator: dataTypeDeclared(result.indicator, 'boolean'),
            }
        }
        if (result.key) {
            format[result.key] = base;
        }
    });
    return format;
}

export function validateInput(results = []) {
    let errors = [];
    if (results.length === 0) errors = ['No data uploaded'];

    const headers = keys(results[0]);
    if (!headers.includes('key')) errors = [ ...errors, 'key needs to be defined'];
    if (!headers.includes('def')) errors = [ ...errors, 'def needs to be defined'];
    if (!headers.includes('type')) errors = [ ...errors, 'type needs to be defined'];

    if (errors.length > 0) {
        return { valid: false, errors }
    } else {
        return { valid: true, errors }
    }
}

export function validateFactor(results = [], inputs = {}) {
    let errors = [];
    if (results.length === 0) errors = ['No data uploaded'];

    const headers = keys(results[0]);
    if (!headers.includes('input_1')) errors = [ ...errors, 'input_1 needs to be defined'];
    if (!headers.includes('value_1')) errors = [ ...errors, 'value_1 needs to be defined'];
    if (!headers.includes('weight')) errors = [ ...errors, 'weight needs to be defined'];
    if (!headers.includes('exclude')) errors = [ ...errors, 'exclude needs to be defined'];
    headers.forEach(h => {
        if(![
            '', // parsing noise
            'name', //parsing noise
            'field1', // parsing noise
            'input_1',
            'value_1',
            'value_to_1',
            'input_2',
            'value_2',
            'value_to_2',
            'input_3',
            'value_3',
            'value_to_3',
            'weight',
            'exclude',
            'excess',
            'endorsement',
            'refer'
        ].includes(h)) errors = [ ...errors, h + ' is not a valid header']
    });

    // Checks input_1, input_2, input_3 exist in project input object
    const input_keys = keys(inputs);
    const [ first_result ] = results;
    if (headers.includes('input_1')) {
        if (!input_keys.includes(first_result.input_1)) {
            errors = [ ...errors, `${first_result.input_1} is not a valid input`]
        }
    }

    if (headers.includes('input_2')) {
        if (!input_keys.includes(first_result.input_2)) {
            errors = [ ...errors, `${first_result.input_2} is not a valid input`]
        }
    }

    if (headers.includes('input_3')) {
        if (!input_keys.includes(first_result.input_3)) {
            errors = [ ...errors, `${first_result.input_3} is not a valid input`]
        }
    }

    if (errors.length > 0) {
        return { valid: false, errors }
    } else {
        return { valid: true, errors }
    }
}

export function uploadFactor(results = []) {
    const rows = results;
    const defs = first(results.filter(r => r.value_1 === 'def')) || {};
    
    const raw_columns = keys(results[0])
        .filter(k => {
            return k !== '' || 
                k !== 'name' || 
                k !== 'field1'
        }); // cleans useless rows

    const columns = raw_columns.map(key => {
        return {
            key: key,
            def: defs[key] || 0,
        }
    });

    const model = {
        def: {},
        dimensions: {},
        hashes: {},
    }
    
    columns.forEach(c => {
        if (!(c.key.includes('input_') || c.key.includes('value_'))) {
            if (c.key === 'weight') {
                model.def[c.key] = round(c.def, 4);
            } else {
                model.def[c.key] = c.def;
            }
        }
    });

    let input_1;
    let input_2;
    let input_3;

    rows.forEach((r, i) => {
        let key = r.value_1;
        input_1 = clean_exp(r.input_1);

        if (!isUndefined(r.input_2) && r.value_2) {
            key = key + '::' + r.value_2;
            input_2 = clean_exp(r.input_2);
        }

        if (!isUndefined(r.input_3) && r.value_3) {
            key= key + '::' + r.value_3;
            input_3 = clean_exp(r.input_3);
        }

        model.hashes[key] = {
            weight: round(r.weight, 4),
            exclude: (r.exclude || '').toLowerCase() === 'true' ? true : false,
        }

        model.hashes[key].refer = ((r.refer || '').toLowerCase() === 'true' ? true : false);
        model.hashes[key].excess = isUndefined(r.excess) ? 0 : round(r.excess, 4);
        model.hashes[key].endorsement = isUndefined(r.endorsement) ? '' : r.endorsement;
    });

    model.dimensions[input_1] = {
        value: uniq(rows.map(r => r.value_1).filter(r => r)),
        value_to: uniq(rows.map(r => r.value_to_1).filter(r => r)),
    };

    if (input_2) {
        model.dimensions[input_2] = {
            value: uniq(rows.map(r => r.value_2).filter(r => r)),
            value_to: uniq(rows.map(r => r.value_to_2).filter(r => r)),
        };
    }

    if (input_3) {
        model.dimensions[input_3] = {
            value: uniq(rows.map(r => r.value_3).filter(r => r)),
            value_to: uniq(rows.map(r => r.value_to_3).filter(r => r)),
        };
    }
    
    return model;
    
}

export function validateVersion(raw_result) {
    try {
        const uploaded = JSON.parse(raw_result);
        const result = evaluateSchema({ project:uploaded, schema: projectSchema });
        return result;
    } catch(e) {
        return {
            valid: false,
            errors: [ e.message ]
        }
    }
}

export function uploadItems (results = []) {
    return results.map(result => {  
        let base = {
            id: unique_id(),
            exp: result.exp,
            def: dataTypeConvertor(result.def),
            name: result.name,
        }
        if (result.value) {
            base = { ...base, value: dataTypeConvertor(result.value) };
        }
        return base;
    });
}

export function uploadCollection (results = []) {
    const collection = results.map(result => {
        let obj = {};
        each(result, (v, k) => {
            obj[k] = dataTypeConvertor(v);
        });
        return obj;
    });
    return collection;
}

export function parseLines(lines = [], seperator = '\t') {
    let headers = [];
    let results = [];
    lines.forEach((line, i) => {
        const values = line.split(seperator);
        if (i === 0) {
            values.forEach(v => {
                headers.push(v);
            });
        } else {
            let obj = {};
            headers.forEach((h, i) => {
                obj[h] = values[i];
            });
            results.push(obj);
        }
    });
    return results;
}

export function scrubLines(lines = []) {
    let index_of;
    lines.forEach((line, i) => {
        if (line.includes('input_1') || line.includes('expected::')) {
            index_of = i;
        }
    });
    lines = lines.slice(index_of, lines.length);
    return lines;
}

export class UploadArea extends Component {
    constructor(props) {
        super(props);
        this.state ={
            uploading: false,
            clear: false,
        };
        this.dragDrop = this.dragDrop.bind(this);
        this.cutAndPaste = this.cutAndPaste.bind(this);
    }

    async cutAndPaste(e) {
        this.setState({ uploading: true, clear: false });
        const { type, complete, inputs } = this.props;
        const { value = '' } = e.target;
        const lines = scrubLines(value.split('\n') || []);
        const results = parseLines(lines, '\t');

        const { 
            validate,
            upload,
        } = mapMethods(type);

        const { valid, errors } = validate(results, inputs);
        if (valid) {
            this.setState({ clear: false, errors: [], uploading: false });
            const mapped = upload(results);
            return await complete(mapped);
        } else {
            this.setState({ errors: errors, uploading: false });
        }        
    }

    async dragDrop([file]) {
        const { type, complete, inputs } = this.props;
        this.setState({ uploading: true, clear: false });
        let errors = [];

        const { 
            validate,
            upload,
        } = mapMethods(type);
        
        const results = await new Promise((resolve, reject) => {
            let results = [];
            const reader = new FileReader();
            reader.onabort = () => { errors = [...errors, 'File reading was aborted'] }
            reader.onerror = () => { errors = [...errors, 'File reading has failed'] }
            reader.onload = () => {
                if (type !== 'version') {
                    let raw = reader.result ? reader.result.toString() : '';
                    const lines = scrubLines(raw.split('\n') || []).join('\n');
                    csv()
                        .fromString(lines)
                        .subscribe(
                            (json) => results.push(json),
                            (e) => { reject(e) }, 
                            () => resolve(results)
                        )
                } else {
                    const raw = reader.result ? reader.result.toString() : '{}';
                    resolve(raw);
                }
            }
            reader.readAsText(file);
        }).catch(e => errors = [...errors, e.message]);

        this.setState({ uploading: false });

        const { errors:validation_errors = [] } = validate(results, inputs);
        errors = [...errors, ...validation_errors];
        if (errors.length === 0) {
            this.setState({ clear: false, errors: [] });
            const mapped = upload(results);
            return await complete(mapped);
        } else {
            this.setState({ errors: errors });
        } 
    }

    render() {
        const { 
            uploading, 
            clear, 
        } = this.state;
        
        // Join prop errors with state errors
        // TODO - format sends in prop errors might be able to move here
        let errors = [
            ...(this.state.errors || []), 
            ...(this.props.errors || []).map(e => e.message)
        ].slice(0, 5);

        // This is need to overwrite persisted prop errors
        if (clear) errors = [];

        if (errors.length > 0) {
            return (
                <div className="upload-area">
                    { errors.map(e => (<div className="help-block error-block" style={{width: '100%'}}>
                        <p><i class="fa fa-triangle-exclamation"></i> {e}</p>
                    </div>))}
                    <button style={{marginTop: 10}} className="button main" onClick={() => this.setState({ 
                        errors: [], 
                        clear: true
                    })}>Try Again</button>
                </div>
            )
        } else {
            return (
                <div className="upload-area">
                    
                    <div key={`empty-dropzone`} ref={'upload-area'} className="upload-box">
                        <Dropzone onDrop={acceptedFiles => this.dragDrop(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => [
                                <div key={'empty-dropzone-child'} {...getRootProps()}>
                                    <i className="fa fa-upload"></i>
                                    <p>Drop your file here, or <em>browse</em></p>
                                </div>,
                                <input key={'empty-dropzone-input'} {...getInputProps()} />
                            ]}
                        </Dropzone>
                        {uploading && <div className="progress-bar"></div>}
                    </div>

                    <div className="cut-and-paste-box">
                        <div className="cut-and-paste-info">
                            <i className="fa fa-paste"></i>
                            <p>Cut and Paste in here</p>
                        </div>
                        <textarea defaultValue={''} onChange={this.cutAndPaste}></textarea>
                    </div>

                </div>
            )
        }
    }
}