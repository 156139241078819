import React from 'react';
import ItemsEdit from './Items';
import { find } from 'lodash';

import Table from './Table';
import ExpressionBuilder from './ExpressionBuilder';
import { evaluateExpressions } from '../Expression/ExpressionTextarea';
import ExpressionTest from '../Expression/ExpressionTest';

import Input from '../Forms/Input';

class ItemsBooleanEdit extends ItemsEdit {

    render() {
        const { 
            step,
            inputs,
            header,
            description,
            tag = 'Item',
            is_approved,
            version,
        } = this.props;

        const { selected, errors } = this.state;

        let {
            id = '',
            name = '',
            exp = '',
        } = selected || {};

        return (
            <div ref={this.topForm}>
                {selected && <div key={`${id}-edit`} className="exclusions-edit">
                    {header && <h4>{header}</h4>}
                    {description && <p>{description}</p>}
                    
                    {!is_approved &&
                        <div className="table-control-panel">
                            <div className="table-control-panel-left"></div>
                            <div className="table-control-panel-right">
                                <button className="button grey small mr"
                                    onClick={this.clearExpression}
                                > Clear {tag}</button>
                                <button className="button secondary small" 
                                    onClick={this.updateItems}
                                > {id === '' ? 'Create' : 'Update'} {tag}</button>
                            </div>
                        </div>
                    }

                    <div className="edit-columns">
                        <div className="edit-column">
                            <form>
                                <Input 
                                    label={`${tag} Name`}
                                    disabled={false}
                                    value={name}
                                    errors={find(errors, e => e.path.includes('.name'))}
                                    updateStep={(value) => {
                                        this.updateItem({
                                            name: value,
                                        })
                                    }}
                                />

                                <ExpressionBuilder 
                                    errors={find(errors, e => e.path.includes('.exp'))}
                                    inputs={inputs}
                                    is_approved={is_approved}
                                    version={version}
                                    exp={exp}
                                    updateItem={this.updateItem}
                                    tag={tag}
                                />

                            </form>
                        </div>
                    </div>
                    {exp !== '' &&
                        <ExpressionTest 
                            inputs={inputs}
                            expression={selected}
                        />
                    }
                </div> 
                }
                <div key={`${id}-table`} className="exclusions-table">
                    <Table 
                        placeholder={tag}
                        inputs={inputs}
                        headers={[
                            '',
                            'Name',
                        ]}
                        hide_checkboxes={true}
                        componentData={{
                            ...step,
                            items: evaluateExpressions({
                                inputs, 
                                items: step.items,
                            }),
                        }}
                        updateComponent={this.updateItemFromTable}
                        selectItem={this.selectItem}
                        is_approved={is_approved}
                    />
                    {!selected && 
                        <div className="exclusions-table-buttons">
                            <button className="button small main" onClick={() => this.setState({ selected: {}})}>Create {tag}</button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ItemsBooleanEdit;