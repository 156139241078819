import React, { Component } from 'react';
import versions_hoc from '../../hoc/versions';

import { omit, find, isUndefined } from 'lodash';
import { getId, getQuery } from '../../../../utils/url';

import TopNav from '../../components/Layout/TopNav';

import GlobalLoading from '../../../../components/GlobalLoading';
import Footer from '../../components/Layout/Footer';
import ApprovedBlock from '../../components/Layout/ApprovedBlock';

import EditTeamTable from '../../components/Review/EditTeamTable';

const base_roles = ['read', 'write', 'approve'];
const base_notifys = ['created', 'started', 'mentioned', 'comments', 'replies', 'approved'];

class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_user: {},
            find_user: null,
            add_user: false,
            team: [],
        }
        this.addUser = this.addUser.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.openAddUser = this.openAddUser.bind(this);
        this.checkUserAccounts = this.checkUserAccounts.bind(this);
        this.selectUser = this.selectUser.bind(this);
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        const user_reference = getQuery(this.props.history, 'user_reference');
        await this.refreshTeam({
            version_reference: id,
            user_reference,
        })
    }

    async refreshTeam({ user_reference }) {
        const { selected:process = {} } = this.props.swallow_process;
        const { team = [] } = process;
        const { data:users = [] } = this.props.swallow_users;
        
        const mapped_team = team.map(t => {
            const user = find(users, u => u.user_reference === t.user_reference) || {};
            return {
                ...t,
                is_admin: isUndefined(user.is_admin) ? false : user.is_admin,
                is_admin_primary: isUndefined(user.is_admin_primary) ? false : user.is_admin_primary,
            }
        });

        const selected_user = mapped_team
            .filter(t => t.user_reference === user_reference)[0] || mapped_team[0];

        this.setState({
            selected_user,
            process,
            team: mapped_team,
        });
    }

    async updateUser(user) {
        this.setState(user);
    }

    openAddUser(){
        this.setState({
            find_user: null,
            add_user: !this.state.add_user,
        });
    }

    async addUser(e) {
        e.preventDefault();

        const { find_user = {} } = this.state;
        const { selected } = this.props.swallow_process;
        const { version = {}, team = [] } = selected;
        const { version_reference, project_reference } = version;

        const { selected: user = {} } = this.props.auth;
        const { pool_reference } = user;

        let user_reference = find_user.user_reference;
        let new_user;

        // If already in process team
        const [ is_already_team ] = team.filter(t => t.user_reference === user_reference);
        if (is_already_team) {
            return this.setState({
                find_user: null,
                add_user: false,
            });
        };

        try {
            // If it is not existing user
            if (!user_reference) {
                await this.props.swallow_users_post({
                    data:{
                        email: find_user.email,
                        custom: {
                            name: find_user.name,
                            company: find_user.company,
                        },
                        project: {
                            project_reference,
                        },
                        pool_reference,
                    },
                });

                const { selected: user } = this.props.swallow_users;
                user_reference = user.user_reference;

            }  
            
            new_user = {
                user_reference,
                roles: ['read'],
                notifys: ['started', 'approved'],
                email: find_user.email,
                name: find_user.name,
                company: find_user.company,
            };

            const new_team = [...(selected.team || []), new_user];

            const data = {
                ...omit(selected, 'version'),
                ...selected,
            };

            await this.props.swallow_process_post_some({
                id: version_reference,
                data: {
                    ...data,
                    team: new_team,
                },
            });

            this.setState({
                team: new_team,
            });

        } catch(e) {
            console.log(e);
        }

        this.setState({
            find_user: null,
            add_user: false,
            selected_user: new_user,
        })
    }

    async checkUserAccounts () {
        this.setState({ find_user: null });

        const { find_email }  = this.refs;
        const email = find_email.value;

        await this.props.swallow_users_get_all({
            email,
        });
        const { data: users = [] } = this.props.swallow_users;
        const [ user ] = users;
        if (user) {
            this.setState({
                find_user: {
                    ...user,
                    ...user.custom,
                },
            });
        } else {
            this.setState({
                find_user: {
                    email,
                },
            });
        }
    }

    async saveUser() {
        const { selected } = this.props.swallow_process;
        const { version_reference, project_reference } = selected.version;
        const { selected_user } = this.state;

        const new_team = (selected.team || []).map(t => {
            if (t.user_reference === selected_user.user_reference) {
                if (selected_user.is_admin) {
                    selected_user.roles = ['read', 'write', 'approve'];
                }
                return omit(selected_user, ['is_admin', 'is_admin_primary' ]);
            }
            return omit(t, [ 'is_admin', 'is_admin_primary' ]);
        })

        const data = {
            ...omit(selected, 'version'),
            ...selected,
        };

        await this.props.swallow_process_post_some({
            id: version_reference,
            data: {
                ...data,
                team: new_team,
            },
        });

        this.setState({
            team: new_team,
        })

        //Updates user in Allow
        let project = {
            project_reference,
        };

        base_roles.forEach(r => {
            if ((selected_user.roles || []).includes(r)) {
                project[`is_${r}`] = true;
            } else {
                project[`is_${r}`] = false;
            }
        });

        base_notifys.forEach(n => {
            if ((selected_user.notifys || []).includes(n)) {
                project[`notify_${n}`] = true;
            } else {
                project[`notify_${n}`] = false;
            }
        });

        await this.props.swallow_users_put({
            id: selected_user.user_reference,
            data: {
                custom: {
                    name: selected_user.name,
                    company: selected_user.company,
                },
                project,
                is_admin: selected_user.is_admin,
            }
        });

        await this.refreshTeam({
            version_reference,
            user_reference: selected_user.user_reference,
        })
    }

    async deleteUser() {
        const { selected } = this.props.swallow_process;
        const { version_reference, project_reference } = selected.version;
        const { selected_user, team } = this.state;

        const new_team = (team || []).filter(t => {
            return t.user_reference !== selected_user.user_reference;
        });

        const data = {
            ...omit(selected, 'version'),
            ...selected,
        };

        await this.props.swallow_process_post_some({
            id: version_reference,
            data: {
                ...data,
                team: new_team,
            },
        });

        //Updates user in Allow
        await this.props.swallow_users_put({
            id: selected_user.user_reference,
            data: {
                project: {
                    project_reference, 
                    is_delete: true,
                }
            }
        });

        this.setState({ team: new_team, selected_user: team[0]});
    }

    selectUser(user) {
        this.setState({
            selected_user: user,
        })
    }

    render() {

        const { isFetching:isFetchingProcess } = this.props.swallow_process;
        const { isFetching:isFetchingUsers } = this.props.swallow_users;
        const { selected: user = {} } = this.props.auth;
        const { process_notification } = this.props;

        const {
            selected_user = {},
            add_user = false,
            find_user,
            process = {},
            team = [],
        }  = this.state;

        const selected_is_admin = (selected_user || {}).is_admin;
        const selected_is_admin_primary = (selected_user || {}).is_admin_primary;
        const { is_admin } = user;

        const isFetching = (isFetchingProcess || isFetchingUsers);

        const {
            version = {},
        } = process;

        const {
            status,
            version_reference,
            project_reference,
        } = version;

        const {
            roles = [],
            notifys = [],
            name,
            email,
            company,
        } = selected_user;

        // const admin_count = team.filter(t => t.roles.includes('admin')).length;
        // const read_count = team.filter(t => t.roles.includes('read')).length;
        const write_count = team.filter(t => t.roles.includes('write')).length;
        const approve_count = team.filter(t => t.roles.includes('approve')).length;

        const {
            account_type,
            account_config,
            is_approved,
        } = this.props;

        const {
            users_count,
            users_approver_count,
            users_writer_count,
        } = account_config;

        return (
            <div className="swallow_app">
                <TopNav history={this.props.history} version={version} auth={this.props.auth} account_type={account_type} />

                { isFetching && <GlobalLoading /> }

                {is_approved && <ApprovedBlock project_reference={project_reference} />}

                {!isFetching &&
                    <section className="main-content slide-left">

                        <div className="main-content-nav">
                            <div className="main-content-nav-left">
                                <button className="button white big" onClick={() => {
                                    this.props.history.goBack();
                                }}>&lt; Go Back</button>
                            </div>
                            
                            <div className="main-content-nav-right">
                                {users_count >= team.length && is_admin &&
                                    <button className="button main big" onClick={this.openAddUser}>
                                        <i className="fa fa-user-plus"></i> Add New User
                                    </button>
                                }
                                {users_count < team.length && is_admin &&
                                    <button className="button disabled grey big">
                                        <i className="fa fa-user-plus"></i> User Limit Reached ({users_count})
                                    </button>
                                }
                            </div>
                        </div>

                        <div className="team-area">
                            <div className="team-area-left" style={{opacity: add_user ? '0.25' : '1'}}>

                                <div className="review-block">
                                    <EditTeamTable 
                                        items={team}
                                        user={user}
                                        save_process={this.save_process}
                                        status={status}
                                        history={this.props.history}
                                        version_reference={version_reference}
                                        selected_user={selected_user}
                                        selectUser={this.selectUser}
                                    />
                                </div>

                            </div>
                            <div className="team-area-right">
                                {add_user &&
                                <div>
                                    <div className="project-divide">
                                        <div className="project-role-header">
                                            <div className="project-role-header-left">
                                                <h4>New User</h4>
                                            </div>
                                            <div className="project-role-header-right">
                                                <button onClick={this.openAddUser} className="button small white"><i className="fa fa-close"></i>Close</button>
                                                {!find_user && <button onClick={this.checkUserAccounts} className="button main small">Next</button>}
                                            </div>
                                        </div>

                                        {!find_user &&
                                            <div>
                                                <fieldset className="spacing">
                                                    <label>New User Email</label>
                                                    <input 
                                                        ref={'find_email'}
                                                        defaultValue={''}
                                                    />
                                                </fieldset>
                                            </div>
                                        }

                                        {find_user &&
                                            <div>
                                                <fieldset className="spacing">
                                                    <label>Email</label>
                                                    <input 
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            this.updateUser({
                                                                find_user: {
                                                                    ...selected_user,
                                                                    email: value,
                                                                }
                                                            });
                                                        }} 
                                                        value={find_user.email || ''}
                                                    />
                                                </fieldset>
                                                <fieldset className="spacing">
                                                    <label>Name</label>
                                                    <input 
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            this.updateUser({
                                                                find_user: {
                                                                    ...find_user,
                                                                    name: value,
                                                                }
                                                            });
                                                        }} 
                                                        value={find_user.name || ''}
                                                    />
                                                </fieldset>
                                                <fieldset className="spacing">
                                                    <label>Company</label>
                                                    <input 
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            this.updateUser({
                                                                find_user: {
                                                                    ...find_user,
                                                                    company: value,
                                                                }
                                                            });
                                                        }} 
                                                        value={find_user.company}
                                                    />
                                                </fieldset>
                                                <button onClick={this.addUser} className="button main small">Add New User</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                }
                                <div style={{opacity: add_user ? '0' : '1'}}>
                                    <div className="project-divide">
                                        <div className="project-role-header">
                                            <div className="project-role-header-left">
                                            </div>
                                            <div className="project-role-header-right">
                                                <button onClick={this.saveUser} className="button main small">Save</button>
                                            </div>
                                        </div>
                                        <div>
                                            <fieldset className="spacing">
                                                <label>Email</label>
                                                <input 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        this.updateUser({
                                                            selected_user: {
                                                                ...selected_user,
                                                                email: value,
                                                            }
                                                        });
                                                    }}
                                                    value={email}
                                                />
                                            </fieldset>
                                            <fieldset className="spacing">
                                                <label>Name</label>
                                                <input 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        this.updateUser({
                                                            selected_user: {
                                                                ...selected_user,
                                                                name: value,
                                                            }
                                                        });
                                                    }} 
                                                    value={name}
                                                />
                                            </fieldset>
                                            <fieldset className="spacing">
                                                <label>Company</label>
                                                <input 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        this.updateUser({
                                                            selected_user: {
                                                                ...selected_user,
                                                                company: value,
                                                            }
                                                        });
                                                    }} 
                                                    value={company}
                                                />
                                            </fieldset>
                                        </div>

                                        {is_admin &&
                                        <div className="table-wrapper" style={{marginTop: 20}}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Admin</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{textAlign: 'center'}}>

                                                            {!(selected_is_admin || selected_is_admin_primary) &&
                                                                <button onClick={async(e) => {
                                                                    await this.updateUser({
                                                                        selected_user: {
                                                                            ...selected_user,
                                                                            is_admin: true,
                                                                        }
                                                                    });
                                                                    await this.saveUser();
                                                                }} className="button valid">Make Admin</button>
                                                            }

                                                            {selected_is_admin_primary && 
                                                                <button className="button grey no-hover">Admin - Account Owner</button>
                                                            }

                                                            {selected_is_admin && !selected_is_admin_primary && <button onClick={async(e) => {
                                                                    await this.updateUser({
                                                                        selected_user: {
                                                                            ...selected_user,
                                                                            is_admin: false,
                                                                        }
                                                                    });
                                                                    await this.saveUser();
                                                                }} className="button invalid">Remove Admin</button>
                                                            }

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        }

                                        <div className="table-wrapper" style={{marginTop: 20}}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Roles</th>
                                                        <th style={{ width: '200px' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {base_roles.map((role) => {

                                                        if (role === 'write' && 
                                                            users_writer_count <= write_count &&
                                                            !roles.includes('write')
                                                            ) {
                                                            return (
                                                                <tr key={`role-${role}`}>
                                                                    <td>
                                                                        <button className="button small grey role">{role}</button>
                                                                    </td>
                                                                    <td className="table-buttons">
                                                                        <div className="toggle">
                                                                            <input disabled={true} type="checkbox" checked={false} id={`role-${role}`} />
                                                                            <label htmlFor={`role-${role}`}></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }

                                                        if (role === 'approve' && 
                                                            users_approver_count <= approve_count &&
                                                            !roles.includes('approve')
                                                            ) {
                                                            return (
                                                                <tr key={`role-${role}`}>
                                                                    <td>
                                                                        <button className="button small grey role">{role}</button>
                                                                    </td>
                                                                    <td className="table-buttons">
                                                                        <div className="toggle">
                                                                            <input disabled={true} type="checkbox" checked={false} id={`role-${role}`} />
                                                                            <label htmlFor={`role-${role}`}></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }

                                                        return (
                                                            <tr key={`role-${role}`}>
                                                                <td>
                                                                    <button className="button small grey role">{role}</button>
                                                                </td>
                                                                <td className="table-buttons">
                                                                    <div className="toggle">
                                                                        <input onChange={() => {
                                                                            let new_roles = [];
                                                                            if (roles.includes(role)) {
                                                                                new_roles = roles.filter(r => r !== role);
                                                                            } else {
                                                                                new_roles = [...roles, role];
                                                                            }
                                                                            this.updateUser({
                                                                                selected_user: {
                                                                                    ...selected_user,
                                                                                    roles: new_roles,
                                                                                }
                                                                            });
                                                                        }} type="checkbox" checked={roles.includes(role)  ? true : false} id={`role-${role}`} />
                                                                        <label htmlFor={`role-${role}`}></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                        {process_notification &&
                                            <div className="table-wrapper" style={{marginTop: 20}}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Notification</th>
                                                            <th style={{ width: '200px' }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {base_notifys.map((notify) => {
                                                            return (
                                                                <tr key={`notifys-${notify}`}>
                                                                    <td>
                                                                        on {notify}
                                                                    </td>
                                                                    <td className="table-buttons">
                                                                        <div className="toggle">
                                                                            <input onChange={() => {
                                                                                let new_notifys = [];
                                                                                if (notifys.includes(notify)) {
                                                                                    new_notifys = notifys.filter(r => r !== notify);
                                                                                } else {
                                                                                    new_notifys = [...notifys, notify];
                                                                                }
                                                                                this.updateUser({
                                                                                    selected_user: {
                                                                                        ...selected_user,
                                                                                        notifys: new_notifys,
                                                                                    }
                                                                                });
                                                                            }} type="checkbox" checked={notifys.includes(notify) ? true : false} id={`notifys-${notify}`} />
                                                                            <label htmlFor={`notifys-${notify}`}></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                        {is_admin &&
                                            <div className="project-role-header" style={{marginTop: 30}}>
                                                <div className="project-role-header-left">
                                                </div>
                                                <div className="project-role-header-right">
                                                    <button onClick={this.deleteUser} className="button error small">Remove User</button>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                }

                <Footer history={this.props.history} />

            </div>
        );
    }
}

export default versions_hoc(Team);