import React, { Component } from 'react';

class Input extends Component {
    render() {
        let { 
            label = 'Step Name', 
            value = '',
            errors = null,
            disabled = false,
            updateStep = (() => {}),
            updateBlur=(() => {}),
            type = 'text',
        } = this.props;
        const { message } = errors || {};
        return (
            <fieldset className={`${message ? 'error' : ''} ${disabled ? 'disabled' : ''} spacing`}>
                <label>{label}</label>
                <input
                    onBlur={(e) => updateBlur(e.target.value)} 
                    onChange={(e) => {
                        updateStep(e.target.value)
                    }} disabled={disabled} value={value} type={type} 
                />
                {message && <p className='error-message'>{message}</p>}
            </fieldset>
        );
    }
}

export default Input;