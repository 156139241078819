import { SET_ERROR } from '../actions/set_error';
export default function lang(state = { error: false }, action) {
    switch (action.type) {
    case SET_ERROR:
        if (!action.value) {
            return {
                ...state,
                error: false,
            };
        } else {
            return {
                ...state,
                error: action.value,
            };
        }
    default:
        return state;
    }
}