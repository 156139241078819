import React, { Component } from 'react';
import config from '../../../../config';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footer-left">
                    Powered by <a href={config.landing}><span className="footer-logo"></span> Swallow</a>
                </div>
                <div className="footer-right">

                    {/*<a href={'https://swallow-1.gitbook.io/swallow'} target="_blank"><i className="fa fa-life-ring"></i>Help Docs</a>*/}
                    <a href={'mailto:contact@llow.io?subject=Some help please'}><i className="fa fa-comments"></i>Contact Support</a>
                </div>
                
            </div>
        );
      
    }
}

export default Footer;